export function validateSeriesRequest() {
  /**
   * Validate contractId - does this contract exist? does it have enough balance? (maybe)
   * Validate title - is this title unique?
   * Validate description: is this description small enough data-wise
   * Validate copies: is there a limit
   * Validate Royalties
   *  - for each royalty, validate accountID exists
   *      TODO SERVICE - GET NEAR: does accountID exist?
   *        "Account id blah-test doesn't exist to assign royalties"
   *  - reducer to validate the sum of royalties <= 10000 percentage points (100%)
   * Validate the user has enough balance
   */
}

export const getContractIdAndSeriesTitleFromSeriesId = (seriesId: string) => {
  const contractId = seriesId.split('/')[0]
  const title = seriesId.split('/').slice(1).join('/') // title could have slashes
  return [contractId, title]
}
