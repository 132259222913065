import { Button, Grid, IconButton, TextField, useMediaQuery } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { createCollection, initStore } from '../store/dispatchers'
import { useRecoilState } from 'recoil'
import {
  loadingState,
  collectionsState,
  userState,
  nearState,
  seriesState,
  campaignsState,
  errorState,
} from '../store/index'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'

import CloseIcon from '@mui/icons-material/Close'
import { WalletConnection } from 'near-api-js'
import { getAccount, getTransactionInformation, initNear, sendTokens, signIn } from '../services/near'
import slugify from 'slugify'
import { addTransaction, updateApp } from '../services/sonar-app'
import { TransactionBody, TransactionStatus } from '../store/models/transaction'
import { SUCCESS_STATUSES } from '../store/models'
import { ERROR_MESSAGES } from '../utils/Error'
import { Constants } from '../constants'

const LS_NEAR_ID = 'NEAR_ID'
const LS_C_TITLE = 'C_TITLE'

const CONTRACT_COST = '5'

function CreateCollection() {
  const [, setLoading] = useRecoilState(loadingState)
  const [collections, setCollections] = useRecoilState(collectionsState)
  const [, setSeries] = useRecoilState(seriesState)
  const [, setCampaigns] = useRecoilState(campaignsState)
  const [user] = useRecoilState(userState)
  const [near, setNear] = useRecoilState(nearState)
  const [, setError] = useRecoilState(errorState)

  const [isRequesting, setIsRequesting] = useState(false)
  const [open, setOpen] = useState(true)
  const [title, setTitle] = useState('')
  const [titleError, setTitleError] = useState('')
  const maxWidth = 'sm' // TODO RESPONSIVE
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const txHash = searchParams.get('transactionHashes')
  const account_id = searchParams.get('account_id')

  const xs = useMediaQuery('(max-width:600px)')

  function handleClose() {
    setOpen(false)
    navigate('/dashboard')
  }

  async function handleChange(e: any) {
    /**
     * * VALIDATION REQS
     * - unique title, ie unique contractId within network
     * - 32 slugified chars limit <= {17chars} + ".snft.testnet".length()
     *    - special characters removed/replace with hyphen
     *    - special chars can be decoded bc they're saved -
     *    - slugify({string}, { strict: true, lower: true })
     */

    // SLUGIFIED TITLE SHOULD BE A CHECK ONLY
    if (titleError) setTitleError('')
    const val = e.target.value
    setTitle(val)
    if (val.length > 32) setTitleError('Collection title must be less than 32 characters')
    const contractId = slugify(val, { strict: true, lower: true }) + Constants.SNFT_SUFFIX
    const { exists } = await getAccount(contractId)
    if (exists) {
      setTitleError('Collection with that name exists')
    }
  }

  /*
Record of a blockchain "transfer" transaction - either a payment or a refund
interface TransactionBody {
  category: TransactionCategory
  categoryItemId: string // contractId, seriesId or campaignId
  timestamp: number
  amount: string // in yoctoNEAR, e.g. "5000000000000000000000000"
  receiverId: string
  signerId: string
  isRefund: boolean
  hash: string
  status: TransactionStatus
  error?: string
}
  */

  useEffect(() => {
    ;(async () => {
      const collectionTitle = localStorage.getItem(LS_C_TITLE)
      if (txHash && collectionTitle && user.apiToken) {
        const trimmedTitle = collectionTitle.trim()
        // const txRes = (await getTransactionStatus(txHash, localStorage.getItem(LS_NEAR_ID) as string)) as any
        try {
          const { receiverId, signerId, amount, status } = await getTransactionInformation(txHash)
          const valid = signerId && receiverId && amount && status !== 'error'
          // console.log('valid: ', valid)
          // console.log('signerId: ', signerId, 'receiverId: ', receiverId, 'amount: ', amount, 'status: ', status)
          if (!valid) {
            /// something weird going on with transaction
            setError(ERROR_MESSAGES.NEAR_TRANSACTION_ERROR)
            /// TODO: log this error for reference
            return
          }
          // build transaction record
          const tx: TransactionBody = {
            category: 'collection',
            timestamp: Date.now(),
            amount,
            receiverId,
            signerId,
            isRefund: false,
            hash: txHash,
            status: status as TransactionStatus,
          }
          const addTransactionRes = await addTransaction(user.email, user.apiToken, tx)
          // console.log('addTransactionRes: ', addTransactionRes)
          if (!SUCCESS_STATUSES.includes(addTransactionRes.status))
            console.warn('error adding transaction: ', addTransactionRes)
          if (status !== 'success') {
            setError(ERROR_MESSAGES.NEAR_TRANSACTION_ERROR)
            return
          }
          setTitle(collectionTitle)
          setIsRequesting(true)
          localStorage.removeItem(LS_C_TITLE)
          localStorage.removeItem(LS_NEAR_ID)
          const response = await createCollection(
            user.email,
            user.apiToken,
            { title: trimmedTitle, fundingHash: tx.hash },
            collections,
            setCollections
          )
          // console.log('create collection response: ', response)
          if (SUCCESS_STATUSES.includes(response.status)) {
            await initStore(user.email, user.apiToken, setCollections, setSeries, setCampaigns, setLoading)
            setIsRequesting(false)
            handleClose()
          } else {
            // TODO: handle 400 vs 500 responses (not refunded vs. refunded)
            setError(ERROR_MESSAGES.CREATE_COLLECTION_ERROR_REFUNDED)
            setIsRequesting(false)
          }
        } catch (e) {
          console.warn('Error getting transaction info: ', e)
          setError(ERROR_MESSAGES.NEAR_TRANSACTION_ERROR)
          return
        }
      }
    })()
  }, [txHash, user.apiToken])

  async function submit() {
    setIsRequesting(true)
    // save collection title to autofill on redirect
    localStorage.setItem(LS_C_TITLE, title)
    const wallet = (near.wallet as WalletConnection) || (await initNear(setNear))
    // await signIn(wallet, '/create-collection')
    if (!wallet.isSignedIn()) {
      await signIn(wallet, '/create-collection')
    } else {
      // save acct id to check tx hash
      localStorage.setItem(LS_NEAR_ID, wallet.getAccountId())
      sendTokens(wallet, CONTRACT_COST)
    }
  }

  useEffect(() => {
    ;(async () => {
      if (account_id) {
        const collectionTitle = localStorage.getItem(LS_C_TITLE)
        setTitle(collectionTitle as string)
        setIsRequesting(true)
        try {
          if (account_id !== user.nearId) {
            // update user
            await updateApp(user.email, user.apiToken, { nearId: account_id })
          }
        } catch (e) {
          console.warn('error updating nearId: ', e)
        } finally {
          // save acct id to check tx hash
          localStorage.setItem(LS_NEAR_ID, account_id)
          const wallet = (near.wallet as WalletConnection) || (await initNear(setNear))
          setSearchParams({})
          sendTokens(wallet, CONTRACT_COST)
        }
      }
    })()
  }, [account_id])

  //@ts-ignore
  const DialogTitleWithClose = (props) => {
    const { children, onClose, ...other } = props

    return (
      <DialogTitle sx={{ m: 0, p: { xs: 2, sm: 4 } }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: { xs: 8, sm: 8 },
              top: { xs: 6, sm: 20 },
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    )
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Dialog open={open} maxWidth={maxWidth} fullWidth onClose={handleClose}>
        <DialogTitleWithClose id="customized-dialog-title" onClose={handleClose}>
          Create Collection
        </DialogTitleWithClose>

        <DialogContent>
          <DialogContentText> {/* A collection is where you will store your NFTs. */}</DialogContentText>
          <TextField
            autoFocus
            id="title"
            label="Title"
            variant="filled"
            onFocus={() => {
              if (titleError) setTitleError('')
            }}
            value={title}
            error={titleError.length === 0 ? false : true}
            helperText={titleError.length === 0 ? null : titleError}
            fullWidth
            onChange={handleChange}
            InputProps={{ disableUnderline: true }}
            sx={{ mt: 2, mb: { xs: 0, sm: 2 } }}
            disabled={isRequesting}
          />
        </DialogContent>

        <DialogActions
          sx={{
            pl: { xs: 0, sm: 3 },
            pr: { xs: 0, sm: 3 },
            pb: { xs: 0, sm: 0 },
            flexDirection: {
              xs: 'column-reverse',
              sm: 'row',
            },
            justifyContent: {
              xs: 'center',
              sm: 'space-between',
            },
            alignItems: {
              xs: 'center',
              sm: 'start',
            },
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            disabled={isRequesting}
            fullWidth={xs ? true : false}
          >
            Cancel
          </Button>

          <LoadingButton
            loading={isRequesting}
            loadingPosition="start"
            variant="contained"
            startIcon={<CreateNewFolderIcon />}
            onClick={submit}
            disabled={!title || !!titleError}
            sx={{ mb: { xs: 2, sm: 2, md: 0 }, ml: { xs: '0px !important' } }}
            fullWidth={xs ? true : false}
          >
            {!isRequesting ? `Create Collection for ${CONTRACT_COST}N` : 'Creating Collection...'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default CreateCollection
