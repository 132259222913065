import { Grid, Typography } from '@mui/material'

interface WyreBalanceProps {
  availableBalances: { USD?: number }
  totalBalances: { USD?: number }
}

function WyreBalance({ availableBalances, totalBalances }: WyreBalanceProps) {
  return (
    <Grid container direction="column">
      {availableBalances.USD ? (
        <Typography variant="body1" component="p">
          Available Balance: ${availableBalances.USD}
        </Typography>
      ) : null}
      {totalBalances.USD ? (
        <Typography variant="body1" component="p">
          Total Balance: ${totalBalances.USD}
        </Typography>
      ) : null}
    </Grid>
  )
}

export default WyreBalance
