import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import { useRecoilState } from 'recoil'
import { userState, WyrePlaidPaymentMethod } from '../../store'
import { Success, SUCCESS_STATUSES, Error } from '../../store/models'
import colors from '../../theme/colors'
import { formatCurrency } from '../../utils/Currency'
import { ERROR_MESSAGES } from '../../utils/Error'
import GradientBorder from '../GradientBorder'

const WITHDRAWAL_FEE_PERCENT = 0.2

const WithdrawDialog = ({
  openDialog,
  setOpenDialog,
  handleAccept,
  amount,
}: {
  openDialog: boolean
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
  handleAccept: (paymentMethod: WyrePlaidPaymentMethod) => Promise<Error | Success<any>>
  amount: number
}) => {
  const calculateWithdrawFee = useCallback((amount) => {
    const percentFee = amount * (WITHDRAWAL_FEE_PERCENT / 100)
    return Math.max(percentFee, 1)
  }, [])

  const theme = useTheme()
  const [user] = useRecoilState(userState)
  const [isFetching, setIsFetching] = useState(false)
  const [fee, setFee] = useState(calculateWithdrawFee(amount))
  const [paymentMethod, setPaymentMethod] = useState<WyrePlaidPaymentMethod | undefined>(
    user.wyreUser?.paymentMethods[0]
  )
  const [error, setError] = useState<undefined | string>(undefined)
  const formattedAmount = formatCurrency(amount)
  const formattedFee = formatCurrency(fee)
  const formattedTotal = formatCurrency(amount - fee)

  const handleCloseDialog = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason !== 'backdropClick') setOpenDialog(false)
  }

  const handleAcceptInternal = useCallback(
    async (event: any) => {
      setIsFetching(true)
      const createTransferResponse = await handleAccept(paymentMethod!)
      setIsFetching(false)

      if (!SUCCESS_STATUSES.includes(createTransferResponse.status)) {
        console.warn('error creating transfer: ', createTransferResponse)
        setError(ERROR_MESSAGES.GET_TRANSACTIONS_ERROR)
      } else {
        setOpenDialog(false)
      }
    },
    [paymentMethod]
  )

  const handlePaymentMethodChange = useCallback(
    (index: number) => {
      setPaymentMethod(user.wyreUser?.paymentMethods[index])
    },
    [user.wyreUser?.paymentMethods]
  )

  useEffect(() => {
    setFee(calculateWithdrawFee(amount))
  }, [amount])

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        scroll="paper"
        maxWidth="md"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogContent dividers sx={{ width: 250 }}>
          <Box>
            <Box mb={1} display="flex" justifyContent="space-between">
              <Typography fontWeight="bold" color="secondary" lineHeight={3}>
                Withdraw
              </Typography>
              <IconButton disabled={isFetching} onClick={handleCloseDialog}>
                <CloseOutlinedIcon sx={{ color: theme.palette.text.secondary, opacity: 0.9 }} fontSize="small" />
              </IconButton>
            </Box>
            <GradientBorder display="block" alignItems="center" borderRadius={2.5} py={1} mb={2}>
              <Typography fontSize="0.8em" textAlign="center" fontWeight="bold" color="secondary">
                Withdrawal amount
              </Typography>
              <Box my={1} display="block" justifyContent="center" flexDirection="column">
                <Box display="flex" justifyContent="center">
                  <Typography variant="h2" component="span">
                    $&nbsp;{formattedAmount.whole}
                  </Typography>
                  <Typography variant="h2" color={colors.SECONDARY_DISABLED_STRONG} component="span">
                    .{formattedAmount.decimal} USD
                  </Typography>
                </Box>
              </Box>
              <Typography fontSize="0.7em" textAlign="center">
                Not including transfer fees
              </Typography>
            </GradientBorder>
          </Box>
          <Box>
            <Typography mb={2} fontWeight="bold" color="secondary">
              Withdraw to
            </Typography>
            {user.wyreUser?.paymentMethods
              .filter(({ wyrePaymentMethodStatus }) => wyrePaymentMethodStatus === 'ACTIVE')
              .map(({ plaidAccountName, plaidAccountMask, wyrePaymentMethodId }, index) => {
                const isSelected = paymentMethod?.wyrePaymentMethodId === wyrePaymentMethodId
                return (
                  <Box
                    display="flex"
                    borderRadius={2.5}
                    px={2.5}
                    py={2.5}
                    justifyContent="space-between"
                    sx={{ backgroundColor: colors.SECONDARY_DISABLED_BG }}
                    key={wyrePaymentMethodId}
                    onClick={() => handlePaymentMethodChange(index)}
                  >
                    <Box display="flex">
                      <Box display="flex" alignItems="center" mr={1.5}>
                        <Box
                          width={33}
                          height={33}
                          borderRadius="50%"
                          sx={{
                            backgroundColor: isSelected ? theme.palette.text.primary : colors.SECONDARY_DISABLED,
                          }}
                          display="flex"
                          alignItems="center"
                          flexDirection="column"
                        >
                          <Box display="flex" mt={0.8}>
                            <AccountBalanceOutlinedIcon
                              fontSize="small"
                              sx={{
                                color: isSelected ? theme.palette.background.default : theme.palette.text.primary,
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Box display="flex" flexDirection="column">
                          <Typography fontSize="0.8em" textAlign="center" fontWeight="bold" color="secondary" mb={0.1}>
                            {plaidAccountName}
                          </Typography>
                          <Typography fontSize="0.7em">**** {plaidAccountMask}</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box
                        position="relative"
                        width={18}
                        height={18}
                        borderRadius="50%"
                        sx={{ backgroundColor: isSelected ? theme.palette.text.primary : colors.SECONDARY_DISABLED }}
                      >
                        {isSelected && (
                          <Box
                            position="absolute"
                            borderRadius="50%"
                            width={7}
                            height={7}
                            left="50%"
                            top="50%"
                            sx={{
                              transform: 'translate(-50%,-50%)',
                              backgroundColor: theme.palette.background.default,
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                )
              })}
          </Box>
          <Box>
            <Typography my={2} fontWeight="bold" color="secondary">
              Total summary
            </Typography>
            <Box>
              <Box display="flex" justifyContent="space-between" my={1}>
                <Typography fontSize="0.7em" textAlign="center">
                  Withdrawal amount
                </Typography>
                <Typography fontSize="0.7em" color="secondary" fontWeight="bold">
                  $ {formattedAmount.value}
                </Typography>
              </Box>
              <Divider />
              <Box display="flex" justifyContent="space-between" my={1}>
                <Typography fontSize="0.7em" textAlign="center">
                  Provider fee
                </Typography>
                <Typography fontSize="0.7em" color="secondary" fontWeight="bold">
                  $ {formattedFee.value}
                </Typography>
              </Box>
              <Divider />
              <Box display="flex" justifyContent="space-between" my={1}>
                <Typography fontSize="0.9em" color="secondary" fontWeight="bold" textAlign="center">
                  You'll receive
                </Typography>
                <Typography fontSize="0.9em" color="secondary" fontWeight="bold">
                  $ {formattedTotal.value}
                </Typography>
              </Box>
            </Box>
          </Box>
          <DialogContentText color="error">{error}</DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: '0 18px' }}>
          <Box display="flex" flexDirection="row" alignItems="center" width="100%">
            <Button
              disabled={isFetching}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={handleCloseDialog}
              sx={{ width: '50%', mr: 0.5 }}
            >
              Cancel
            </Button>
            <Button
              disabled={isFetching}
              variant="contained"
              size="small"
              onClick={handleAcceptInternal}
              sx={{
                width: '50%',
                ml: 0.5,
                backgroundColor: theme.palette.text.primary,
                border: `1px solid ${theme.palette.text.primary}`,
                '&:hover': {
                  backgroundColor: theme.palette.text.primary,
                  border: `1px solid ${theme.palette.text.primary}`,
                },
              }}
            >
              Withdraw
            </Button>
          </Box>
        </DialogActions>
        <DialogContent>
          <Box display="flex" alignItems="center" flexDirection="column">
            <img width={130} src="/powered-by-wyre.png" alt="powered by wyre" />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default WithdrawDialog
