import { Box, Container, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import ChevronUp from '../icons/chevron-up.svg'
import ChevronDown from '../icons/chevron-down.svg'
import SatoriTeam from '../icons/satori-team@2x.png'
import colors from '../theme/colors'
import { Constants } from '../constants'
import { FaqItem, FAQ_ITEMS } from '../utils/FAQ'
import { responsiveContainer } from '../utils/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { handleOpenEmailClient } from '../utils/Other'

const Item = ({ item, lastChild, handleClick }: { item: FaqItem; lastChild: boolean; handleClick: () => void }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignContent="center"
      sx={{
        width: { xs: '100%', sm: '100%', md: '588px' },
        pt: 3,
        pb: 3,
        borderBottom: lastChild ? 'none' : `1px solid ${colors.SECONDARY_DISABLED_BG}`,
        cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      <ItemHeader item={item} />
      {item.expanded && <ItemBody item={item} />}
    </Box>
  )
}

const ItemHeader = ({ item }: { item: FaqItem }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        sx={{
          alignSelf: 'center',
          mr: { xs: 2, sm: 2, md: 0 },
        }}
        variant="h3"
      >
        {item.question}
      </Typography>
      <img
        src={item.expanded ? ChevronUp : ChevronDown}
        style={{ height: '24px', width: '24px', alignSelf: 'center', cursor: 'pointer' }}
        alt="chevron"
      />
    </Box>
  )
}

const ItemBody = ({ item }: { item: FaqItem }) => {
  return (
    <Typography variant="body1" sx={{ mt: 2 }}>
      {item.answer}
    </Typography>
  )
}

const FaqFooter = () => {
  const xs = useMediaQuery('(max-width:600px)')

  return (
    <Grid
      container
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        minWidth: { xs: 'none', sm: 'none', md: '580px' },
        backgroundColor: 'white',
        borderRadius: '16px',
        mt: 8,
        p: { xs: 4, sm: 4, md: 8 },
      }}
    >
      <img
        src={SatoriTeam}
        style={{ width: xs ? '100px' : '218px', height: 'auto', marginBottom: '24px' }}
        alt="chevron"
      />
      <Typography variant="h2" sx={{ mb: 3 }}>
        Still have questions?
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Can’t find the answer you’re looking for? Please contact our team.
      </Typography>
      <Typography
        variant="h5"
        sx={{ color: colors.BLUE, cursor: 'pointer' }}
        onClick={() => handleOpenEmailClient(Constants.SATORI_INFO_EMAIL)}
      >
        {Constants.SATORI_INFO_EMAIL}
      </Typography>
    </Grid>
  )
}

function FAQ() {
  const [faqItems, setFaqItems] = useState<FaqItem[]>(FAQ_ITEMS)

  const handleClick = (item: FaqItem, idx: number) => {
    const updated: FaqItem = {
      ...item,
      expanded: !item.expanded,
    }
    faqItems.splice(idx, 1, updated)
    setFaqItems([...faqItems])
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="start"
      sx={{ ...responsiveContainer() }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="start">
          <Typography variant="h1" sx={{ mb: { xs: 4, sm: 4, md: 10 }, textAlign: 'center' }}>
            Frequently Asked Questions
          </Typography>
          {faqItems.map((item, idx) => (
            <Item
              item={item}
              key={idx}
              lastChild={idx === faqItems.length - 1}
              handleClick={() => handleClick(item, idx)}
            />
          ))}
          <FaqFooter />
        </Grid>
      </Container>
    </Grid>
  )
}

export default FAQ
