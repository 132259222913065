import { LoadingButton } from '@mui/lab'
import { Button, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { LS_EMAIL, LS_USER_KEY } from '../../App'
import AuthHOC from '../../components/Auth/AuthHOC'
import TermsAndConditionsDialog from '../../components/Auth/TermsAndConditionsDialog'
import Loading from '../../components/Loading'
import { activateApp } from '../../services/sonar-app'
import { errorState, loadingState, userState } from '../../store'
import { SUCCESS_STATUSES } from '../../store/models'
import colors from '../../theme/colors'
import { ERROR_MESSAGES } from '../../utils/Error'
import { hash } from '../../utils/Other'

let debounceIntervalId1: number | undefined
let debounceIntervalId2: number | undefined
const DEBOUNCE_DELAY = 700 // ms

const VerifyContent = () => {
  const [loading, setLoading] = useRecoilState(loadingState)
  const [user, setUser] = useRecoilState(userState)
  const [_, setError] = useRecoilState(errorState)

  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [passwordError, setPasswordError] = useState<string>('')
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [appName, setAppName] = useState<string>('')
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const a = searchParams.get('appName')
    const c = searchParams.get('code')
    if (a && c) {
      setCode(c)
      setAppName(a)
      setSearchParams({})
    }
  }, [])

  const passwordRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$') // Minimum eight characters, at least one letter, one number and one special character
  const passwordValid = passwordRegex.test(password) && password === confirmPassword

  const handleSetPassword = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const val = e.target.value
    if (passwordError || !val) setPasswordError('')
    setPassword(val)
    if (debounceIntervalId1) window.clearTimeout(debounceIntervalId1)
    debounceIntervalId1 = window.setTimeout(() => {
      if (val && !passwordRegex.test(val))
        setPasswordError(
          'Must contain minimum 8 characters, at least one uppercase letter, one number and one special character'
        )
    }, DEBOUNCE_DELAY)
  }

  const handleSetConfirmPassword = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (confirmPasswordError) setConfirmPasswordError('')
    const val = e.target.value
    setConfirmPassword(val)
    if (debounceIntervalId2) window.clearTimeout(debounceIntervalId2)
    debounceIntervalId2 = window.setTimeout(() => {
      if (val && val !== password) setConfirmPasswordError('Passwords must match')
    }, DEBOUNCE_DELAY)
  }

  const handleOpenDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setOpenDialog(true)
  }

  const handleActivateAndSetPassword = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    try {
      const activateRes = await activateApp(appName, code, await hash(password))
      if (!SUCCESS_STATUSES.includes(activateRes.status)) {
        console.warn('error activating app. res: ', activateRes)
        setError(ERROR_MESSAGES.ACTIVATE_ACCOUNT_ERROR)
      } else {
        localStorage.setItem(LS_USER_KEY, activateRes.data.userKey)
        localStorage.setItem(LS_EMAIL, activateRes.data.email)
        setUser({
          ...user,
          apiToken: activateRes.data.userKey,
          nearId: activateRes.data.nearId,
          email: activateRes.data.email,
        })
        navigate('/dashboard')
      }
    } catch (e) {
      console.warn('error activating app: ', e)
      setError(ERROR_MESSAGES.ACTIVATE_ACCOUNT_ERROR)
    }
  }

  const NotFound = () => {
    return (
      <>
        <Typography variant="h1" sx={{ mb: 5 }}>
          Page not found 😢
        </Typography>
        <Typography>Please check the URL and try again.</Typography>
        <Typography
          sx={{ color: colors.PRIMARY, textDecoration: 'underline', cursor: 'pointer', mt: 2 }}
          onClick={() => navigate('/')}
        >
          Take me home
        </Typography>
      </>
    )
  }

  if (loading) return <Loading />

  return appName && code ? (
    <form
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Typography variant="h1" sx={{ mb: 5 }}>
        Set Your Password
      </Typography>
      <TextField
        autoFocus
        id="password-enter"
        label="Enter a password"
        type="password"
        variant="filled"
        value={password}
        onChange={handleSetPassword}
        error={!!passwordError}
        helperText={passwordError || null}
        InputProps={{ disableUnderline: true }}
        sx={{ mt: 2, mb: 2, width: { xs: '100%', sm: '400px' } }}
      />
      <TextField
        id="password-confirm"
        label="Confirm password"
        type="password"
        variant="filled"
        value={confirmPassword}
        onChange={handleSetConfirmPassword}
        error={!!confirmPasswordError}
        helperText={confirmPasswordError || null}
        InputProps={{ disableUnderline: true }}
        sx={{ mt: 2, mb: 5, width: { xs: '100%', sm: '400px' } }}
      />
      <Button
        type="submit"
        variant="contained"
        size="large"
        sx={{
          minWidth: { xs: '100%', sm: '400px' },
        }}
        disabled={!passwordValid}
        onClick={handleOpenDialog}
      >
        Set Password
      </Button>
      <TermsAndConditionsDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        handleAccept={handleActivateAndSetPassword}
      />
    </form>
  ) : (
    <NotFound />
  )
}

const Verify = () => <AuthHOC Content={VerifyContent} />

export default Verify
