import { AxiosResponse } from 'axios'
import { Error, ErrorStatus, Success, SuccessStatus } from '../store/models/common'

export function resolveResponseSuccess<T>(res: AxiosResponse<T>): Success<T> {
  return {
    data: res.data,
    status: res.status as SuccessStatus,
  }
}

export function resolveResponseError(error: any): Error {
  // console.log('response error: ', error)
  return {
    status: error.response?.status as ErrorStatus,
    data: error.response?.data,
  }
}

export const formatSeriesId = (contractId: string, seriesTitle: string) => contractId + '/' + seriesTitle
