import { Box, Typography, List, ListItem, TypographyProps, ListItemProps, BoxProps } from '@mui/material'

function TermsHeading(props: TypographyProps) {
  return <Typography sx={{ mt: 4, mb: 1 }} variant="h3" {...props} />
}

function TermsBold(props: TypographyProps) {
  return <Typography component="span" fontWeight="bold" {...props} />
}

function TermsListItem(props: ListItemProps) {
  return <ListItem sx={{ display: 'list-item' }} {...props} />
}

function TermsAndConditions(props: BoxProps) {
  return (
    <Box {...props}>
      <TermsHeading>Acceptance of Terms of Service</TermsHeading>
      <Typography>
        These Terms of Service (“<TermsBold>Terms</TermsBold>”) are a legal agreement between you and Satori
        Technologies Inc. (“<TermsBold>Company</TermsBold>,” “<TermsBold>we</TermsBold>,” “<TermsBold>us</TermsBold>,”
        or “<TermsBold>our</TermsBold>”) that governs your use of Company's product and services known as [Creator
        Studio] (collectively, the “<TermsBold>Services</TermsBold>”). The words “<TermsBold>user,</TermsBold>” “
        <TermsBold>you,</TermsBold>” and “<TermsBold>your</TermsBold>” refer to entities or individuals that access or
        use the Services. These Terms do not alter in any way the terms or conditions of any other agreement you may
        have with us. If you are using the Services on behalf of an entity, you represent and warrant that you are
        authorized to accept these Terms on such entity's behalf, and, as such, “<TermsBold>user,</TermsBold>” “
        <TermsBold>you</TermsBold>” and “<TermsBold>your</TermsBold>” will apply to the entity on whose behalf you have
        accepted these terms.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        BY CLICKING TO ACCESS OR SIGN-IN TO THE SERVICES, COMPLETING THE SIGNUP PROCESS TO USE THE SERVICES, OR BY USING
        THE SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS. IF YOU DO
        NOT AGREE TO BE BOUND BY THESE TERMS, YOU MAY NOT USE THE SERVICES.
      </Typography>
      <TermsHeading>1. ELIGIBILITY</TermsHeading>
      <Typography>
        The Services are only available to users who can form legally binding contracts under applicable law. By
        accessing or using the Services, you represent that you are at least eighteen (18) years of age, or over the age
        of majority in the state or country where you are a resident or citizen.
      </Typography>
      <TermsHeading>2. REGISTERING WITH US</TermsHeading>
      <Typography>
        Access to and use of the Services requires you to register for a user account (“<TermsBold>Account</TermsBold>”)
        with us. If you register for an Account with us, you will provide us your email address, password,
        cryptocurrency wallet identification, and other registration information to create and access your Account. You
        agree to provide us with accurate, complete, and current information during Account registration and at all
        other times, and you agree to update all information provided to us or requested by us if, and as soon as, such
        information changes. You agree to keep your login information confidential and to not authorize any third party
        to use your Account. We will not be liable for any loss or damage that results from the unauthorized use of your
        Account, either with or without your knowledge. You are fully responsible for your failure to safeguard
        information or for permitting any other person to access or use the Services via your Account, and you agree
        that we may attribute all use of your Account to you. You agree to notify us immediately at support@satori.art
        if you suspect any unauthorized use of your Account or any other breach of security. You may not sell or
        otherwise transfer your Account.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        You and Company will use electronic means to communicate when you use the Services or send us emails and when we
        post a notice on the Services or send you emails. You: (a) consent to receive communications, including
        notifications, from Company in an electronic form; and (b) agree that all terms and conditions, agreements,
        notices, disclosures, and other communications that Company provides to you electronically satisfy any legal
        requirement that such communications would satisfy if they were in a hard copy writing.
      </Typography>
      <TermsHeading>3. ACCEPTABLE USE</TermsHeading>
      <Typography>
        All information, materials, software, and other content provided as a component of the Services (collectively,
        the “<TermsBold>Content</TermsBold>”) is the sole and exclusive property of Company or its licensors. Subject to
        your compliance with these Terms, we grant to you a limited, non-exclusive, non-transferable, non-sublicensable
        license to access and use the Services, and to access, use, and view any Content, solely for your personal,
        non-commercial purposes. For the avoidance of doubt, the following activities are permitted under these Terms:
      </Typography>
      <List>
        <TermsListItem>
          your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing
          those materials;
        </TermsListItem>
        <TermsListItem>
          you may store files that are automatically cached by your Web browser for display enhancement purposes only;
          and
        </TermsListItem>
        <TermsListItem>
          if we provide desktop, mobile, or other applications for download, you may download a single copy to your
          computer or mobile device solely for your own internal, personal use, provided you agree to be bound by our
          end user license agreement for such applications, if applicable.
        </TermsListItem>
      </List>
      <TermsHeading>4. PROHIBITED USE</TermsHeading>
      <Typography>
        Except for the limited rights set forth in Section 3, you may not download, copy, duplicate, sell, print, rent,
        lease, issue, distribute, transmit, broadcast, modify, perform, display, transfer, upload, post, create
        derivative works of, exploit, sublicense, or otherwise assign to any third party any portion of the Services or
        the Content. When using the Services, you must not:
      </Typography>
      <List>
        <TermsListItem>
          remove any proprietary notices on the Services or Content, or attempt to disable, bypass, modify, defeat, or
          otherwise circumvent any digital rights management or security system used as part of the Services or Content;
        </TermsListItem>
        <TermsListItem>
          reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to derive or gain access to the
          source code of the Services or Content, in whole or in part;
        </TermsListItem>
        <TermsListItem>
          infringe or misappropriate the intellectual property, proprietary, or privacy rights of any third party;
        </TermsListItem>
        <TermsListItem>
          make any use of the Services or Content that violates any applicable local, state, national, international, or
          foreign law;
        </TermsListItem>
        <TermsListItem>
          provide any content, data, or information to Company that contains viruses or malicious code or is unlawful,
          harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of
          another's privacy or right of publicity, hateful, or racially, ethnically, or otherwise objectionable;
        </TermsListItem>
        <TermsListItem>
          fail to use reasonable efforts to prevent the unauthorized license, access, sale, transfer, lease,
          transmission, distribution, or other disclosure of the Services or Content;
        </TermsListItem>
        <TermsListItem>
          allow any individual to use any account login credentials (e.g., user identification(s), code(s), password(s),
          procedure(s), and user keys) issued to, or selected by, Company for someone other than the individual
          identified in the Account information;
        </TermsListItem>
        <TermsListItem>
          upload to the Services or otherwise provide to use the personal information of others that you are not
          authorized to provide;
        </TermsListItem>
        <TermsListItem>
          use the Services or Content to develop applications, services, websites, or any other functionalities that
          leverage the Services or Content;
        </TermsListItem>
        <TermsListItem>
          communicate the Content to the public, provide or make available any links, hypertext (Universal Resource
          Locator (URL) address) or otherwise grant access (other than a “<TermsBold>bookmark</TermsBold>” from a web
          browser) to the Services, or any part thereof;
        </TermsListItem>
        <TermsListItem>
          use the Services or Content in a way that suggests you are a representative of Company;
        </TermsListItem>
        <TermsListItem>
          circumvent the user authentication or security of the Services or any host, network, or account related
          thereto;
        </TermsListItem>
        <TermsListItem>
          interfere with or disrupt the proper functioning of the Services, any third-party systems used to host the
          Services, or other equipment or networks used to provide the Services; or
        </TermsListItem>
        <TermsListItem>
          cause damage to Company's business, reputation, employees, members, facilities, or to any other person or
          legal entity.
        </TermsListItem>
      </List>
      <Typography>
        In addition to the foregoing, any scraping, automated access, or other unauthorized access to, and storage of,
        Services or Content will result in immediate termination of your access to the Services.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        No right, title, or interest in or to the Services or any Content is transferred to you, and all rights not
        expressly granted are reserved by the Company. Any use of the Services or Content not expressly permitted by
        these Terms is a breach of these Terms and may violate copyright, trademark, and other laws.
      </Typography>
      <TermsHeading>5. SERVICES AND PAYMENT</TermsHeading>
      <TermsHeading>5.1. Service Options and Fees</TermsHeading>
      <Typography>
        The Services are provided to allow you, a creator, to create and sell one or more non-fungible tokens (each, an
        “<TermsBold>NFT</TermsBold>”), accounted for on a decentralized blockchain. NFTs may represent certain digital
        art, likeness, names, personas, trademarks, logos, tradenames, and other content owned or licensed to you (“
        <TermsBold>Creator Content</TermsBold>”). The Services include certain proprietary application programming
        interfaces (APIs) that enable the creation, insurance, sale, and distribution of any other commerce or
        integration related activities of crypto assets, such as, but not limited to, fungible tokens, NFTs, blockchain
        accounts, cryptographic access keys, and membership access to blockchain organizations on any decentralized,
        non-custodial platform, as follows: (i) a Minting API, which issues NFTs with functionalities determined by
        smart contracts, which may include, and are not limited to, access, transfer, and upgrades; (ii) a Distribution
        API, which distributes NFTs direct to end users, who can claim, redeem, or purchase such NFTs via white-labeled
        internet landing page, social media, email, e-commerce platform, and/or other digital means; and (iii) an
        Accounts API, which provides you with data and insights pertaining to the minting and distribution of NFTs on
        the blockchain. Certain of these APIs may utilize or enable the utilization of third-party payment processing
        technology. You acknowledge that your use of these payment processing functionalities will require that you have
        an existing account with such third-party payment processor and will be subject to the such processor's terms
        and conditions governing your use of such processor's technology, including any fees or deductions that may be
        owed to such processor. You are solely responsible for ensuring that you are familiar and compliant with such
        terms and conditions. Satori makes no representations or warranties with respect to such third-party payment
        processing technology and has no control over the terms and conditions of your use of it.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        You can find a more detailed description of our Services on the website, and we will explain which options are
        available to you when you create an Account. Use of the Services requires the payment of certain fees, also
        described on the website, including the fee for the use of the Services (the “
        <TermsBold>Services Fee</TermsBold>”) and an ongoing royalty on Secondary Transactions (the “Royalty Fee”, and
        together with the Applications Fee, the “<TermsBold>Fees</TermsBold>”). A “Secondary Transaction” means any
        transaction in which an NFT is sold by one end user to another end user or is otherwise transferred in any
        manner that is not a Primary Transaction. A “Primary Transaction” means a transaction facilitated through use of
        the Services in which an NFT is first sold to an end user.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        The Fees do not include any applicable sales, use, ad valorem, or other taxes, regardless of the taxing
        authority. You shall be responsible for all taxes imposed on your income or property. As between us and you, you
        shall be solely responsible for payment of any and all expenses, applicable taxes, and/or withholding
        requirements related to your use of the Services.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        By creating an Account to access the Services, you agree to pay the Fees. Fees may be processed through a
        third-party payment processor, in which case you acknowledge and agree that such third-party payment processor's
        terms and conditions will apply to the transaction.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        We may also offer special promotional plans, memberships, or services, including offerings of third-party
        products and services in conjunction with or through the Services. We are not responsible for the products and
        services provided by such third parties. We reserve the right to modify, terminate, or otherwise amend our
        offered Services and promotional offerings at any time in accordance with these Terms.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        You are solely responsible for any data, usage, and other charges assessed by mobile, cable, internet, or other
        communications services providers for your access to and use of the Services.
      </Typography>
      <TermsHeading>5.2. Billing</TermsHeading>
      <Typography>
        You must pay all Fees in accordance with the payment terms described in more detail when you create an Account.
        We reserve the right to adjust the Fees from time to time and will communicate in advance any price changes and,
        if applicable, how to accept those changes. Price changes will take effect upon your next use of the Services
        following the date of the price change. Subject to applicable law, you accept the new price by continuing to use
        the Services after the price change takes effect. If you do not agree with a price change, you have the right to
        reject the change by no longer using the Services.
      </Typography>
      <TermsHeading>6. OWNERSHIP AND INTELLECTUAL PROPERTY</TermsHeading>
      <Typography>
        The Services and the Content are protected by copyright, trademark, patent, and other intellectual property and
        proprietary right laws. All title, ownership rights, and intellectual property rights in and to the Services and
        the Content are owned by us or our licensors. All rights are reserved. The Services and the Content may contain
        certain licensed materials, and our licensors may protect their rights in the event of any violation of these
        Terms.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        The Company name, Satori Technologies Inc., Satori, Creator Studio, and the related logo, and all related names,
        logos, product and service names, designs, and slogans are trademarks of the Company or its affiliates or
        licensors. You must not use such marks without the prior written permission of the Company. All other names,
        logos, product and service names, designs, and slogans included in the Services are the trademarks of their
        respective owners.
      </Typography>
      <TermsHeading>7. YOUR MATERIALS</TermsHeading>
      <Typography>
        You are responsible for the Creator Content and any other material or information you upload or otherwise
        provide as part of your use of the Services (collectively, along with the Creator Content, the “
        <TermsBold>Creator Materials</TermsBold>”). By submitting the Creator Materials, you represent and warrant that:
      </Typography>
      <List>
        <TermsListItem>
          You own or otherwise control all of the rights to the Creator Materials, including copyrights and trademarks;
          and
        </TermsListItem>
        <TermsListItem>
          The Creator Materials do not violate the privacy, publicity, intellectual property, or other rights of any
          other person or entity.
        </TermsListItem>
      </List>
      <Typography>
        COMPANY TAKES NO RESPONSIBILITY AND ASSUMES NO LIABILITY FOR ANY OF THE CREATOR MATERIALS, INCLUDING LOSS OR
        DAMAGE RESULTING FROM YOUR USE OF THE CREATOR MATERIALS. UNDER NO CIRCUMSTANCES ARE YOU ENTITLED TO PAYMENT FROM
        COMPANY FOR THE CREATOR MATERIALS. YOU AGREE THAT IF ANYONE BRINGS A CLAIM AGAINST COMPANY RELATED TO THE
        CREATOR MATERIALS, YOU WILL INDEMNIFY AND HOLD COMPANY HARMLESS FROM AND AGAINST ALL DAMAGES, LOSSES, AND
        EXPENSES OF ANY KIND (INCLUDING REASONABLE ATTORNEYS' FEES AND COSTS) ARISING OUT OF SUCH CLAIM.
      </Typography>
      <TermsHeading>8. USER FEEDBACK</TermsHeading>
      <Typography>
        By submitting any ideas, feedback, or suggestions regarding the Services (“<TermsBold>Feedback</TermsBold>”) to
        us through the Services or by any other means, you acknowledge and agree that: (i) your Feedback does not
        contain confidential or proprietary information of you or any third party; (ii) we are not under any obligations
        of confidentiality with respect to the Feedback; (iii) we may freely use, reproduce, distribute, and otherwise
        exploit the Feedback for any purpose; and (iv) you are not entitled to any compensation of any kind from us.
      </Typography>
      <TermsHeading>9. LINKS TO OTHER WEBSITES AND SERVICES</TermsHeading>
      <Typography>
        If the Services contain links to other sites and resources provided by third parties, these links are provided
        for your convenience only. This includes, among other things, links contained in advertisements, including
        banner advertisements and sponsored links. We have no control over the contents of those sites or resources, and
        accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide
        to access any of the third-party websites linked to the Services, you do so entirely at your own risk and
        subject to the terms and conditions of use for such websites.
      </Typography>
      <TermsHeading>10. RELIANCE ON INFORMATION POSTED</TermsHeading>
      <Typography>
        The information presented on or through the Services is made available solely for general information purposes.
        We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such
        information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance
        placed on such materials by you or any other visitor to the Services, or by anyone who may be informed of any of
        its contents.
      </Typography>
      <TermsHeading>11. PRIVACY POLICY</TermsHeading>
      <Typography>
        Please refer to our Privacy Policy (found at{' '}
        <a href="https://satori.art/privacy-policy" target="_blank" rel="noreferrer">
          https://satori.art/privacy-policy
        </a>
        ), which describes our practices and policies related to the collection, use, and storage of information about
        users of the Services. You acknowledge and agree that you are solely responsible for the accuracy and content of
        your personal information that you provide through the Services.
      </Typography>
      <TermsHeading>12. DISCLAIMER OF WARRANTIES</TermsHeading>
      <Typography>
        You understand that we cannot and do not guarantee or warrant that files available for downloading from the
        internet or the services will be free of viruses or other destructive code. You are responsible for implementing
        sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and
        accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of
        lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
        DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
        COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SERVICES OR
        THE CONTENT OR YOUR DOWNLOADING OF ANY MATERIAL FROM THE SERVICES OR THE CONTENT, OR ON ANY WEBSITE LINKED TO
        THE SERVICES.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        YOUR USE OF THE SERVICES OR THE CONTENT IS AT YOUR OWN RISK. THE SERVICES AND CONTENT ARE PROVIDED ON AN “AS IS”
        AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR
        ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
        SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. NOTHING IN THE SERVICES OR THE
        CONTENT IS FINANCIAL ADVICE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE
        COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES OR THE CONTENT WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
        UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
        VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SERVICES OR THE CONTENT WILL OTHERWISE MEET YOUR NEEDS OR
        EXPECTATIONS. COMPANY MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE OF THE SECURITY, RELIABILITY, TIMELINESS,
        QUALITY, SUITABILITY, AVAILABILITY, ACCURACY, OR COMPLETENESS OF THE SERVICES OR CONTENT. COMPANY IS NOT LIABLE
        FOR ANY OMISSIONS OR ERRORS OF THE SERVICES OR CONTENT.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
        OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
        NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
      </Typography>
      <TermsHeading>13. LIMITATION OF LIABILITY</TermsHeading>
      <Typography>
        TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL COMPANY OR ANY OF ITS LICENSORS, SERVICE PROVIDERS, OR
        SUPPLIERS BE LIABLE UNDER OR IN CONNECTION WITH ANY LEGAL OR EQUITABLE THEORY, ARISING OUT OF OR IN CONNECTION
        WITH YOUR USE, OR INABILITY TO USE, THE SERVICES, ANY WEBSITES LINKED TO THE SERVICES, OR ANY CONTENT CONTAINED
        ON OR IN THE SERVICES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, ENHANCED OR PUNITIVE
        DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE,
        LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND
        WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        The limitation of liability set out above does not apply to liability resulting from our gross negligence or
        willful misconduct.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
      </Typography>
      <TermsHeading>14. INDEMNIFICATION</TermsHeading>
      <Typography>
        You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers,
        and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers,
        successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs,
        expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these
        Terms or your use of the Services or Content other than as expressly authorized in these Terms, or your use of
        any information obtained from the Services.
      </Typography>
      <TermsHeading>15. NO THIRD-PARTY BENEFICIARIES</TermsHeading>
      <Typography>
        You acknowledge and agree that, except as otherwise expressly provided in these Terms, there will be no
        third-party beneficiaries to these Terms.
      </Typography>
      <TermsHeading>16. GOVERNING LAW, VENUE, AND NO TRIAL BY JURY</TermsHeading>
      <Typography>
        All matters relating to the Services and these Terms, and any dispute or claim arising therefrom or related
        thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in
        accordance with the internal laws of the State of Delaware without giving effect to any choice or conflict of
        law provision or rule (whether of the State of Delaware or any other jurisdiction).
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        Any legal suit, action, or proceeding arising from, or related to, these Terms or the Services will be
        instituted exclusively in the federal courts of the United States or the state courts of Delaware; provided,
        however, we reserve the right to bring any suit, action, or proceeding against you for breach of these Terms in
        your country of residence or any other relevant country. You waive any and all objections to the exercise of
        jurisdiction over you by such courts and to venue in such courts. Additionally, you expressly waive your right
        to a trial by jury and right to participate in a class action lawsuit.
      </Typography>
      <TermsHeading>17. LIMITATION ON TIME TO FILE CLAIMS</TermsHeading>
      <Typography>
        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OR THE SERVICES MUST BE
        COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
        PERMANENTLY BARRED.
      </Typography>
      <TermsHeading>18. CHANGES TO TERMS</TermsHeading>
      <Typography>
        Except as provided in Section 25 (Entire Agreement) these Terms are subject to change by us in our sole
        discretion at any time. All changes are effective immediately when we post them on the Services, and apply to
        all access to, and use of, the Services thereafter. Please regularly check the Services to view the then-current
        Terms. When we make changes, we will make a new copy of the latest version of the Terms available on the
        Services. We may require you to provide consent to the updated Terms in a specified manner before we permit
        further use of the Services. If you do not agree to any change after receiving a notice of such change, you must
        stop using the Services. Your continued use of the Services following the posting of any revised Terms
        constitutes your acceptance of all changes.
      </Typography>
      <TermsHeading>19. MONITORING AND ENFORCEMENT</TermsHeading>
      <Typography>
        We have the right to take appropriate legal action, including without limitation, referral to law enforcement
        for any illegal or unauthorized use of the Services. Without limiting the foregoing, we have the right to
        cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the
        identity or other information of anyone posting any materials on or through the Services. YOU WAIVE AND HOLD
        HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSORS, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY
        ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH
        PARTIES OR LAW ENFORCEMENT AUTHORITIES.
      </Typography>
      <TermsHeading>20. TERMINATION</TermsHeading>
      <Typography>
        You may terminate your use of the Services and/or Content at any time. We may, in our sole and absolute
        discretion, take whatever action we deem necessary to preserve the integrity of the Services and Content.
        Violation of any of these Terms may result in actions being taken by us, effective immediately or at a time
        determined by us, which may include without limitation: (i) temporarily suspending your access to the Services
        and/or Content, or (ii) permanently terminating your access to the Services and/or Content. Without limiting the
        foregoing, we retain the right to decline to provide the Services and Content to any user who violates these
        Terms or our Privacy Policy.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        Termination will not limit any of Company's other rights or remedies. Section 5 (Services and Payment), Section
        6 (Ownership and Intellectual Property), Section 7 (Your Materials), Section 8 (User Feedback), Section 12
        (Disclaimer of Warranties), Section 13 (Limitation of Liability), Section 14 (Indemnification), Section 16
        (Governing Law, Venue, and No Trial by Jury), Section 17 (Limitation of Time to File Claims) Section 19
        (Monitoring and Enforcement) Section 20 (Termination), Section 24 (Waiver and Severability), and any other
        provision that, by its nature, is intended to survive termination, will survive termination of these Terms.
      </Typography>
      <TermsHeading>21. NOTICE TO CALIFORNIA RESIDENTS</TermsHeading>
      <Typography>
        BY USING THE SERVICES, YOU WAIVE YOUR RIGHTS WITH RESPECT TO CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS, “A
        GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST
        IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY
        AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.”
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        If the Services are at any time deemed an electronic commercial service (as defined under California Civil Code
        Section 1789.3), California residents are entitled to the following specific consumer rights information:
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>The provider of the Services is:</Typography>
      <Box sx={{ m: 2 }}>
        <Typography>Satori Technologies Inc.</Typography>
        <Typography>8070 La Jolla Shores Dr. #212</Typography>
        <Typography>La Jolla, CA 92037</Typography>
        <Typography>United States</Typography>
      </Box>
      <Typography>
        If you should have any further questions, the Complaint Assistance Unit of the Division of Consumer Services of
        the California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd.,
        Sacramento, CA 95834, or by telephone at <a href="tel:9164451254">(916) 445-1254</a> or{' '}
        <a href="tel:8009525210">(800) 952-5210</a>.
      </Typography>
      <TermsHeading>22. INTERNATIONAL USE</TermsHeading>
      <Typography>
        If you are using the Services from outside the United States, your information may be transferred to, stored,
        and processed in the United States where our servers may be located.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        Company makes no representation that the Services or Content are appropriate or available for use in locations
        outside the United States, and access to the Services or Content from territories where such Services or Content
        is illegal is prohibited. Those who choose to access the Services and Content from locations outside the United
        States do so on their own initiative and are responsible for compliance with applicable local laws. You may not
        use or export the Services or Content in violation of United States export laws and regulations.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        If you use the Services or access the Content outside the United States you, (i) consent to the transfer,
        storage, and processing of your information to and in the United States; (ii) will not access or use the
        Services or Content if you are on the United States Treasury Department's “Specially Designated Nationals and
        Blocked Persons List,” or are located in a country embargoed by the United States; and (iii) agree to comply
        with all local laws, rules, and regulations including all laws, rules, and regulations in effect in the country
        in which you reside and the country from which you access the Services. The Services are not intended for
        distribution to, or use by, any person or entity in any jurisdiction or country where such distribution or use
        would be contrary to law or regulation, or that would subject Company or its affiliates to any registration
        requirement within such jurisdiction or country.
      </Typography>
      <TermsHeading>23. WAIVER AND SEVERABILITY</TermsHeading>
      <Typography>
        No waiver by the Company of any term or condition set out in these Terms will be deemed a further or continuing
        waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to
        assert a right or provision under these Terms will not constitute a waiver of such right or provision.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        If any provisions of these Terms is held by a court or other tribunal of competent jurisdiction to be invalid,
        illegal, or unenforceable for any reason, such provision will be eliminated or limited to the minimum extent
        such that the remaining provisions of these Terms will continue in full force and effect.
      </Typography>
      <TermsHeading>24. ENTIRE AGREEMENT</TermsHeading>
      <Typography>
        These Terms are the complete and exclusive agreement between you and Company with respect to the Services, and
        supersede all prior and contemporaneous discussions, understandings, agreements, representations and warranties,
        whether written or oral, regarding the Services.
      </Typography>
      <TermsHeading>25. CONTACT INFORMATION</TermsHeading>
      <Typography>
        The Services are operated by Satori Technologies Inc. All other questions, feedback, comments, requests for
        technical support, and other communications relating to the Services should be directed to:{' '}
        <a href="mailto:support@satori.art">support@satori.art</a>.
      </Typography>
    </Box>
  )
}

export default TermsAndConditions
