import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'
import { addIdentityFields } from '../../../../services/sonar-payments'
import { userState, errorState, WyreUserField, WyreResidenceAddressField, WyreUser } from '../../../../store'
import { SUCCESS_STATUSES } from '../../../../store/models'
import { ERROR_MESSAGES } from '../../../../utils/Error'
import { isValidEmail } from '../../../../utils/Other'
import { US_STATES, VALID_COUNTRIES } from './utils'

const KYC = () => {
  const [user, setUser] = useRecoilState(userState)

  // PERSONAL INFO
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [phoneError, setPhoneError] = useState<string>('')
  const [dob, setDob] = useState<string>('')
  const [dobError, setDobError] = useState<string>('')

  // ADDRESS INFO
  const [street, setStreet] = useState<string>('')
  const [streetError, setStreetError] = useState<string>('')

  const [city, setCity] = useState<string>('')
  const [cityError, setCityError] = useState<string>('')

  const [zipcode, setZipcode] = useState<string>('')
  const [zipcodeError, setZipcodeError] = useState<string>('')

  const [state, setState] = useState<string>('')
  const [stateError, setStateError] = useState<string>('')

  const [country, setCountry] = useState<string>('')
  const [countryError, setCountryError] = useState<string>('')

  const [_, setError] = useRecoilState(errorState)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const missingFields = user.wyreUser!.kyc?.wyreMissingFields!

  // COMMENT IN BELOW (AND COMMENT OUT ABOVE) FOR FULL KYC EXPERIENCE
  // const wyreMissingFields: WyreUserField[] = ['dateOfBirth', 'email', 'cellphoneNumber', 'residenceAddress']

  type Field = WyreResidenceAddressField | WyreUserField

  /** Construct personalFields & addressFields arrays from wyreMissingFields array */
  const { personalFields, addressFields } = useMemo(() => {
    const personalFields = missingFields.filter((field) => field !== 'residenceAddress')
    const addressFields: WyreResidenceAddressField[] | null = missingFields.filter(
      (field) => field === 'residenceAddress'
    )[0]
      ? ['street1', 'city', 'state', 'postalCode', 'country']
      : null
    return { personalFields, addressFields }
  }, [missingFields]) as { personalFields: Field[]; addressFields: null | Field[] }

  /** Handle input focus */
  const handleOnFocus = (field: string) => {
    switch (field) {
      case 'email':
        return setEmailError('')
      case 'cellphoneNumber':
        return setPhoneError('')
      case 'dateOfBirth':
        return setDobError('')
      case 'street1':
        return setStreetError('')
      case 'city':
        return setCityError('')
      case 'postalCode':
        return setZipcodeError('')
      case 'state':
        return setStateError('')
      case 'country':
        return setCountryError('')
      default:
        console.warn('unknown field in handleOnFocus: ', field)
        return
    }
  }

  /** Determine whether input has error */
  const hasError = (field: string) => {
    switch (field) {
      case 'email':
        return !!emailError
      case 'cellphoneNumber':
        return !!phoneError
      case 'dateOfBirth':
        return !!dobError
      case 'street1':
        return !!streetError
      case 'city':
        return !!cityError
      case 'postalCode':
        return !!zipcodeError
      case 'state':
        return !!stateError
      case 'country':
        return !!countryError
      default:
        console.warn('unknown field in hasError: ', field)
        return false
    }
  }

  /** Get input helper text (error text) */
  const getHelperText = (field: string) => {
    switch (field) {
      case 'email':
        return emailError
      case 'cellphoneNumber':
        return phoneError
      case 'dateOfBirth':
        return dobError
      case 'street1':
        return streetError
      case 'city':
        return cityError
      case 'postalCode':
        return zipcodeError
      case 'state':
        return stateError
      case 'country':
        return countryError
      default:
        console.warn('unknown field in getHelperText: ', field)
        return false
    }
  }

  /** Handle input change */
  const handleChange = (field: string, value: string) => {
    switch (field) {
      case 'email':
        return setEmail(value)
      case 'cellphoneNumber':
        return setPhone(value)
      case 'dateOfBirth':
        return setDob(value)
      case 'street1':
        return setStreet(value)
      case 'city':
        return setCity(value)
      case 'postalCode':
        return setZipcode(value)
      case 'state':
        return setState(value)
      case 'country':
        return setCountry(value)
      default:
        console.warn('unknown field in handleChange: ', field)
        return
    }
  }

  /** Get input value*/
  const getValue = (field: string) => {
    switch (field) {
      case 'email':
        return email
      case 'cellphoneNumber':
        return phone
      case 'dateOfBirth':
        return dob
      case 'street1':
        return street
      case 'city':
        return city
      case 'postalCode':
        return zipcode
      case 'state':
        return state
      case 'country':
        return country
      default:
        console.warn('unknown field in getValue: ', field)
        return ''
    }
  }

  /** Get input label */
  const getLabel = (field: WyreUserField | WyreResidenceAddressField) => {
    switch (field) {
      case 'email':
        return 'Email'
      case 'cellphoneNumber':
        return 'Phone number'
      case 'dateOfBirth':
        return 'Date of birth (YYYY-MM-DD)'
      case 'street1':
        return 'Street Address'
      case 'city':
        return 'City'
      case 'postalCode':
        return 'ZIP Code'
      case 'state':
        return 'State'
      case 'country':
        return 'Country'
      default:
        console.warn('unknown field in getLabel: ', field)
        return ''
    }
  }

  /** Determine whether input fields are valid */
  const isValid = (field: WyreUserField | WyreResidenceAddressField) => {
    const val = getValue(field)
    let valid
    switch (field) {
      case 'email':
        valid = isValidEmail(val)
        if (!valid) setEmailError('Please enter a valid email address')
        return valid
      case 'cellphoneNumber':
        valid = val.length === 10
        if (!valid) setPhoneError('Please enter a valid 10-digit phone number')
        return valid
      case 'dateOfBirth':
        const [Y, M, D] = val.split('-')
        valid = Y.length === 4 && M.length === 2 && D.length === 2
        if (!valid) setDobError('Please enter a valid date in the format YYYY-MM-DD')
        return valid
      case 'street1':
        valid = !!val.length
        if (!valid) setStreetError('Please enter a valid street address')
        return valid
      case 'city':
        valid = !!val.length
        if (!valid) setCityError('Please enter a valid city')
        return valid
      case 'postalCode':
        valid = val.length === 5
        const chars = val.split('')
        for (let i = 0; i < chars.length; i++) {
          if (isNaN(parseInt(chars[i]))) valid = false
        }
        if (!valid) setZipcodeError('Please enter a valid 5-digit zipcode')
        return valid
      case 'state':
        valid = US_STATES.includes(val)
        if (!valid) setStateError('Please select a state')
        return valid
      case 'country':
        valid = VALID_COUNTRIES.includes(val)
        if (!valid) setCountryError('Please select a country')
        return valid
      default:
        console.warn('unknown field in getLabel: ', field)
        return ''
    }
  }

  /** Get country code from full country name (only US valid at present) */
  const getCountryCode = (country: string) => {
    if (country === 'United States') return 'US'
    console.warn('Unsupported country: ', country)
    return ''
  }

  /** Construct `fields` object to send to Sonar to create Wyre user */
  const getFieldsObjFromUserInputs = () => {
    const fields = {} as Record<WyreUserField | 'residenceAddress', string | Record<WyreResidenceAddressField, string>>
    if (email) fields.email = email
    if (phone) fields.cellphoneNumber = phone
    if (dob) fields.dateOfBirth = dob
    if (addressFields) {
      fields.residenceAddress = {
        street1: street,
        city,
        postalCode: zipcode,
        state: state.split(' ')[0],
        country: getCountryCode(country),
      }
    }
    return fields
  }

  /** Validate inputs & send user fields to Sonar */
  const handleSubmit = async () => {
    // VALIDATE INPUTS
    const allFields = [...personalFields, ...(addressFields || [])]
    let valid = true
    for (let i = 0; i < allFields.length; i++) {
      const field = allFields[i]
      if (!isValid(field)) valid = false
    }
    if (!valid) return
    // SEND FIELDS TO SONAR TO CREATE WYRE USER
    setSubmitting(true)
    const fields = getFieldsObjFromUserInputs()
    try {
      const addIdentityFieldsRes = await addIdentityFields(
        user.email,
        user.apiToken,
        fields,
        user.wyreUser!.paymentMethods.filter((pm) => !pm.isDeleted)[0].plaidAccountId!
      )
      // console.log('identity res: ', addIdentityFieldsRes)
      setSubmitting(false)
      if (!SUCCESS_STATUSES.includes(addIdentityFieldsRes.status)) {
        console.warn('error adding fields: ', addIdentityFieldsRes)
        setError(ERROR_MESSAGES.PLAID_ADD_IDENTITY_FIELDS_ERROR)
      } else {
        const { wyreUser } = addIdentityFieldsRes.data as {
          wyreUser: WyreUser
        }
        setUser({
          ...user,
          wyreUser,
        })
      }
    } catch (e) {
      console.warn('error adding fields: ', e)
      setError(ERROR_MESSAGES.PLAID_ADD_IDENTITY_FIELDS_ERROR)
      setSubmitting(false)
    }
  }

  /** Fields that require <Select /> rather than <Input /> */
  const selectFields: Field[] = ['state', 'country']

  /** Options to populate <Select /> menus */
  const selectOptions: any = {
    state: US_STATES,
    country: VALID_COUNTRIES,
  }

  return (
    <Grid sx={{ pt: 5 }}>
      <Grid item>
        <Typography variant="h3" sx={{ mb: 5 }}>
          Personal Information
        </Typography>
      </Grid>
      {personalFields.map((field, idx) => {
        return (
          <Grid item key={idx}>
            <TextField
              id={`${field}-input`}
              label={getLabel(field)}
              variant="filled"
              onFocus={() => handleOnFocus(field)}
              error={hasError(field)}
              helperText={getHelperText(field)}
              onChange={(e) => handleChange(field, e.target.value)}
              value={getValue(field)}
              fullWidth
              select={selectFields.includes(field)}
              InputProps={{ disableUnderline: true }}
              sx={{ mb: idx === personalFields.length - 1 ? 0 : 3 }}
            >
              {selectFields.includes(field)
                ? selectOptions[field].map((option: Field, idx: number) => (
                    <MenuItem key={idx} value={option}>
                      {option}
                    </MenuItem>
                  ))
                : null}
            </TextField>
          </Grid>
        )
      })}
      {addressFields && (
        <>
          <Grid item>
            <Typography variant="h3" sx={{ mt: 5, mb: 5 }}>
              Residential Address
            </Typography>
          </Grid>
          {addressFields.map((field, idx) => {
            return (
              <Grid item key={idx}>
                <TextField
                  id={`${field}-input`}
                  label={getLabel(field)}
                  variant="filled"
                  onFocus={() => handleOnFocus(field)}
                  error={hasError(field)}
                  helperText={getHelperText(field)}
                  onChange={(e) => handleChange(field, e.target.value)}
                  value={getValue(field)}
                  fullWidth
                  select={selectFields.includes(field)}
                  InputProps={{ disableUnderline: true }}
                  sx={{ mb: idx === addressFields.length - 1 ? 0 : 3 }}
                >
                  {selectFields.includes(field)
                    ? selectOptions[field].map((option: Field, idx: number) => (
                        <MenuItem key={idx} value={option}>
                          {option}
                        </MenuItem>
                      ))
                    : null}
                </TextField>
              </Grid>
            )
          })}
        </>
      )}
      <Button
        disabled={submitting}
        variant="contained"
        size="large"
        type="submit"
        sx={{ mt: 5, minWidth: '400px' }}
        onClick={handleSubmit}
      >
        {submitting ? 'One moment...' : 'Start Verification'}
      </Button>
    </Grid>
  )
}

export default KYC
