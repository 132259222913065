import { AxiosRequestHeaders } from 'axios'
import { AppStatsResponse, AppActivateResponse, AppVerifyResponse } from '.'
import { RequiredIdentityField, WyreResidenceAddressField, WyreUserField } from '../../store'
import { Success, Error } from '../../store/models/common'
import { Transaction, TransactionBody } from '../../store/models/transaction'
import { sonar } from '../axios'
import { resolveResponseError, resolveResponseSuccess } from '../helpers'
import { AppDetails, SonarCollectionsResponse, SonarMediaUploadResponse, TwitterUser, UpdateAppBody } from './models'

export function getIsAllowed(appName: string, apiKey: string): Promise<Success<AppStatsResponse> | Error> {
  return sonar({ appName, apiKey }).get('app/allowed').then(resolveResponseSuccess).catch(resolveResponseError)
}

export function getAppStats(appName: string, apiKey: string): Promise<Success<AppStatsResponse> | Error> {
  return sonar({ appName, apiKey }).get('app/stats').then(resolveResponseSuccess).catch(resolveResponseError)
}

export function registerApp(appName: string): Promise<Success<{}> | Error> {
  return sonar({ apiKey: process.env.REACT_APP_CREATOR_STUDIO_API_KEY as string, appName })
    .post('app/register', { redirectUrlOrigin: window.location.origin })
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function activateApp(
  appName: string,
  code: string,
  hashedPassword: string
): Promise<Success<AppActivateResponse> | Error> {
  return sonar({ apiKey: process.env.REACT_APP_CREATOR_STUDIO_API_KEY as string, appName })
    .post('app/activate', {
      code,
      hashedPassword,
    })
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function verifyPassword(appName: string, hashedPassword: string): Promise<Success<AppVerifyResponse> | Error> {
  return sonar({ apiKey: process.env.REACT_APP_CREATOR_STUDIO_API_KEY as string, appName })
    .post(`app/password-verify/${hashedPassword}`)
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function resetPassword(appName: string): Promise<Success<AppVerifyResponse> | Error> {
  return sonar({ apiKey: process.env.REACT_APP_CREATOR_STUDIO_API_KEY as string, appName })
    .post(`app/password-reset/`, { baseUrl: window.location.origin })
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function setNewPassword(
  appName: string,
  apiKey: string,
  oldPwHash: string,
  newPwHash: string
): Promise<Success<null> | Error> {
  return sonar({ apiKey, appName })
    .post(`app/password-new/${oldPwHash}/${newPwHash}`)
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function isActivated(appName: string): Promise<Success<AppVerifyResponse> | Error> {
  return sonar({ apiKey: process.env.REACT_APP_CREATOR_STUDIO_API_KEY as string, appName })
    .get(`app/activated`)
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function updateApp(appName: string, apiKey: string, updates: UpdateAppBody): Promise<Success<null> | Error> {
  return sonar({ apiKey, appName }).put(`app`, updates).then(resolveResponseSuccess).catch(resolveResponseError)
}

export function getAppDetails(appName: string, apiKey: string): Promise<Success<AppDetails> | Error> {
  return sonar({ apiKey, appName }).get(`app`).then(resolveResponseSuccess).catch(resolveResponseError)
}

export function getCollections(appName: string, apiKey: string): Promise<Success<SonarCollectionsResponse> | Error> {
  return sonar({ apiKey, appName }).get(`app/collections`).then(resolveResponseSuccess).catch(resolveResponseError)
}

export function addTransaction(
  appName: string,
  apiKey: string,
  body: TransactionBody
): Promise<Success<Transaction> | Error> {
  return sonar({ apiKey, appName })
    .post(`app/transaction`, body)
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function getTransactions(appName: string, apiKey: string): Promise<Success<Transaction[]> | Error> {
  return sonar({ apiKey, appName }).get(`app/transactions`).then(resolveResponseSuccess).catch(resolveResponseError)
}

export function twitterRedirect(
  appName: string,
  apiKey: string,
  id: string
): Promise<Success<{ url: string }> | Error> {
  return sonar({ apiKey, appName })
    .get(
      `oauth/twitter-redirect?id=${id}&redirectUrl=${encodeURIComponent(`${window.location.origin}/create-campaign`)}`
    )
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function twitterCallback(
  appName: string,
  apiKey: string,
  id: string,
  oauthToken: string,
  oauthVerifier: string
): Promise<Success<{ user: TwitterUser }> | Error> {
  return sonar({ apiKey, appName, 'oauth-token': oauthToken, 'oauth-verifier': oauthVerifier })
    .get(`oauth/twitter-callback?id=${id}`)
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function getCheckInToken(
  appName: string,
  apiKey: string,
  campaignId: string
): Promise<Success<AppDetails> | Error> {
  return sonar({ apiKey, appName })
    .get(`code/${campaignId}/token`)
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function getCheckInCode(
  // appName: string,
  // apiKey: string,
  campaignId: string,
  token: string,
  msValid: number = 20000
): Promise<Success<AppDetails> | Error> {
  return sonar({ apiKey: process.env.REACT_APP_CREATOR_STUDIO_API_KEY as string })
    .get(`code/${campaignId}?token=${token}&msValid=${msValid}`)
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}
