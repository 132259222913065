import React, { useState, Fragment } from 'react'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useRecoilState } from 'recoil'
import { errorState } from '../store'
import { Alert, AlertTitle } from '@mui/material'

export default function ErrorAlert() {
  const [error, setError] = useRecoilState(errorState)

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setError('')
  }

  const action = (
    <Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  )

  // return <Snackbar open={!!error.message} onClose={handleClose} message={error.message} action={action} />
  return (
    <Snackbar open={!!error} onClose={handleClose}>
      <Alert sx={{ minWidth: '300px' }} variant="filled" action={action} severity="error">
        {/* <AlertTitle>Error</AlertTitle> */}
        {error}
      </Alert>
    </Snackbar>
  )
}
