import FileViewer from '../Series/FileViewer'
import { Typography, Box, useMediaQuery } from '@mui/material'
import { Series } from '../../store/models/series'
import { getContractIdAndSeriesTitleFromSeriesId } from '../../utils/Series'

interface HeaderProps {
  series: Series | undefined
  id: string
}

function Header({ series, id }: HeaderProps) {
  const sm = useMediaQuery('(max-width:900px)')

  return (
    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', mb: { xs: 4, sm: 4, md: 6 }, pb: 0 }}>
      {sm ? null : <FileViewer fromIPFS mediaID={series?.mediaCid as string} />}
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', ml: { xs: 0, sm: 0, md: 3 } }}>
        <Typography variant="h1" component="h1" sx={{ mb: '12px' }}>
          Distribution Campaign
        </Typography>
        <Typography variant="h2">for {getContractIdAndSeriesTitleFromSeriesId(id)[1]}</Typography>
      </Box>
    </Box>
  )
}

export default Header
