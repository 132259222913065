import { Button, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

function EmptyDashboard() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="start"
      sx={{
        mt: 16,
      }}
    >
      <img
        src={`collection img.png`}
        alt={'Create collection'}
        loading="lazy"
        style={{ width: '135px', marginBottom: '32px' }}
      />

      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
        Start by creating a collection
      </Typography>

      <Typography variant="body1" component="p" gutterBottom sx={{ mb: 4, maxWidth: '308px', textAlign: 'center' }}>
        A collection is like a container that contains the NFTs. NFTs cannot exist outside the collection.
      </Typography>

      <Button variant="contained" color="primary" component={Link} to={'/create-collection'}>
        Create Collection
      </Button>
    </Grid>
  )
}

export default EmptyDashboard
