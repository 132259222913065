import { Button, Grid, Typography } from '@mui/material'
import { useRecoilState } from 'recoil'
import NEAR from '../../icons/near.svg'
import { signIn, initNear } from '../../services/near'
import { errorState, nearState } from '../../store'
import { ERROR_MESSAGES } from '../../utils/Error'
import useMediaQuery from '@mui/material/useMediaQuery'

function ConnectNear() {
  const xs = useMediaQuery('(max-width:900px)')
  const sm = useMediaQuery('(max-width:900px)')

  const [near, setNear] = useRecoilState(nearState)
  const [_, setError] = useRecoilState(errorState)

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="start"
      sx={{
        mt: 16,
      }}
    >
      <img
        src={NEAR}
        alt={'Connect NEAR'}
        loading="lazy"
        style={{ width: xs ? '68px' : sm ? '120px' : '135px', marginBottom: xs ? '16px' : sm ? '24px' : '32px' }}
      />

      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
        One more step...
      </Typography>

      <Typography variant="body1" component="p" gutterBottom sx={{ mb: 4, maxWidth: '308px', textAlign: 'center' }}>
        Connect your NEAR account so that you can create NFTs!
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={async () => {
          try {
            const wallet = near.wallet || (await initNear(setNear))
            await signIn(wallet, '/dashboard')
          } catch (e) {
            console.warn('error connecting with NEAR: ', e)
            setError(ERROR_MESSAGES.NEAR_ERROR)
          }
        }}
      >
        Connect with NEAR
      </Button>
    </Grid>
  )
}

export default ConnectNear
