import { Grid, Container } from '@mui/material'
import CloverIcon from '../../icons/clover-blue.svg'
import CreatorStudio from '../../icons/creator_studio.svg'
import Footer from '../Footer'
import useMediaQuery from '@mui/material/useMediaQuery'

const AuthHOC = ({ Content }: { Content: React.FC }) => {
  const xs = useMediaQuery('(max-width:900px)')
  const sm = useMediaQuery('(max-width:900px)')

  const responsiveLogo = xs ? '90px' : sm ? '120px' : '180px'
  const responsiveWordmark = xs ? '16px' : sm ? '24px' : '32px'
  const responsiveWordmarkMargin = xs ? '24px' : sm ? '32px' : '48px'

  return (
    <Grid container sx={{ minHeight: '100%', flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
      {sm ? null : (
        <Grid
          item
          md={4}
          sx={{
            height: { xs: 'none', sm: 'none', md: '100%' },
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Container
            sx={{
              alignSelf: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={CloverIcon} style={{ height: responsiveLogo, width: responsiveLogo }} alt="clover" />
            <img
              src={CreatorStudio}
              style={{
                height: responsiveWordmark,
                marginTop: responsiveWordmarkMargin,
                marginBottom: responsiveWordmarkMargin,
              }}
              alt="creator studio"
            />
          </Container>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        sx={{
          height: { md: '100%' },
          background: `url('Top blobs.png') no-repeat top center`,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Container
          sx={{
            alignSelf: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            pt: { xs: 6, sm: 12, md: 0 },
          }}
        >
          {sm ? (
            <>
              <img src={CloverIcon} style={{ height: responsiveLogo, width: responsiveLogo }} alt="clover" />
              <img
                src={CreatorStudio}
                style={{
                  height: responsiveWordmark,
                  marginTop: responsiveWordmarkMargin,
                  marginBottom: responsiveWordmarkMargin,
                }}
                alt="creator studio"
              />
            </>
          ) : null}
          <Content />
          {sm ? <Footer pt={8} pl={0} pr={0} pb={0} /> : null}
        </Container>
      </Grid>
      {sm ? null : <Footer absolute />}
    </Grid>
  )
}

export default AuthHOC
