import { CircularProgress, Container } from '@mui/material'

function Loading() {
  return (
    <Container
      maxWidth="sm"
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress sx={{ alignSelf: 'center' }} />
    </Container>
  )
}

export default Loading
