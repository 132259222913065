import { Box } from '@mui/material'
import { IOnboardItem } from '../../types'
import Title from './Title'
import Status from './Status'

function Header({ item }: { item: IOnboardItem }) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Title item={item} />
      <Status item={item} />
    </Box>
  )
}

export default Header
