import { Container, Typography, CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { getCheckInCode } from '../../services/sonar-app'
import { errorState } from '../../store'
import { SUCCESS_STATUSES } from '../../store/models'
import colors from '../../theme/colors'
import { ERROR_MESSAGES } from '../../utils/Error'

interface Props {
  campaignId: string
  token: string
  fontSizePx: number // size of check-in code font (currently 32px for Monitoring page & 80px for Check-In page)
}

let interval: number

const CheckInCode = ({ campaignId, token, fontSizePx }: Props) => {
  const [code, setCode] = useState<string | null>(null)
  const [timeRemaining, setTimeRemaining] = useState<number>(100) // timeRemaining = secsRemaining * 5 (full progress circle == 100)
  const [_, setError] = useRecoilState(errorState)

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (code && timeRemaining === 100) {
      // "if code": don't call the first time when code is null (first call is handled by useEffect below)
      if (token) getAndSetCode(token)
    }
  }, [timeRemaining])

  useEffect(() => {
    ;(async () => {
      const code = await getAndSetCode(token)
      if (!code) {
        setError(ERROR_MESSAGES.CHECK_IN_CODE_ERROR)
        return
      }
      /// START TIMER
      interval = window.setInterval(() => {
        setTimeRemaining((secs) => {
          if (secs > 0) return secs - 5
          return 100
        })
      }, 1000)
    })()
    /// CLEAR INTERVAL ON UNMOUNT
    return () => window.clearInterval(interval)
  }, [])

  const getAndSetCode = async (token: string) => {
    let res
    try {
      res = await getCheckInCode(campaignId, token)
      if (!SUCCESS_STATUSES.includes(res.status)) {
        console.warn('error getting code: ', res)
        return null
      }
    } catch (e) {
      console.warn('Error getting code: ', e)
      setError(ERROR_MESSAGES.CHECK_IN_CODE_ERROR)
      window.clearInterval(interval)
      return null
    }
    setCode((res.data.code as number).toString())
    return res.data.code
  }

  if (!code) return null

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <Typography
          variant="h1"
          sx={{ fontSize: `${fontSizePx}px`, color: colors.BLUE, fontFamily: 'Manrope-SemiBold' }}
        >
          {code.slice(0, 3) + ' ' + code.slice(3)}
        </Typography>
        <CircularProgress
          variant="determinate"
          value={timeRemaining}
          thickness={22}
          size={Math.floor(fontSizePx * 0.5)}
          sx={{ ml: fontSizePx > 32 ? 3 : 2 }} // this is such a hack but I'm not sure of a better way rn... basically, the margin should be less in the Montioring page, which uses 32px fontSize
        />
      </Container>
    </Container>
  )
}

export default CheckInCode
