import { Button, Grid, Typography, TextField, Select, MenuItem, Container, Box, useMediaQuery } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { NFTStorage } from 'nft.storage'
import { createSeries, initStore } from '../store/dispatchers'
import { useRecoilState } from 'recoil'
import {
  loadingState,
  seriesState,
  collectionsState,
  userState,
  nearState,
  campaignsState,
  errorState,
} from '../store/index'
import { RoyaltyRequest } from '../services/series/models'
import AddIcon from '@mui/icons-material/Add'
import FileUploader from '../components/Series/FileUploader'
import Royalties from '../components/Series/Royalties'
import defaultRoyalty from '../utils/Royalty'
import { getTransactionInformation, initNear, sendTokens, signIn } from '../services/near'
import { WalletConnection } from 'near-api-js'
import { addTransaction, updateApp } from '../services/sonar-app'
import { CreateSeriesRequest } from '../services/series'
import { SUCCESS_STATUSES } from '../store/models'
import { TransactionBody, TransactionStatus } from '../store/models/transaction'
import { ERROR_MESSAGES } from '../utils/Error'
import { isASCIIExtended } from '../utils/Other'
import { responsiveContainer } from '../utils/styles'
import CTACancelButtonContainer from '../components/CTACancelButtonContainer'
import { Constants } from '../constants'
import { PreparedFile } from '../types'
import { ContactSupportOutlined } from '@mui/icons-material'

export const LS_NEAR_ID = 'NEAR_ID'
const LS_MEDIA_FILENAME = 'MEDIA_FILENAME'
const LS_IPFS_DIRECTORY_CID = 'DIRECTORY_CID'
const LS_C_TITLE = 'C_TITLE'
const LS_S_TITLE = 'S_TITLE'
const LS_DESC = 'DESC'
const LS_COPIES = 'COPIES'
const LS_ROYALTY = 'ROYALTY'
const LS_DID_LOCK_CREATE_SERIES_FORM = 'DID_LOCK_CREATE_SERIES_FORM'

const MAX_COPIES = 1000000

const PER_NFT_COST = 0.05

function CreateSeries() {
  const navigate = useNavigate()
  const [, setLoading] = useRecoilState(loadingState)
  const [isFormLocked, setIsFormLocked] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [collections, setCollections] = useRecoilState(collectionsState)
  const [series, setSeries] = useRecoilState(seriesState)
  const [, setCampaigns] = useRecoilState(campaignsState)
  const [user] = useRecoilState(userState)
  const [near, setNear] = useRecoilState(nearState)
  const [_, setError] = useRecoilState(errorState)

  const [searchParams, setSearchParams] = useSearchParams()
  const txHash = searchParams.get('transactionHashes')
  const account_id = searchParams.get('account_id')

  const sm = useMediaQuery('(max-width:900px)')

  // MEDIA
  const [mediaError, setMediaError] = useState<string>('')
  const [files, setFiles] = useState<PreparedFile[]>([]) // TODO for generative

  async function handleMediaChange(files: PreparedFile[]) {
    const [file] = files
    if (file) {
      // save the name of the original file, then rename for IPFS storage
      setFiles(files)
    }
  }

  const handleClearMediaError = () => {
    if (mediaError) setMediaError('')
  }

  const handleMediaError = (error: any) => setMediaError(error)

  // COLLECTION
  const location = useLocation() as any
  const collectionId = location.state?.collectionId
  const defaultContractId = collectionId ? collectionId : collections.length !== 0 ? collections[0].contractId : ''
  const [contractId, setContractId] = useState(defaultContractId)
  function handleChangeContractId(e: any) {
    setContractId(e.target.value)
  }

  // TITLE
  const [title, setTitle] = useState('')
  const [titleError, setTitleError] = useState<string>('')
  function handleChangeTitle(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    if (titleError) setTitleError('')
    const val = e.target.value
    if (val && !isASCIIExtended(val)) {
      setDescriptionError('Invalid character')
      return
    }
    setTitle(val)
    if (seriesTitles.includes(val.trim().toLowerCase()))
      setTitleError('An NFT with this title already exists for this Collection.')
  }

  // DESCRIPTION
  const [description, setDescription] = useState('')
  const [descriptionError, setDescriptionError] = useState<string>('')
  function handleChangeDescription(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    if (descriptionError) setDescriptionError('')
    const val = e.target.value
    if (val && !isASCIIExtended(val)) {
      setDescriptionError('Invalid character')
      return
    }
    setDescription(val)
    // TODO: Placeholder
    if (description.trim().length > 140) setDescriptionError('Exceeds 140 character limit')
  }

  // COPIES
  const [copies, setCopies] = useState(1)
  const [copiesError, setCopiesError] = useState<string>('')
  function handleChangeCopies(e: any) {
    if (copiesError) setCopiesError('')
    const val = e.target.value
    if (val === '0') {
      setCopiesError('Must be greater than 0')
      return
    }
    setCopies(parseInt(val))
    // TODO: Placeholder
    if (val > MAX_COPIES) setCopiesError('Exceeds 1,000,000 copies limit')
  }

  const totalCost = Math.round(PER_NFT_COST * copies * 100) / 100

  // ROYALTIES
  const defaultRoyalties = defaultRoyalty as RoyaltyRequest
  const [royalty, setRoyalty] = useState(defaultRoyalties)
  const [royaltiesError, setRoyaltiesError] = useState<boolean>(false)

  function handleChangeRoyalty(royalties: RoyaltyRequest) {
    setRoyalty(royalties)
  }

  const isValid = () => {
    let valid = true

    if (
      contractId === null ||
      title.trim() === '' ||
      description === '' ||
      description.length > 140 ||
      copies === null ||
      files.length === 0 ||
      royaltiesError
    )
      valid = false
    return valid
  }

  // SUBMIT
  async function handleCreateSeries() {
    setIsRequesting(true)
    // we're about to trigger a NEAR wallet payment, after which user will be redirected back to this page to complete series creation
    // save details on local storage so they can be repopulated after redirect
    localStorage.setItem(LS_C_TITLE, contractId)
    localStorage.setItem(LS_S_TITLE, title.trim())
    localStorage.setItem(LS_DESC, description.trim())
    localStorage.setItem(LS_COPIES, copies.toString())
    localStorage.setItem(LS_ROYALTY, JSON.stringify(royalty))
    localStorage.setItem(LS_DID_LOCK_CREATE_SERIES_FORM, 'true')

    try {
      // upload as directory to IPFS
      const [{ type, data, extension }] = files
      const fileName = `1.${extension}`
      const mediaFile = new File([data], fileName, { type })
      localStorage.setItem(LS_MEDIA_FILENAME, fileName)
      const storage = new NFTStorage({ token: Constants.NFT_STORAGE_TOKEN as string })
      const cid = await storage.storeDirectory([mediaFile])
      localStorage.setItem(LS_IPFS_DIRECTORY_CID, cid)
      const wallet = (near.wallet as WalletConnection) || (await initNear(setNear))
      if (!wallet.isSignedIn()) {
        try {
          await signIn(wallet, '/create-series')
        } catch (e) {
          console.warn('error signing in with NEAR: ', e)
          setError(ERROR_MESSAGES.NEAR_ERROR)
          setIsRequesting(false)
        }
      } else {
        // save acct id to check tx hash
        localStorage.setItem(LS_NEAR_ID, wallet.getAccountId())
        sendTokens(wallet, totalCost.toString())
      }
    } catch (e) {
      console.warn('Error uploading to NFT.storage: ', e)
      setError(ERROR_MESSAGES.MEDIA_UPLOAD_ERROR)
      setIsRequesting(false)
      return
    }
  }

  // SERIES
  const seriesTitles = useMemo(() => {
    if (Array.isArray(series))
      return series.filter((s) => s.contractId === contractId).map((s) => s.title.toLowerCase())
    // TODO: series should always be an array? for some reason it's an object after creating a series
    return []
  }, [series, contractId])

  useEffect(() => {
    if (document.getElementById('button-submit') && (txHash || account_id) && localStorage.getItem(LS_S_TITLE)) {
      document.getElementById('button-submit')?.scrollIntoView()
    }
  }, [document.getElementById('button-submit')])

  useEffect(() => {
    ;(async () => {
      const seriesTitle = localStorage.getItem(LS_S_TITLE) // pre-trimmed
      if (txHash && user.apiToken && seriesTitle) {
        // we've just come back in from a redirect (user has paid for series via NEAR wallet)
        // set local storage vars back to state for display purposes and proceed to create series
        setIsRequesting(true)
        setTitle(seriesTitle)
        const fn = localStorage.getItem(LS_MEDIA_FILENAME)!
        const contract = localStorage.getItem(LS_C_TITLE)!
        setContractId(contract)
        const desc = localStorage.getItem(LS_DESC)! // pre-trimmed
        setDescription(desc)
        const cp = parseInt(localStorage.getItem(LS_COPIES)!, 10)
        setCopies(cp)
        const r = JSON.parse(localStorage.getItem(LS_ROYALTY)!)
        setRoyalty(r)
        const mediaLocation = localStorage.getItem(LS_IPFS_DIRECTORY_CID)!
        try {
          const { receiverId, signerId, amount, status, error } = await getTransactionInformation(txHash)
          const valid = signerId && receiverId && amount && status !== 'error'
          if (!valid || error) {
            /// something weird going on with transaction
            setError(ERROR_MESSAGES.NEAR_TRANSACTION_ERROR)
            /// TODO: log this error to Sentry
            return
          }
          // build transaction record
          const tx: TransactionBody = {
            category: 'series',
            timestamp: Date.now(),
            amount,
            receiverId,
            signerId,
            isRefund: false,
            hash: txHash,
            status: status as TransactionStatus,
          }
          const addTransactionRes = await addTransaction(user.email, user.apiToken, tx)
          if (!SUCCESS_STATUSES.includes(addTransactionRes.status)) {
            console.warn('error adding transaction: ', addTransactionRes)
            setError(ERROR_MESSAGES.NEAR_POST_TRANSACTION_ERROR)
            return
          }
          if (status !== 'success') {
            setError(ERROR_MESSAGES.NEAR_TRANSACTION_ERROR)
            setIsRequesting(false)
            return
          }
          if (contract && seriesTitle && desc && cp && mediaLocation) {
            let requestData = {
              contractId: contract,
              title: seriesTitle,
              description: desc,
              copies: cp,
              royalty: r,
              mediaCid: mediaLocation,
              assets: [[fn, cp, '']],
              coverAsset: fn,
            }
            const response = await createSeries(
              user.email,
              user.apiToken,
              { nftContent: requestData as CreateSeriesRequest, fundingHash: tx.hash },
              series,
              setSeries,
              collections,
              setCollections
            )
            localStorage.removeItem(LS_NEAR_ID)
            localStorage.removeItem(LS_MEDIA_FILENAME)
            localStorage.removeItem(LS_C_TITLE)
            localStorage.removeItem(LS_S_TITLE)
            localStorage.removeItem(LS_DESC)
            localStorage.removeItem(LS_COPIES)
            localStorage.removeItem(LS_ROYALTY)
            localStorage.removeItem(LS_IPFS_DIRECTORY_CID)
            localStorage.removeItem(LS_DID_LOCK_CREATE_SERIES_FORM)

            if (SUCCESS_STATUSES.includes(response.status)) {
              await initStore(user.email, user.apiToken, setCollections, setSeries, setCampaigns, setLoading)
              setIsRequesting(false)
              navigate('/dashboard')
            } else {
              console.warn('Error creating series: ', response)
              setError(ERROR_MESSAGES.CREATE_SERIES_ERROR)
              localStorage.removeItem(LS_MEDIA_FILENAME)
              setIsRequesting(false)
            }
          } else {
            // something went wrong setting items in local storage &/or retrieving them; series won't be created but tx has completed
            console.warn('missing required properties to create series')
            setIsRequesting(false)
            localStorage.removeItem(LS_MEDIA_FILENAME)
            setError(ERROR_MESSAGES.CREATE_SERIES_ERROR)
          }
        } catch (e) {
          console.warn('Error getting transaction info: ', e)
          setError(ERROR_MESSAGES.NEAR_TRANSACTION_ERROR)
          return
        }
      }
    })()
  }, [txHash, user.apiToken])

  useEffect(() => {
    ;(async () => {
      if (account_id) {
        try {
          if (account_id !== user.nearId) {
            // update user
            await updateApp(user.email, user.apiToken, { nearId: account_id })
          }
        } catch (e) {
          console.warn('error updating nearId: ', e)
        } finally {
          // save acct id to check tx hash
          localStorage.setItem(LS_NEAR_ID, account_id)
          const wallet = (near.wallet as WalletConnection) || (await initNear(setNear))
          setSearchParams({})
          sendTokens(wallet, (PER_NFT_COST * parseInt(localStorage.getItem(LS_COPIES) as string, 10)).toString())
        }
      }
    })()
  }, [account_id])

  useEffect(() => {
    const isFormLocked = localStorage.getItem(LS_DID_LOCK_CREATE_SERIES_FORM) === 'true'
    if (isFormLocked) {
      setIsFormLocked(true)
    }
  }, [])

  return (
    <Grid
      id="grid-container"
      container
      spacing={0}
      direction="column"
      alignItems="start"
      justifyContent="start"
      sx={{ ...responsiveContainer() }}
    >
      <Container maxWidth="sm">
        <Typography variant="h1" component="h1" sx={{ mb: 4 }}>
          Create NFT
        </Typography>

        <Grid container spacing={0} direction="row" alignItems="start" justifyContent="start">
          <Grid container spacing={0} direction="column" alignItems="start" justifyContent="start">
            {/* MEDIA UPLOAD */}{' '}
            <Box sx={{ width: '100%', mb: 3, cursor: 'pointer' }} onClick={handleClearMediaError}>
              <Typography variant="subtitle1" component="h2" gutterBottom mb={3}>
                Upload File
              </Typography>
              {isFormLocked ? (
                <></>
              ) : (
                <FileUploader onMediaChange={handleMediaChange} onMediaError={handleMediaError} />
              )}

              {mediaError && (
                <Typography color="error" mt={2}>
                  {mediaError}
                </Typography>
              )}
            </Box>
            {/* COLLECTION */}
            <Select
              id="collection-select"
              labelId="collection-select-label"
              value={contractId}
              label="Collection"
              variant="filled"
              onChange={handleChangeContractId}
              defaultValue={contractId}
              fullWidth
              sx={{ mb: 3 }}
              disabled={isRequesting}
            >
              {collections.map((c) => (
                <MenuItem value={c.contractId} key={c.contractId}>
                  {c.title}
                </MenuItem>
              ))}
            </Select>
            <Box width="100%" mt={2}>
              <Typography variant="subtitle1" component="h2" gutterBottom mb={3}>
                Title & description
              </Typography>
              <Box width="100%">
                {/* TITLE */}
                <TextField
                  // autoFocus={!isRequesting}
                  id="title-input"
                  label="Title"
                  variant="filled"
                  onFocus={() => {
                    if (titleError) setTitleError('')
                  }}
                  error={titleError.length === 0 ? false : true}
                  helperText={titleError.length === 0 ? null : titleError}
                  onChange={handleChangeTitle}
                  value={title}
                  fullWidth
                  InputProps={{ disableUnderline: true }}
                  sx={{ mb: 3 }}
                  disabled={isRequesting}
                />
                {/* DESCRIPTION */}
                <TextField
                  id="description-input"
                  label="Description"
                  variant="filled"
                  onChange={handleChangeDescription}
                  onFocus={() => {
                    if (descriptionError) setDescriptionError('')
                  }}
                  error={descriptionError.length === 0 ? false : true}
                  helperText={descriptionError.length === 0 ? null : descriptionError}
                  fullWidth
                  InputProps={{ disableUnderline: true }}
                  value={description}
                  multiline
                  minRows={4}
                  maxRows={4}
                  sx={{ mb: 3 }}
                  disabled={isRequesting}
                />
              </Box>
            </Box>
            <Box width="100%" mt={2}>
              <Typography variant="subtitle1" component="h2" gutterBottom mb={3}>
                Number of copies
              </Typography>
              <Box width="100%">
                {/* COPIES */}
                <TextField
                  id="copies-input"
                  label="Copies"
                  variant="filled"
                  onChange={handleChangeCopies}
                  value={copies || ''}
                  onFocus={() => {
                    if (copiesError) setCopiesError('')
                  }}
                  error={copiesError.length === 0 ? false : true}
                  helperText={copiesError.length === 0 ? null : copiesError}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                  sx={{ mb: 3 }}
                  disabled={isRequesting}
                />
              </Box>
            </Box>
            {/* ROYALTIES */}
            {!isRequesting && ( // don't display royalties when requesting as they don't currently match the actual royalties that have been set
              <Box width="100%" mb={5} mt={2}>
                <Typography variant="subtitle1" component="h2" gutterBottom mb={3}>
                  Royalties
                </Typography>
                <Royalties
                  start={royalty}
                  handleChangeRoyalty={handleChangeRoyalty}
                  isRequesting={isRequesting}
                  royaltiesError={royaltiesError}
                  setRoyaltiesError={setRoyaltiesError}
                />
              </Box>
            )}
            {/* BUTTONS */}
            <CTACancelButtonContainer>
              <LoadingButton
                id="button-submit"
                loading={isRequesting}
                disabled={!isValid()}
                loadingPosition="start"
                variant="contained"
                fullWidth={sm ? true : false}
                onClick={handleCreateSeries}
                startIcon={<AddIcon />}
                sx={{ mb: { xs: 2, sm: 2, md: 0 } }}
              >
                {isRequesting ? 'Creating NFT' : `Create NFT for ${totalCost}N`}
              </LoadingButton>
              <Button
                disabled={isRequesting}
                variant="outlined"
                color="secondary"
                component={Link}
                to={'/dashboard'}
                fullWidth={sm ? true : false}
              >
                Cancel
              </Button>
            </CTACancelButtonContainer>
          </Grid>

          {/* TODO: Preview */}
          <Grid container spacing={0} direction="column" alignItems="start" justifyContent="start"></Grid>
        </Grid>
      </Container>
    </Grid>
  )
}

export default CreateSeries
