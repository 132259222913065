import { useCallback, useState } from 'react'
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOnExit,
  PlaidLinkOnExitMetadata,
  PlaidLinkError,
  PlaidLinkOnEvent,
  PlaidLinkOnEventMetadata,
  PlaidLinkStableEvent,
} from 'react-plaid-link'
import { useRecoilState } from 'recoil'
import { exchangePlaidPublicToken } from '../../services/sonar-payments'
import { errorState, userState, WyreUser } from '../../store'
import { SUCCESS_STATUSES } from '../../store/models'
import { ERROR_MESSAGES } from '../../utils/Error'

const PlaidLink = ({
  linkToken,
  setInitiatePlaid,
}: {
  linkToken: string
  setInitiatePlaid: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [user, setUser] = useRecoilState(userState)
  const [_, setError] = useRecoilState(errorState)
  const [success, setSuccess] = useState<boolean>(false)

  // SEE https://plaid.com/docs/link/web/#onsuccess
  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      // log and save metadata
      // exchange public token
      const exchangeRes = await exchangePlaidPublicToken(
        user.email,
        user.apiToken,
        public_token,
        metadata.accounts[0].id
      )
      if (!SUCCESS_STATUSES.includes(exchangeRes.status)) {
        console.warn('Error exchanging public token: ', exchangeRes)
        setError(ERROR_MESSAGES.PLAID_EXCHANGE_TOKEN_ERROR)
      } else {
        const { wyreUser } = exchangeRes.data as {
          wyreUser: WyreUser
        }
        setUser({
          ...user,
          wyreUser,
        })
        // setOnboardItems(pa)
        setSuccess(true)
      }
      setInitiatePlaid(false)
    },
    []
  )

  // SEE https://plaid.com/docs/link/web/#onexit
  const onExit = useCallback<PlaidLinkOnExit>((error: null | PlaidLinkError, metadata: PlaidLinkOnExitMetadata) => {
    // log and save error and metadata
    // handle invalid link token
    if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
      // generate new link token
      console.log('TODO: GENERATE NEW LINK TOKEN!')
    }
    // to handle other error codes, see https://plaid.com/docs/errors/
    setInitiatePlaid(false)
  }, [])

  // SEE https://plaid.com/docs/link/web/#onevent
  const onEvent = useCallback<PlaidLinkOnEvent>(
    (eventName: PlaidLinkStableEvent | string, metadata: PlaidLinkOnEventMetadata) => {},
    []
  )

  // The usePlaidLink hook manages Plaid Link creation
  // It does not return a destroy function;
  // instead, on unmount it automatically destroys the Link instance
  const config: PlaidLinkOptions = {
    onSuccess,
    onExit,
    onEvent,
    token: linkToken,
    //required for OAuth; if not using OAuth, set to null or omit:
    // receivedRedirectUri: window.location.href,
  }
  const { open, /*exit,*/ ready } = usePlaidLink(config)

  // Open Link
  if (ready && !success) {
    open()
  }

  return null
}

export default PlaidLink
