export const responsiveTypography = (marginBottom: number = 4) => {
  return {
    mb: {
      xs: 2,
      sm: marginBottom,
    },
    flex: 1,
    width: '100%',
    textAlign: {
      xs: 'center',
      sm: 'left',
    },
  }
}

export const responsiveContainer = () => {
  return {
    flex: 1,
    pt: {
      xs: 4,
      sm: 4,
      md: 10,
    },
    pr: {
      xs: 0,
      sm: 4,
      md: 4,
    },
    pb: {
      xs: 4,
      sm: 4,
      md: 10,
    },
    pl: {
      xs: 0,
      sm: 4,
      md: 4,
    },
    background: {
      xs: `url('Top blobs.png') no-repeat top left`,
      sm: `url('Top blobs.png') no-repeat top center`,
      md: `none`,
    },
  }
}
