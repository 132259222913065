export function Logomark({ height = 32 }: { height?: number }) {
  const widthFactor = 180 / 32

  return (
    <svg
      width={widthFactor * height + 'px'}
      height={height + 'px'}
      viewBox="0 0 180 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.3984 15.641C40.3984 20.297 43.6432 23.489 47.8082 23.489C52.07 23.489 54.54 20.897 54.8063 16.985L53.3292 16.913C53.1839 19.985 51.1741 22.217 47.8082 22.217C44.4665 22.217 41.9966 19.697 41.9966 15.641C41.9966 11.585 44.4665 9.06497 47.8082 9.06497C51.1741 9.06497 53.1839 11.297 53.3292 14.369L54.8063 14.297C54.54 10.385 52.07 7.79297 47.8082 7.79297C43.6432 7.79297 40.3984 10.985 40.3984 15.641Z"
        fill="black"
      />
      <path
        d="M63.3551 12.977C62.9677 12.641 62.5076 12.497 61.8538 12.497C60.304 12.497 59.2386 13.769 58.8269 14.969V12.785H57.3498V23.201H58.8269V17.489C58.8269 15.401 60.0619 13.841 61.5874 13.841C62.217 13.841 62.5802 13.937 63.1856 14.249L63.3551 12.977Z"
        fill="black"
      />
      <path
        d="M73.0127 19.481C72.5769 21.329 71.3903 22.289 69.4531 22.289C67.3706 22.289 65.7482 20.657 65.6514 18.353H74.2961V17.681C74.2961 14.849 72.6495 12.497 69.3563 12.497C66.3778 12.497 64.1258 14.849 64.1258 18.137C64.1258 21.353 66.402 23.489 69.4531 23.489C72.0199 23.489 73.7634 22.025 74.2719 19.649L73.0127 19.481ZM65.6756 17.321C65.9177 15.377 67.2738 13.649 69.3563 13.649C71.584 13.649 72.6737 15.401 72.7221 17.321H65.6756Z"
        fill="black"
      />
      <path
        d="M83.4353 18.473C83.4353 20.801 81.8614 22.361 79.8031 22.361C78.4229 22.361 77.7691 21.545 77.7691 20.561C77.7691 19.169 78.9556 18.785 80.8686 18.377L83.4353 17.825V18.473ZM84.8398 16.433C84.8398 13.865 83.4353 12.497 80.8201 12.497C78.5439 12.497 76.9942 13.865 76.5825 15.737L77.8417 15.977C78.2049 14.633 79.1493 13.649 80.8444 13.649C82.491 13.649 83.4353 14.633 83.4353 16.217V16.745L80.6264 17.321C78.0112 17.849 76.2677 18.641 76.2677 20.609C76.2677 22.409 77.5753 23.489 79.5367 23.489C81.3286 23.489 82.8784 22.433 83.4353 21.209C83.4838 22.529 84.2344 23.417 85.2757 23.417C86.0021 23.417 86.3411 23.153 86.6559 22.793V22.001C86.3653 22.169 85.9537 22.265 85.7115 22.265C85.1788 22.265 84.8398 22.001 84.8398 21.449V16.433Z"
        fill="black"
      />
      <path
        d="M88.8027 20.897C88.8027 22.793 89.6987 23.489 91.1031 23.489C92.1686 23.489 92.9193 23.201 93.6699 22.625V21.593C93.0646 21.953 92.3381 22.313 91.6117 22.313C90.6431 22.313 90.2556 21.857 90.2556 20.921V13.865H93.3067V12.785H90.2556V9.88097H89.505L89.1902 11.369C88.9722 12.377 88.7785 12.833 87.8099 13.025L87.1803 13.145V13.865H88.8027V20.897Z"
        fill="black"
      />
      <path
        d="M96.4579 17.993C96.4579 15.449 97.8139 13.649 100.163 13.649C102.536 13.649 103.892 15.449 103.892 17.993C103.892 20.537 102.536 22.337 100.163 22.337C97.8139 22.337 96.4579 20.537 96.4579 17.993ZM105.442 17.993C105.442 14.753 103.165 12.497 100.163 12.497C97.1843 12.497 94.9081 14.753 94.9081 17.993C94.9081 21.233 97.1843 23.489 100.163 23.489C103.165 23.489 105.442 21.233 105.442 17.993Z"
        fill="black"
      />
      <path
        d="M113.894 12.977C113.507 12.641 113.047 12.497 112.393 12.497C110.843 12.497 109.778 13.769 109.366 14.969V12.785H107.889V23.201H109.366V17.489C109.366 15.401 110.601 13.841 112.127 13.841C112.756 13.841 113.119 13.937 113.725 14.249L113.894 12.977Z"
        fill="black"
      />
      <path
        d="M119.395 17.777C119.395 21.449 122.083 23.489 125.715 23.489C128.936 23.489 131.26 21.881 131.26 19.217C131.26 16.817 129.88 15.737 127.192 15.209L124.48 14.681C122.47 14.297 121.381 13.745 121.381 12.041C121.381 10.145 122.737 8.96897 125.158 8.96897C127.943 8.96897 129.59 10.577 129.638 13.337L131.042 13.217C131.042 9.80897 128.839 7.79297 125.134 7.79297C121.913 7.79297 119.904 9.59297 119.904 12.089C119.904 14.369 121.405 15.521 123.584 15.953L126.684 16.553C128.887 16.985 129.807 17.801 129.807 19.337C129.807 21.209 128.234 22.313 125.715 22.313C122.93 22.313 120.896 20.657 120.775 17.657L119.395 17.777Z"
        fill="black"
      />
      <path
        d="M134.21 20.897C134.21 22.793 135.106 23.489 136.511 23.489C137.576 23.489 138.327 23.201 139.078 22.625V21.593C138.472 21.953 137.746 22.313 137.019 22.313C136.051 22.313 135.663 21.857 135.663 20.921V13.865H138.714V12.785H135.663V9.88097H134.913L134.598 11.369C134.38 12.377 134.186 12.833 133.218 13.025L132.588 13.145V13.865H134.21V20.897Z"
        fill="black"
      />
      <path
        d="M148.28 19.025C148.28 21.041 146.851 22.337 145.18 22.337C143.388 22.337 142.492 21.137 142.492 19.289V12.785H141.015V19.241C141.015 21.737 142.081 23.489 144.793 23.489C146.367 23.489 147.771 22.553 148.28 21.281V23.201H149.757V12.785H148.28V19.025Z"
        fill="black"
      />
      <path
        d="M160.982 18.353C160.982 20.849 159.336 22.289 157.253 22.289C155.026 22.289 153.742 20.633 153.742 17.993C153.742 15.353 155.026 13.697 157.253 13.697C159.336 13.697 160.982 15.137 160.982 17.633V18.353ZM160.934 23.201H162.411V8.08097H160.934V15.089C160.474 13.769 159.094 12.497 157.06 12.497C153.936 12.497 152.192 14.921 152.192 17.993C152.192 21.065 153.936 23.489 157.06 23.489C159.094 23.489 160.474 22.241 160.934 20.921V23.201Z"
        fill="black"
      />
      <path
        d="M165.552 12.785V23.201H167.029V12.785H165.552ZM165.503 10.025H167.077V8.08097H165.503V10.025Z"
        fill="black"
      />
      <path
        d="M171.016 17.993C171.016 15.449 172.372 13.649 174.721 13.649C177.094 13.649 178.45 15.449 178.45 17.993C178.45 20.537 177.094 22.337 174.721 22.337C172.372 22.337 171.016 20.537 171.016 17.993ZM180 17.993C180 14.753 177.724 12.497 174.721 12.497C171.743 12.497 169.466 14.753 169.466 17.993C169.466 21.233 171.743 23.489 174.721 23.489C177.724 23.489 180 21.233 180 17.993Z"
        fill="black"
      />
      <path
        d="M26.6068 1.01479C26.6068 0.827568 26.5751 0.640346 26.4801 0.484328C26.4167 0.359514 26.3534 0.297106 26.2901 0.234699C26.2584 0.203495 26.1951 0.172292 26.1634 0.141088C25.72 -0.139745 25.1183 0.0162735 24.865 0.453124C21.223 6.2882 17.0426 10.2199 12.9889 14.0267C9.88535 16.9286 6.90844 19.737 4.34322 23.2006C4.31155 21.6092 4.4699 19.4561 5.19829 17.1471C7.22513 10.8439 12.4506 6.35061 20.7796 3.79191C21.2863 3.63589 21.5713 3.10543 21.413 2.60618C21.2546 2.10692 20.7163 1.82608 20.2096 1.9821C14.034 3.88552 6.08504 7.9732 3.36147 16.6478C2.0947 20.6731 2.41139 24.1991 2.66475 25.6657C1.71467 27.1946 0.827927 28.8796 0.0678632 30.7207C-0.122153 31.1887 0.0995321 31.7504 0.574572 31.9376C0.701249 32 0.827927 32 0.922935 32C1.30297 32 1.65133 31.7816 1.80968 31.4071C2.44306 29.847 3.17146 28.4428 3.96319 27.1322C5.16663 27.2882 6.30672 27.3819 7.41515 27.3819C13.749 27.3819 18.6578 24.6983 22.0147 19.3313C22.2997 18.8945 22.1414 18.3016 21.698 18.052C21.2546 17.7711 20.6529 17.9272 20.3996 18.364C17.011 23.731 11.8805 26.1025 5.07162 25.3848C7.73184 21.5468 10.9621 18.52 14.2874 15.3997C17.7393 12.1233 21.318 8.7845 24.5799 4.25997C24.3899 6.53783 23.9465 9.6894 22.9331 12.8722L19.5762 14.2139C19.1011 14.4011 18.8478 14.9628 19.0695 15.4309C19.2278 15.8053 19.5762 16.0237 19.9562 16.0237C20.0829 16.0237 20.2096 15.9925 20.3046 15.9613L24.0732 14.4324C24.3266 14.3387 24.5166 14.1203 24.6116 13.8707C25.7517 10.5007 26.2267 7.19311 26.4484 4.97765C26.6384 2.73099 26.6701 1.60766 26.6068 1.01479Z"
        fill="#4667E3"
      />
    </svg>
  )
}

;<svg width="180" height="32" xmlns="http://www.w3.org/2000/svg"></svg>
