import styled from '@emotion/styled'
import { Box, BoxProps } from '@mui/material'

type GradientBorderProps = {
  gradient?: string
  thickness?: number
  backgroundColor?: string
} & BoxProps

const Gradient = styled(Box)<any>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: ${({ gradient }) => gradient};
  width: 100%;
  height: 100%;
`

function GradientBorder({
  gradient = 'linear-gradient(90deg, rgba(72,227,180,1) 0%, rgba(74,196,248,1) 50%, rgba(239,116,217,1) 100%);',
  thickness = 2,
  borderRadius = 3,
  backgroundColor = '#fff',
  children,
  ...props
}: GradientBorderProps) {
  return (
    <Box position="relative" {...props} width="100%" height="100%">
      <Box
        position="absolute"
        sx={{
          backgroundColor,
          zIndex: 2,
          top: thickness,
          left: thickness,
          width: `calc(100% - ${thickness * 2}px)`,
          height: `calc(100% - ${thickness * 2}px)`,
          borderRadius,
        }}
      />
      <Gradient gradient={gradient} borderRadius={borderRadius} />
      <Box position="relative" sx={{ zIndex: 3 }}>
        {children}
      </Box>
    </Box>
  )
}

export default GradientBorder
