import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import { useRef, useState } from 'react'
import TermsConditions from './TermsConditions'

const TermsAndConditionsDialog = ({
  openDialog,
  setOpenDialog,
  handleAccept,
}: {
  openDialog: boolean
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
  handleAccept: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>
}) => {
  const [agreed, setAgreed] = useState<boolean>(false)
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState<boolean>(false)

  const handleCloseDialog = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason !== 'backdropClick') setOpenDialog(false)
  }

  const contentRef = useRef()
  const onScroll = () => {
    setIsCheckboxEnabled(true)
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        scroll="paper"
        maxWidth='md'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Terms & Conditions</DialogTitle>
        <DialogContent ref={contentRef} onScroll={onScroll} dividers>
          <DialogContentText sx={{ mb: 2 }}>Updated May 20, 2022</DialogContentText>
          <DialogContentText tabIndex={-1}>
            <TermsConditions />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Checkbox sx={{ p: 0 }} value={agreed} disabled={!isCheckboxEnabled} onChange={() => setAgreed(!agreed)} />
            <Typography sx={{ ml: 1 }}>I have read and agree to these terms & conditions</Typography>
          </Box>
          <Button variant="outlined" color="secondary" size="small" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button disabled={!agreed} variant="contained" color="primary" size="small" onClick={handleAccept}>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default TermsAndConditionsDialog
