import { Box, Button, Grid, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useNavigate } from 'react-router-dom'
import { BlockExplorer } from '../../icons/BlockExplorer'
import { InfoOutline } from '../../icons/InfoOutline'
import explorer from '../../utils/Explorer'
import colors from '../../theme/colors'
import { SonarCollection } from '../../services/sonar-app'
import useMediaQuery from '@mui/material/useMediaQuery'
import MoreVertIcon from '@mui/icons-material/MoreVert'

interface CollectionHeaderProps {
  collection: SonarCollection
  openMenuCo: boolean
  handleMenuClickCo: (event: any, collection: SonarCollection) => void
}

function CollectionHeader({ collection, openMenuCo, handleMenuClickCo }: CollectionHeaderProps) {
  const navigate = useNavigate()

  const xs = useMediaQuery('(max-width: 600px)')

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      justifyContent="space-between"
      sx={{
        borderBottom: '1px solid rgba(38, 38, 38, 0.08);',
        mt: { xs: 4, sm: 4, md: 8 },
        mb: { xs: 2, sm: 2, md: 4 },
        alignItems: { xs: 'start', sm: 'center' },
      }}
    >
      <Box>
        <Grid container spacing={0} direction="row" alignItems="center" justifyContent="start">
          <Typography variant="h3" component="h2">
            {collection.title}
          </Typography>
          <Tooltip
            title="A Collection is a Smart Contract deployed on the NEAR blockchain. It can contain many NFTs."
            sx={{ ml: 1 }}
          >
            <IconButton>
              <InfoOutline />
            </IconButton>
          </Tooltip>
        </Grid>
        <Typography variant="body1" component="p" sx={{ mb: 4 }}>
          Contains {collection.series.length} NFT{collection.series.length > 1 ? 's' : ''}
        </Typography>
      </Box>

      <Box>
        {xs ? (
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            aria-label="More"
            aria-controls={openMenuCo ? 'collection-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenuCo ? 'true' : undefined}
            onClick={(e) => handleMenuClickCo(e, collection)}
            sx={{ pl: 0, pr: 0, minWidth: '40px', '&:hover': { color: colors.SECONDARY_HOVER_BG } }}
          >
            <MoreVertIcon />
          </Button>
        ) : (
          <>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => window.open(explorer.getAccountURL(collection.contractId), '_blank')}
              sx={{ pl: 0, pr: 0, minWidth: '40px', '&:hover': { color: colors.SECONDARY_HOVER_BG } }}
            >
              <BlockExplorer />
            </Button>

            {collection.series.length > 0 ? (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  navigate('/create-series', {
                    state: { collectionId: collection.contractId },
                  })
                }
                size="small"
                sx={{ ml: 2 }}
              >
                Create NFT
              </Button>
            ) : null}
          </>
        )}
      </Box>
    </Grid>
  )
}

export default CollectionHeader
