import { Grid } from '@mui/material'

interface ButtonContainerProps {
  children: JSX.Element | JSX.Element[]
}
function CTACancelButtonContainer({ children }: ButtonContainerProps) {
  return (
    <Grid
      container
      sx={{
        flexDirection: {
          xs: 'column',
          sm: 'row-reverse',
        },
        justifyContent: {
          xs: 'center',
          sm: 'space-between',
        },
        alignItems: {
          xs: 'center',
          sm: 'start',
        },
      }}
    >
      {children}
    </Grid>
  )
}

export default CTACancelButtonContainer
