import axios from 'axios'
import { Constants } from '../constants'

export const spearmint = axios.create({
  baseURL: Constants.SPEARMINT_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Constants.SPEARMINT_API_KEY}`,
  },
})

// DISTRIBUTION CAMPAIGNs
export const sonar = ({ apiKey, appName, ...rest }: any = {}) => {
  let headers: any = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${apiKey}`,
  }
  if (appName) headers['app-name'] = appName
  if (rest) {
    headers = {
      ...headers,
      ...rest,
    }
  }
  return axios.create({
    baseURL: Constants.SONAR_API_URL,
    headers,
  })
}
