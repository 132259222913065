export function BlockExplorer({ height = 16 }: { height?: number }) {
  const widthFactor = 16 / 16

  return (
    <svg
      width={widthFactor * height + 'px'}
      height={height + 'px'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.59353 0.966441L13.9269 3.63311C14.149 3.74349 14.3359 3.91366 14.4667 4.12447C14.5974 4.33528 14.6667 4.57838 14.6669 4.82644V11.1798C14.6667 11.4278 14.5974 11.6709 14.4667 11.8817C14.3359 12.0926 14.149 12.2627 13.9269 12.3731L8.59353 15.0398C8.40829 15.1325 8.204 15.1807 7.99686 15.1807C7.78972 15.1807 7.58543 15.1325 7.4002 15.0398L2.06686 12.3731C1.84495 12.2613 1.6587 12.0897 1.52912 11.8777C1.39954 11.6656 1.3318 11.4216 1.33353 11.1731V4.82644C1.33366 4.57838 1.40299 4.33528 1.53371 4.12447C1.66444 3.91366 1.85139 3.74349 2.07353 3.63311L7.40686 0.966441C7.59123 0.874825 7.79432 0.827148 8.00019 0.827148C8.20607 0.827148 8.40916 0.874825 8.59353 0.966441V0.966441Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.54688 4.10645L8.00021 7.33311L14.4535 4.10645"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 15.173V7.33301" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
