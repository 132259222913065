import { useEffect, useState } from 'react'
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, MenuItem, Container, Avatar, Tooltip } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { Logomark } from '../icons/CreatorStudio'
import { Link, useNavigate } from 'react-router-dom'
import ChevronIcon from '../icons/chevron-down.svg'
import ProfileIcon from '../icons/user.svg'
import NearSmallIcon from '../icons/near-small.svg'
import SettingsIcon from '../icons/settings.svg'
import LogOutIcon from '../icons/log-out.svg'
import colors from '../theme/colors'
import { logout } from '../services/near'
import { useRecoilState } from 'recoil'
import { errorState, nearState, userState } from '../store'
import { formatNearAmount } from 'near-api-js/lib/utils/format'
import { ERROR_MESSAGES } from '../utils/Error'
import useMediaQuery from '@mui/material/useMediaQuery'

const DASHBOARD_ROUTE = '/dashboard'
const FAQ_ROUTE = '/frequently-asked-questions'

const pages = [
  ['Dashboard', DASHBOARD_ROUTE],
  ['FAQ', FAQ_ROUTE],
]

function Navbar() {
  const xs = useMediaQuery('(max-width:600px)')
  const sm = useMediaQuery('(max-width:900px)')

  const navigate = useNavigate()
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [balance, setBalance] = useState('')

  const [near] = useRecoilState(nearState)
  const [user, setUser] = useRecoilState(userState)
  const [, setError] = useRecoilState(errorState)

  const settings = [
    {
      name: near.wallet?.getAccountId() || 'No wallet connected',
      icon: NearSmallIcon,
    },
    {
      name: 'Account',
      icon: SettingsIcon,
      onClick: () => {
        navigate('/account')
        handleCloseUserMenu()
      },
    },
    {
      name: 'Logout',
      icon: LogOutIcon,
      onClick: () => {
        logout(near, setUser)
        navigate('/')
      },
    },
  ]

  const settingsMobile = [
    {
      name: 'Dashboard',
      icon: null,
      onClick: () => {
        navigate(DASHBOARD_ROUTE)
        handleCloseUserMenu()
      },
    },
    {
      name: 'FAQ',
      icon: null,
      onClick: () => {
        navigate(FAQ_ROUTE)
        handleCloseUserMenu()
      },
    },
  ]

  useEffect(() => {})

  function handleClick(event: React.MouseEvent<HTMLSpanElement, MouseEvent>, route: string) {
    navigate(route)
  }

  //@ts-ignore
  const handleOpenUserMenu = async (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  useEffect(() => {
    ;(async () => {
      if (anchorElUser) {
        if (near.wallet?.isSignedIn()) {
          try {
            let nearBalance = (await near.wallet?.account().getAccountBalance())?.available
            if (nearBalance) nearBalance = formatNearAmount(nearBalance)
            setBalance(nearBalance)
          } catch (e) {
            console.warn('error getting NEAR balance: ', e)
            setError(ERROR_MESSAGES.NEAR_ERROR)
          }
        }
      }
    })()
  }, [anchorElUser])

  const getNavbarTitleColor = (page: string) => {
    const isFaqRoute = window.location.pathname.toLowerCase().includes(FAQ_ROUTE.toLowerCase())
    switch (page.toLowerCase()) {
      case 'faq': {
        if (isFaqRoute) return colors.SECONDARY
        else return colors.SECONDARY_DISABLED_STRONG
      }
      default: {
        if (!isFaqRoute) return colors.SECONDARY
        else return colors.SECONDARY_DISABLED_STRONG
      }
    }
  }

  return (
    <AppBar id="navbar" color="secondary" position="sticky">
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'space-evenly', justifyContent: 'space-between' }}
        >
          {/* LEFT (logo) - show always */}
          <Box sx={{ mt: 2, mb: 2, mr: 4, display: 'flex' }}>
            <Link to={'/'}>
              <Logomark height={xs ? 24 : 32} />
            </Link>
          </Box>

          {/* MIDDLE (links) - show on > tablet */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {pages.map((p, i) => (
              <Typography
                variant="h5"
                key={i}
                onClick={(e) => handleClick(e, p[1])}
                sx={{
                  color: getNavbarTitleColor(p[0]),
                  mr: i < pages.length - 1 ? 3 : 0,
                  cursor: 'pointer',
                }}
              >
                {p[0]}
              </Typography>
            ))}
          </Box>

          {/* RIGHT (user icon) - show always*/}
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 0 }}>
            {xs ? null : (
              <Tooltip title="Open settings">
                <IconButton sx={{ p: 0 }}>
                  <Avatar sx={{ height: '16px', width: '16px' }} alt="Satori User" src={ProfileIcon} />
                </IconButton>
              </Tooltip>
            )}

            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              marginThreshold={0}
              PaperProps={{
                style: xs
                  ? {
                      width: '100%',
                      maxWidth: '100%',
                      left: '0 !important',
                    }
                  : {},
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              sx={{ mt: { xs: '54px', sm: '45px' }, width: { xs: '100%' } }}
            >
              {sm
                ? settingsMobile.map((setting, idx) => {
                    return (
                      <MenuItem
                        sx={{
                          ml: 1,
                          mr: 1,
                          pt: 2,
                          pb: 2,
                          cursor: idx !== 0 ? 'pointer' : 'auto',
                          ':not(:last-child)': {
                            borderBottom: '1px solid rgba(38, 38, 38, 0.08)',
                          },
                        }}
                        key={setting.name}
                        onClick={setting.onClick}
                      >
                        {setting.icon ? (
                          <img style={{ height: '16px', marginRight: '18px' }} src={setting.icon} alt="setting" />
                        ) : null}
                        <Typography
                          sx={{ fontFamily: 'Manrope-Medium', fontSize: '14px', color: colors.SECONDARY_ACTIVE_BG }}
                          textAlign="center"
                        >
                          {setting.name}
                        </Typography>
                      </MenuItem>
                    )
                  })
                : null}
              <Typography
                sx={{ pt: 2, ml: 3, fontSize: '12px', fontFamily: 'Manrope-Medium', color: colors.SECONDARY_ACTIVE_BG }}
              >
                Connected wallet
              </Typography>
              {settings.map((setting, idx) => {
                return (
                  <MenuItem
                    sx={{
                      ml: 1,
                      mr: 1,
                      pt: 2,
                      pb: 2,
                      cursor: idx !== 0 ? 'pointer' : 'auto',
                      ':not(:last-child)': {
                        borderBottom: '1px solid rgba(38, 38, 38, 0.08)',
                      },
                    }}
                    key={setting.name}
                    onClick={setting.onClick}
                  >
                    {setting.icon ? (
                      <img style={{ height: '16px', marginRight: '18px' }} src={setting.icon} alt="setting" />
                    ) : null}
                    <Typography
                      sx={{ fontFamily: 'Manrope-Medium', fontSize: '14px', color: colors.SECONDARY_ACTIVE_BG }}
                      textAlign="center"
                    >
                      {setting.name}
                    </Typography>
                    {idx === (xs ? 2 : 0) && near.wallet?.isSignedIn() && (
                      <Typography sx={{ ml: 2 }}>{balance ? parseInt(balance, 10).toFixed(1) : '-'} N</Typography>
                    )}
                  </MenuItem>
                )
              })}
            </Menu>

            {xs ? (
              <IconButton size="large" edge="start" aria-label="menu" sx={{ ml: 2 }} onClick={handleOpenUserMenu}>
                {Boolean(anchorElUser) ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            ) : (
              <>
                <Typography
                  onClick={handleOpenUserMenu}
                  sx={{
                    fontFamily: 'Manrope-Medium',
                    fontSize: '16px',
                    color: colors.SECONDARY,
                    ml: 2,
                    cursor: 'pointer',
                  }}
                >
                  {user.email}
                </Typography>
                <img
                  onClick={handleOpenUserMenu}
                  style={{ marginLeft: '16px', width: '16px', height: '16px', cursor: 'pointer' }}
                  src={ChevronIcon}
                  alt="chevron icon"
                />
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Navbar
