import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop() {
  const { pathname } = useLocation()
  const el = document.getElementsByClassName('App')[0]

  useEffect(() => {
    if (!el) return
    el.scrollTo(0, 0)
    // TODO: remember scroll position on dashboard if we're navigating via cancel on one of the forms
  }, [pathname, el])

  return null
}
