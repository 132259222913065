import React, { useCallback, useState } from 'react'
import { Grid, FilledInput, InputAdornment, FormControl, FormHelperText } from '@mui/material'
import { toPerc, toAmount } from '../../utils/Royalty'
import { topLevel } from '../../utils/Account'
import colors from '../../theme/colors'
import { getAccount } from '../../services/near'
import { Royalty } from './Royalties'
import TextField from '../ui/TextField'

interface RoyaltyRowProps {
  k: string
  v: number
  isDisabled?: boolean
  listID?: number
  update: (royalty: Royalty, idx: number) => void
  isAccountIDUnique: (id: string) => boolean
  isAmountBelowMax: (amount: number, listID: number) => boolean
  isFull: boolean
}

function RoyaltyRow({
  k,
  v,
  isDisabled = false,
  listID,
  update,
  isAccountIDUnique,
  isAmountBelowMax,
  isFull,
}: RoyaltyRowProps) {
  // AccountID
  const [keyError, setKeyError] = useState<string>('')

  // Royalty (%)
  const [percRaw, setPercRaw] = useState(toPerc(v).toString()) // need to keep the form input as string or else "." gets trimmed off from number conversion
  const [perc, setPerc] = useState(toPerc(v))
  const [percError, setPercError] = useState<string>('')

  async function handleChangeKey(val: string) {
    if (isDisabled) return

    setKeyError('')
    let errorMsg: string = ''

    const topLevelAccountName = val + topLevel

    update([val, toAmount(perc), errorMsg], listID as number)

    if (val !== topLevel) {
      const { exists, valid } = await getAccount(topLevelAccountName)
      if (!valid) errorMsg = 'Invalid account ID'
      else if (!exists) {
        errorMsg = 'Account does not exist.'
      }
    }

    // validate it is unique to this royalty list
    if (isAccountIDUnique(topLevelAccountName) === false && val !== '' && val !== k) {
      errorMsg = 'Account ID is already in list'
    }
    setKeyError(errorMsg)
  }

  const handleChangePerc = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (isDisabled) return

      // Reset validation check
      setPercError('')
      let errorMsg: string = ''

      const val = e.target.value
      const isValidFloat = /^-?\d*(\.\d+)?$/.test(val)
      const periodCount = (val.match(/\./g) || []).length
      if (periodCount > 1) return
      if (!isValidFloat && val[val.length - 1] !== '.') return
      if (val.startsWith('00')) return
      // validate
      if (isNaN(parseFloat(val))) {
        errorMsg = 'Value must be a valid number'
      }

      if (parseFloat(val) <= 0) {
        errorMsg = 'Value must be greater than 0'
      }

      if (isAmountBelowMax(toAmount(parseFloat(val)), listID!) === false) {
        errorMsg = 'Sum of royalties must not exceed 100%'
      }

      if (val === '') {
        errorMsg = ''
      }

      // IT'S VALID - TRIM ANYTHING BEYOND TICK DECIMAL COUNT
      if (val.includes('.')) {
        const decimalPlaces = val.split('.')[1].length
        if (decimalPlaces > 2) {
          return
        }
      }

      const percFloat = parseFloat(parseFloat(val).toFixed(2)) || 0
      setPercError(errorMsg)
      setPercRaw(val)
      setPerc(percFloat)
      update([k, toAmount(percFloat), errorMsg], listID as number)
    },
    [isAmountBelowMax, isDisabled, k, listID, update]
  )

  const getBorderColor = () => {
    let border = '1px solid '
    if (k && !keyError && perc && !percError && listID !== undefined) {
      // valid row
      border += colors.SUCCESS
    } else if (((perc || perc === 0) && percError) || (k && keyError)) {
      // error
      border += colors.ERROR
    } else {
      border += colors.SECONDARY_DISABLED_BG
    }
    return border
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      sx={{ mb: 0 }}
      className="royalty-row"
    >
      <Grid container spacing={2} direction="row" alignItems="center" justifyContent="space-between">
        <Grid item xs={9}>
          <FormControl sx={{ flex: 2, width: '100%' }} variant="filled">
            <TextField
              id="filled-adornment-accound-id"
              value={k}
              disableUnderline
              onChange={handleChangeKey}
              error={keyError.length === 0 ? false : true}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'Account ID',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ paddingRight: '12px' }}>
                    {topLevel}
                  </InputAdornment>
                ),
              }}
              sx={{
                border: `${getBorderColor()}`,
              }}
              disabled={isDisabled}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="filled">
            <FilledInput
              id="filled-adornment-perc"
              value={percRaw}
              disableUnderline
              onChange={handleChangePerc}
              error={percError.length === 0 ? false : true}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'Percentage',
              }}
              sx={{
                border: getBorderColor(),
              }}
              disabled={isDisabled}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={0} direction="column" alignItems="start" justifyContent="flex-start">
        <FormHelperText sx={{ mr: 2, color: colors.ERROR }} id="filled-weight-helper-text">
          {keyError.length === 0 ? null : keyError}
        </FormHelperText>
        <FormHelperText sx={{ color: colors.ERROR }} id="filled-weight-helper-text">
          {percError.length === 0 ? null : percError}
        </FormHelperText>
      </Grid>
    </Grid>
  )
}

export default RoyaltyRow
