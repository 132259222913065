import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

function NullSeries() {
  const navigate = useNavigate()
  return (
    <>
      <Typography sx={{ alignSelf: 'center' }} variant="h2">
        Please go to the dashboard and select a series
      </Typography>
      <Button
        sx={{ mt: 8, alignSelf: 'center' }}
        variant="outlined"
        color="secondary"
        onClick={() => navigate('/dashboard')}
      >
        Back to Dashboard
      </Button>
    </>
  )
}

export default NullSeries
