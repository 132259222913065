import './App.css'
import 'react-placeholder/lib/reactPlaceholder.css'
import styled from '@emotion/styled'
import Dashboard from './pages/Dashboard'
import CreateCollection from './pages/CreateCollection'
import CreateSeries from './pages/CreateSeries'
import CreateCampaign from './pages/CreateCampaign'
import Monitoring from './pages/Monitoring'
import { Routes, Route, Navigate, useSearchParams, useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import {
  loadingState,
  collectionsState,
  seriesState,
  campaignsState,
  nearState,
  userState,
  User,
  errorState,
  allowedState,
} from './store/index'
import { initStore } from './store/dispatchers'
import { useEffect } from 'react'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Auth from './pages/auth/Auth'
import { initNear } from './services/near'
import { AppDetails, getAppDetails, getIsAllowed, updateApp } from './services/sonar-app'
import Verify from './pages/auth/Verify'
import Loading from './components/Loading'
import { SUCCESS_STATUSES } from './store/models'
import Account from './pages/Account'
import ErrorAlert from './components/ErrorAlert'
import { ERROR_MESSAGES } from './utils/Error'
import CheckIn from './pages/CheckIn'
import FAQ from './pages/FAQ'
import { pageView } from './services/googleAnalytics'
// import ResponsiveGuidelines from './components/ResponsiveGuidelines'

export const LS_USER_KEY = 'user_key'
export const LS_EMAIL = 'email'

const StyledApp = styled.div`
  background: url('Top blobs.png') no-repeat top center;
  background-size: cover;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

function App() {
  const [user, setUser] = useRecoilState(userState)
  const [near, setNear] = useRecoilState(nearState)

  const [, setCollections] = useRecoilState(collectionsState)
  const [, setSeries] = useRecoilState(seriesState)
  const [, setCampaigns] = useRecoilState(campaignsState)
  const [loading, setLoading] = useRecoilState(loadingState)
  const [error, setError] = useRecoilState(errorState)

  const [searchParams] = useSearchParams()
  const location = useLocation()
  const account_id = searchParams.get('account_id')

  const key = localStorage.getItem(LS_USER_KEY)
  const email = localStorage.getItem(LS_EMAIL)

  const [allowed, setAllowed] = useRecoilState(allowedState)

  useEffect(() => {
    if (account_id && account_id !== user.nearId) {
      ;(async () => {
        try {
          const updateRes = await updateApp(email as string, key as string, { nearId: account_id })
        } catch (e) {
          console.warn('error updating app with nearId: ', e)
        } finally {
          setUser({ ...user, nearId: account_id })
        }
      })()
    }
  }, [account_id])

  useEffect(() => {
    ;(async () => {
      if (user.apiToken) {
        setLoading(true)
        try {
          await initStore(user.email, user.apiToken, setCollections, setSeries, setCampaigns, setLoading)
        } catch (e) {
          setError(ERROR_MESSAGES.APP_LOAD_ERROR)
        }
      }
      if (key && email) {
        const updatedUser: User = {
          ...user,
          apiToken: key,
          email,
        }
        const detailsRes = await getAppDetails(email, key)
        if (!SUCCESS_STATUSES.includes(detailsRes.status)) {
          console.warn("Couldn't get app details: ", detailsRes)
          setError(ERROR_MESSAGES.APP_LOAD_ERROR)
        } else {
          const { nearId, wyreUser } = detailsRes.data as AppDetails
          updatedUser.nearId = nearId
          updatedUser.wyreUser = wyreUser
          setUser(updatedUser)
        }

        const allowedRes = await getIsAllowed(email, key)
        if (!SUCCESS_STATUSES.includes(allowedRes.status)) {
          console.warn("Couldn't get is app on allowlist: ", allowedRes)
          setError(ERROR_MESSAGES.APP_LOAD_ERROR)
        } else {
          setAllowed(allowedRes.data)
        }
      } else {
        setLoading(false)
      }
    })()
  }, [user.apiToken, email, key])

  useEffect(() => {
    ;(async () => {
      if (!near.wallet) {
        await initNear(setNear)
      }
    })()
  }, [])

  useEffect(() => {
    pageView(location.pathname)
  }, [location])

  if (loading)
    return (
      <StyledApp className="App">
        <Loading />
      </StyledApp>
    )

  if (!loading && !user.apiToken)
    return (
      <StyledApp className="App">
        <Routes>
          <Route path="/auth" element={<Auth />}></Route>
          <Route path="/verify" element={<Verify />}></Route>
          <Route path="/check-in/:campaignId" element={<CheckIn />}></Route>
          <Route path="*" element={<Navigate to="/auth" />}></Route>
        </Routes>
        <ErrorAlert />
      </StyledApp>
    )

  return (
    <StyledApp className="App">
      <Navbar />
      <Routes>
        <Route index element={<Navigate replace to="/dashboard" />}></Route>
        <Route path="/" element={<Navigate replace to="/dashboard" />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/frequently-asked-questions" element={<FAQ />}></Route>
        <Route path="/account" element={<Account />}></Route>
        <Route path="/create-collection" element={<CreateCollection />}></Route>
        <Route path="/create-series" element={<CreateSeries />}></Route>
        <Route path="/create-campaign" element={<CreateCampaign />}></Route>
        <Route path="/view-campaign/:campaignId" element={<Monitoring />}></Route>
        <Route path="/check-in/:campaignId" element={<CheckIn />}></Route>
        <Route path="*" element={<Navigate to="/dashboard" />}></Route>
      </Routes>
      <ErrorAlert />
      <Footer id="root-footer" />
      {/* <ResponsiveGuidelines /> */}
    </StyledApp>
  )
}

export default App
