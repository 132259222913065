import { useRecoilState } from 'recoil'
import { allowedState, errorState, userState, WyrePlaidPaymentMethod } from '../store/index'
import {
  Button,
  Grid,
  Typography,
  Box,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Snackbar,
  Container,
  Paper,
  Divider,
} from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import colors from '../theme/colors'
import { Transaction, TransactionCampaign, TransactionCollection, TransactionSeries } from '../store/models/transaction'
import { getCollections, getTransactions, setNewPassword } from '../services/sonar-app'
import { SUCCESS_STATUSES } from '../store/models'
import { formatNearAmount } from 'near-api-js/lib/utils/format'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import moment from 'moment'
import { hash } from '../utils/Other'
import { ERROR_MESSAGES } from '../utils/Error'
import { DEFAULT_NETWORK_ID } from '../constants'
import Payouts from '../components/Payments/Payouts'
import { responsiveContainer } from '../utils/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { createTransfer, getTransfers, getUserBalances } from '../services/sonar-payments'
import { formatCurrency } from '../utils/Currency'
import WithdrawDialog from '../components/Payments/WithdrawDialog'
import { getSeries, getSeriesById } from '../services/series'
import Loading from '../components/Loading'
import FileViewer from '../components/Series/FileViewer'
import GradientBorder from '../components/GradientBorder'

interface MenuOption {
  title: string
  selected: boolean
}
const options: MenuOption[] = [
  {
    title: 'Email & Password'.toLowerCase(),
    selected: true,
  },
  {
    title: 'Transactions'.toLowerCase(),
    selected: false,
  },
  {
    title: 'Payouts'.toLowerCase(),
    selected: false,
  },
  {
    title: 'Sales & Payouts'.toLowerCase(),
    selected: false,
  },
  // {
  //   title: 'Wallet',
  //   selected: false,
  // },
]

function PayoutsCollectionListItem({
  id,
  title,
  price,
  media,
  show = false,
  isLast = false,
  updateTotals,
}: {
  id: string
  title: string
  price: number | undefined
  media: string
  show?: boolean
  isLast?: boolean
  updateTotals: (totals: { id: string; sold: number; total: number; earned: number }) => void
}) {
  const [sold, setSold] = useState<undefined | number>(undefined)
  const [total, setTotal] = useState<undefined | number>(undefined)
  const [earned, setEarned] = useState<undefined | number>(undefined)
  const hasSoldValue = typeof sold !== 'undefined'
  const hasEarnedValue = typeof earned !== 'undefined'
  const formattedEarned = formatCurrency(earned || 0)

  useEffect(() => {
    ;(async () => {
      const seriesResponse = await getSeriesById({ seriesId: encodeURIComponent(id) })
      if (!SUCCESS_STATUSES.includes(seriesResponse.status)) {
        console.warn('error getting series: ', seriesResponse)
      } else {
        const { copiesMinted = 0, copiesRemaining = 0 } = seriesResponse.data
        const total = copiesMinted + copiesRemaining

        setSold(copiesMinted)
        setTotal(total)
        const earned = total * Number(price || 0)
        setEarned(earned)

        updateTotals({
          id,
          sold: copiesMinted,
          total,
          earned,
        })
      }
    })()
  }, [id])

  if (!show) {
    return null
  }

  return (
    <Grid container item xs={12} py={0.5} mb={isLast ? 1 : 0}>
      <Grid item xs={5} sm={7} sx={{ display: 'flex' }}>
        <Box ml={3} display="flex">
          {media ? (
            <FileViewer fromIPFS mediaID={media} showImageOnly sx={{ width: 40, height: 40 }} />
          ) : (
            <Box display="block" height={40} width={1} />
          )}
          <Box display="flex" alignItems="center">
            <Typography ml={media ? 3 : 0} fontWeight="bold" color="secondary">
              {title}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={3} sm={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {hasSoldValue ? (
          <Typography fontWeight="bold" color="secondary" textAlign="center">
            {sold} / {total}
          </Typography>
        ) : (
          <Loading sx={{ mt: 0 }} CircularProgressProps={{ size: 20 }} />
        )}
      </Grid>
      <Grid item xs={3} sm={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {hasEarnedValue ? (
          <Box display="flex" alignItems="center">
            <Typography fontWeight="bold" color="secondary">
              $&nbsp;{formattedEarned.whole}
            </Typography>
            <Typography fontWeight="bold" color={colors.SECONDARY_DISABLED_STRONG}>
              .{formattedEarned.decimal} USD
            </Typography>
          </Box>
        ) : (
          <Loading sx={{ mt: 0 }} CircularProgressProps={{ size: 20 }} />
        )}
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )
}

function PayoutsCollectionList({
  isFetching,
  collection: { series = [], title } = {},
}: {
  isFetching: boolean
  collection: any
}) {
  const [dataBySeriesId, setDataBySeriesId] = useState<{
    [key: string]: { earned: number; sold: number; total: number }
  }>({})
  const [sold, setSold] = useState<undefined | number>(undefined)
  const [total, setTotal] = useState<undefined | number>(undefined)
  const [earned, setEarned] = useState<undefined | number>(undefined)
  const [showSeries, setShowSeries] = useState(false)
  const hasSoldValue = typeof sold !== 'undefined'
  const hasEarnedValue = typeof earned !== 'undefined'

  const updateTotals = useCallback(
    ({ id, earned, sold, total }: { id: string; earned: number; sold: number; total: number }) => {
      setDataBySeriesId((dataBySeriesId) => ({ ...dataBySeriesId, [id]: { earned, sold, total } }))
    },
    []
  )

  useEffect(() => {
    const didFetchAllSeries = Object.keys(dataBySeriesId).length >= series.length - 1
    if (didFetchAllSeries) {
      const { earned, sold, total } = Object.values(dataBySeriesId).reduce(
        (prev, { earned, sold, total }) => ({
          earned: prev.earned + earned,
          sold: prev.sold + sold,
          total: prev.total + total,
        }),
        { earned: 0, sold: 0, total: 0 }
      )
      setEarned(earned)
      setSold(sold)
      setTotal(total)
    }
  }, [dataBySeriesId, series.length])

  const handleToggleSeries = useCallback(() => {
    setShowSeries(!showSeries)
  }, [showSeries])

  const seriesMemo = useMemo(() => {
    if (series.length === 0 && !isFetching) {
      updateTotals({ id: '', earned: 0, sold: 0, total: 0 })
      return []
    }

    return series.map(({ id, title, campaigns }: any) => {
      const { price, media } = campaigns.reduce(
        (prev: any, { requirements, media: currentMedia }: any) => {
          const shouldCheckPrice = typeof prev.price === 'undefined'
          const shouldCheckMedia = typeof prev.media === 'undefined'
          let price = prev.price
          let media = prev.media

          if (shouldCheckPrice) {
            price = requirements?.fiatPayment?.price
          }
          if (shouldCheckMedia) {
            media = currentMedia
          }
          return { price, media }
        },
        { price: undefined, media: undefined }
      )

      return {
        id,
        title,
        price,
        media,
      }
    })
  }, [series, isFetching, updateTotals])
  const formattedEarned = formatCurrency(earned || 0)

  return (
    <Grid
      container
      sx={{
        borderRadius: 3,
        backgroundColor: (theme) => theme.palette.background.default,
        cursor: seriesMemo.length > 0 ? 'pointer' : 'default',
      }}
      onClick={handleToggleSeries}
      mt={2}
    >
      <Grid container item xs={12} py={3}>
        <Grid item xs={5} sm={7} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography ml={3} fontWeight="bold" color="secondary">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {hasSoldValue ? (
            <Typography textAlign="center" fontWeight="bold" color="secondary">
              {sold} / {total}
            </Typography>
          ) : (
            <Loading sx={{ mt: 0 }} CircularProgressProps={{ size: 20 }} />
          )}
        </Grid>
        <Grid item xs={3} sm={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {hasEarnedValue ? (
            <Box display="flex" alignItems="center" flexDirection="column">
              <Box display="flex" alignItems="center">
                <Typography fontWeight="bold" color="secondary">
                  $&nbsp;{formattedEarned.whole}
                </Typography>
                <Typography fontWeight="bold" color={colors.SECONDARY_DISABLED_STRONG}>
                  .{formattedEarned.decimal} USD
                </Typography>
              </Box>
            </Box>
          ) : (
            <Loading sx={{ mt: 0 }} CircularProgressProps={{ size: 20 }} />
          )}
        </Grid>
        <Grid item xs={1}>
          {seriesMemo.length > 0 && (
            <Box display="flex" alignItems="center" flexDirection="column" mr={{ xs: 2, sm: 0 }}>
              <KeyboardArrowDownOutlinedIcon
                sx={{
                  transform: showSeries ? 'scaleY(-1)' : '',
                  opacity: showSeries ? 1 : 0.7,
                  color: (theme) => (showSeries ? theme.palette.text.primary : theme.palette.text.secondary),
                }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      {isFetching && showSeries && <Loading sx={{ mt: 0 }} CircularProgressProps={{ size: 20 }} />}
      {!isFetching && seriesMemo.length > 0 && showSeries && (
        <Grid xs={12} item mb={1}>
          <Box mx={3}>
            <Divider />
          </Box>
        </Grid>
      )}
      {!isFetching &&
        seriesMemo.map((series: any, index: number) => (
          <PayoutsCollectionListItem
            key={series.id}
            {...series}
            show={showSeries}
            updateTotals={updateTotals}
            isLast={index === seriesMemo.length - 1}
          />
        ))}
    </Grid>
  )
}

function Account() {
  const [user] = useRecoilState(userState)
  const [allowed] = useRecoilState(allowedState)

  const [menuOptions, setMenuOptions] = useState(options)
  const [loading, setLoading] = useState<boolean>(true)
  const [transactions, setTransactions] = useState<Transaction[] | null>(null)
  const [, setError] = useRecoilState(errorState)

  const [oldPassword, setOldPassword] = useState<string>('')
  const [oldPasswordError, setOldPasswordError] = useState<string>('')

  const [updatedPassword, setUpdatedPassword] = useState<string>('')
  const [updatedPasswordError, setUpdatedPasswordError] = useState<string>('')

  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState<string>('')

  const [updating, setUpdating] = useState<boolean>(false)
  const [updated, setUpdated] = useState<boolean>(false)

  const selectedMenuOption = useMemo(() => menuOptions.filter((o) => o.selected)[0], [menuOptions])

  const sm = useMediaQuery('(max-width:900px)')

  const [isTransfersEnabled, setIsTransfersEnabled] = useState<boolean>(false)
  const [isTransfersLoading, setIsTransfersLoading] = useState<boolean>(false)
  const [balanceAvailable, setBalanceAvailable] = useState({ value: '0.00', whole: '0', decimal: '00', numeric: 0 })
  const [transfersCollections, setTransfersCollections] = useState([])
  const [balancePaid, setBalancePaid] = useState({ value: '0.00', whole: '0', decimal: '00', numeric: 0 })
  const [canWithdraw, setCanWithdraw] = useState(false)
  const [showWithdrawDialog, setShowWithdrawDialog] = useState(false)

  useEffect(() => {
    const optionsEnabled = options
      .map((option) => {
        if (option.title === 'payouts') {
          const shouldDisablePayouts = !allowed
          if (shouldDisablePayouts) {
            return undefined
          }
        } else if (option.title === 'sales & payouts') {
          const shouldDisableTransfers = !allowed || !(user.wyreUser?.userStatus === 'APPROVED')
          setIsTransfersEnabled(!shouldDisableTransfers)
          if (shouldDisableTransfers) {
            return undefined
          }
        }
        return option
      })
      .filter((option) => Boolean(option)) as MenuOption[]
    setMenuOptions(optionsEnabled)
  }, [allowed, user.wyreUser?.userStatus])

  useEffect(() => {
    if (isTransfersEnabled) {
      setIsTransfersLoading(true)
      ;(async () => {
        const [collectionsResponse, transfersResponse, balancesResponse] = await Promise.all([
          getCollections(user.email, user.apiToken),
          getTransfers(user.email, user.apiToken),
          getUserBalances(user.email, user.apiToken),
        ])
        if (
          !SUCCESS_STATUSES.includes(transfersResponse.status) ||
          !SUCCESS_STATUSES.includes(balancesResponse.status) ||
          !SUCCESS_STATUSES.includes(collectionsResponse.status)
        ) {
          console.warn('error getting transfers: ', transfersResponse)
          setIsTransfersLoading(false)
          setError(ERROR_MESSAGES.GET_TRANSACTIONS_ERROR)
        } else {
          setTransfersCollections(collectionsResponse.data)
          setBalancePaid(formatCurrency(transfersResponse.data.total))
          setBalanceAvailable(formatCurrency(balancesResponse.data.availableBalances?.USD || 0))
          setIsTransfersLoading(false)

          const hasActivePaymentMethod =
            (user?.wyreUser?.paymentMethods || []).filter(
              ({ wyrePaymentMethodStatus }) => wyrePaymentMethodStatus === 'ACTIVE'
            ).length > 0
          const hasPositiveBalance = balancesResponse.data.availableBalances.USD > 0
          setCanWithdraw(hasActivePaymentMethod && hasPositiveBalance)
        }
      })()
    }
  }, [isTransfersEnabled])

  useEffect(() => {
    ;(async () => {
      const transactionsRes = await getTransactions(user.email, user.apiToken)
      if (!SUCCESS_STATUSES.includes(transactionsRes.status)) {
        console.warn('error getting transactions: ', transactionsRes)
        setLoading(false)
        setError(ERROR_MESSAGES.GET_TRANSACTIONS_ERROR)
      } else {
        setTransactions(transactionsRes.data)
        setLoading(false)
      }
    })()
  }, [])

  const formatType = (t: Transaction) => {
    if (t.category === 'collection') {
      return (
        <Typography
          sx={{
            fontFamily: 'Manrope-Medium',
            fontSize: '14px',
            letterSpacing: '0.01em',
            color: t.categoryItem ? colors.SECONDARY : colors.SECONDARY_DISABLED_STRONG,
          }}
        >
          {!t.categoryItem && !t.isRefund && 'Collection creation attempted'}
          {t.categoryItem && !t.isRefund && `"${(t.categoryItem as TransactionCollection)?.title}" `}
          {t.categoryItem && <span style={{ color: colors.SECONDARY_DISABLED_STRONG }}>collection created</span>}
          {t.isRefund && `Refund to ${t.receiverId}`}
        </Typography>
      )
    } else if (t.category === 'series') {
      const copies = (t.categoryItem as TransactionSeries)?.copies
      return (
        <Typography
          sx={{
            fontFamily: 'Manrope-Medium',
            fontSize: '14px',
            letterSpacing: '0.01em',
            color: t.categoryItem ? colors.SECONDARY : colors.SECONDARY_DISABLED_STRONG,
          }}
        >
          {!t.categoryItem && !t.isRefund && 'Series creation attempted'}
          {t.categoryItem && !t.isRefund && `${copies} ${copies > 1 ? 'copies' : 'copy'} `}
          {t.categoryItem && !t.isRefund && <span style={{ color: colors.SECONDARY_DISABLED_STRONG }}>{` of `}</span>}
          {t.categoryItem && !t.isRefund && `"${(t.categoryItem as TransactionSeries).title}" `}
          {t.categoryItem && !t.isRefund && (
            <span style={{ color: colors.SECONDARY_DISABLED_STRONG }}>{` NFTs created`}</span>
          )}
          {t.isRefund && `Refund to ${t.receiverId}`}
        </Typography>
      )
    } else if (t.category === 'campaign') {
      if (!t.categoryItem)
        t.categoryItem = { id: 'unknown', name: 'unknown', seriesId: 'unknown' } as TransactionCampaign // TODO: remove after restarting miniflare
      return (
        <Typography
          sx={{ fontFamily: 'Manrope-Medium', fontSize: '14px', letterSpacing: '0.01em', color: colors.SECONDARY }}
        >
          {`"${(t.categoryItem as TransactionCampaign).name}" `}
          <span style={{ color: colors.SECONDARY_DISABLED_STRONG }}>{` campaign created for `}</span>
          {`"${(t.categoryItem as TransactionCampaign).seriesId.split('/')[1]}" `}
          <span style={{ color: colors.SECONDARY_DISABLED_STRONG }}>NFT</span>
        </Typography>
      )
    }
  }

  const passwordRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$') // Minimum eight characters, at least one letter, one number and one special character
  const passwordValid = passwordRegex.test(updatedPassword) && updatedPassword === confirmNewPassword

  const handleSetOldPassword = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (oldPasswordError) setOldPasswordError('')
    const val = e.target.value
    setOldPassword(val)
    // if (!passwordRegex.test(val)) setPasswordError('Must contain minimum 8 characters, at least one letter, one number and one special character');
  }

  const handleSetUpdatedPassword = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (updatedPasswordError) setUpdatedPasswordError('')
    const val = e.target.value
    setUpdatedPassword(val)
    if (!passwordRegex.test(val))
      setUpdatedPasswordError(
        'Must contain minimum 8 characters, at least one letter, one number and one special character'
      )
  }

  const handleSetConfirmNewPassword = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (confirmNewPasswordError) setConfirmNewPasswordError('')
    const val = e.target.value
    setConfirmNewPassword(val)
    if (val !== updatedPassword) setConfirmNewPasswordError('Passwords must match')
  }

  const handleChangePassword = async () => {
    setUpdating(true)
    const newPasswordRes = await setNewPassword(
      user.email,
      user.apiToken,
      await hash(oldPassword),
      await hash(updatedPassword)
    )
    if (newPasswordRes.status === 403) {
      setUpdating(false)
      setOldPasswordError('Incorrect password')
    } else {
      if (!SUCCESS_STATUSES.includes(newPasswordRes.status)) {
        console.warn('error setting password: ', newPasswordRes)
        setError(ERROR_MESSAGES.SET_PASSWORD_ERROR)
      } else {
        setUpdating(false)
        setUpdated(true)
      }
    }
  }

  const handleCloseSnackbar = () => {
    setUpdated(false)
    setOldPassword('')
    setUpdatedPassword('')
    setConfirmNewPassword('')
  }

  const handleOpenWithdrawDialog = useCallback(() => {
    setShowWithdrawDialog(true)
  }, [])

  const handleWithdraw = useCallback(async ({ wyrePaymentMethodId }: WyrePlaidPaymentMethod) => {
    const createTransferResponse = await createTransfer(user.email, user.apiToken, wyrePaymentMethodId!)
    return createTransferResponse
  }, [])

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="start"
      justifyContent="start"
      sx={{ ...responsiveContainer() }}
    >
      <Container maxWidth="lg">
        <>
          <Typography variant="h1" component="h1">
            Account
          </Typography>

          <Grid
            container
            sx={{ mt: { xs: 4, sm: 6, md: 10 }, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}
          >
            <Grid
              item
              md={3}
              sx={{
                flexDirection: { xs: 'row', sm: 'row', md: 'column' },
                justifyContent: { xs: 'center', sm: 'center', md: 'start' },
                mb: { xs: 4, sm: 4, md: 0 },
              }}
            >
              {menuOptions.map((option, idx) => {
                const { title, selected } = option
                return (
                  <Box
                    key={idx}
                    sx={{
                      h: 5,
                      maxWidth: '163px',
                      mb: { md: 2 },
                      p: '12px 16px',
                      borderRadius: 7,
                      backgroundColor: selected ? colors.SECONDARY_DISABLED_BG : 'transparent',
                      cursor: 'pointer',
                      display: sm ? 'inline-block' : 'block',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '13px',
                        fontFamily: 'Manrope-Bold',
                        letterSpacing: '0.03em',
                        color: selected ? colors.SECONDARY : colors.SECONDARY_DISABLED_STRONG,
                      }}
                      onClick={() => {
                        if (option.selected) return
                        option.selected = !selected
                        setMenuOptions(
                          menuOptions.map((opt, i) => {
                            if (i === idx) {
                              return option
                            } else {
                              opt.selected = !option.selected
                              return opt
                            }
                          })
                        )
                      }}
                    >
                      {title.toUpperCase()}
                    </Typography>
                  </Box>
                )
              })}
            </Grid>

            <Grid item md={9}>
              {loading ? (
                <Box sx={{ w: '100%', display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : selectedMenuOption.title === 'Email & Password'.toLowerCase() ? (
                // EMAIL & PASSWORD PAGE
                <Box
                  sx={{
                    ml: { xs: 0, sm: 0, md: '140px' },
                    maxWidth: '486px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <TextField
                    id="email"
                    label="Email"
                    variant="filled"
                    value={user.email}
                    fullWidth
                    disabled
                    InputProps={{ disableUnderline: true }}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    id="password-old"
                    label="Old Password"
                    type="password"
                    variant="filled"
                    value={oldPassword}
                    disabled={updating || updated}
                    fullWidth
                    onChange={handleSetOldPassword}
                    error={!!oldPasswordError}
                    helperText={oldPasswordError || null}
                    InputProps={{ disableUnderline: true }}
                    sx={{ mt: 2, mb: 2 }}
                  />
                  <TextField
                    id="password-new"
                    label="New Password"
                    type="password"
                    variant="filled"
                    value={updatedPassword}
                    disabled={updating || updated}
                    fullWidth
                    onChange={handleSetUpdatedPassword}
                    error={!!updatedPasswordError}
                    helperText={updatedPasswordError || null}
                    InputProps={{ disableUnderline: true }}
                    sx={{ mt: 2, mb: 2 }}
                  />
                  <TextField
                    id="password-confirm"
                    label="Confirm New Password"
                    type="password"
                    variant="filled"
                    value={confirmNewPassword}
                    disabled={updating || updated}
                    fullWidth
                    onChange={handleSetConfirmNewPassword}
                    error={!!confirmNewPasswordError}
                    helperText={confirmNewPasswordError || null}
                    InputProps={{ disableUnderline: true }}
                    sx={{ mt: 2, mb: 5 }}
                  />
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      width: '122px',
                      alignSelf: 'right',
                    }}
                    disabled={!passwordValid || updating || updated}
                    onClick={handleChangePassword}
                  >
                    {updating ? 'Updating...' : 'Update'}
                  </Button>
                </Box>
              ) : selectedMenuOption.title === 'Transactions'.toLowerCase() ? (
                <TableContainer sx={{ backgroundColor: 'transparent', minWidth: { md: 650 } }}>
                  {transactions && transactions.length > 0 ? (
                    <Table sx={{ minWidth: { md: 650 } }} aria-label="simple table">
                      <TableHead>
                        <TableRow
                          sx={{
                            '&:first-child td, &:first-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell sx={{ fontFamily: 'Manrope-SemiBold', fontSize: '16px' }}>Date</TableCell>
                          <TableCell sx={{ fontFamily: 'Manrope-SemiBold', fontSize: '16px' }} align="left">
                            Amount
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'Manrope-SemiBold', fontSize: '16px' }} align="left">
                            Type
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'Manrope-SemiBold', fontSize: '16px' }} align="left">
                            Tx link
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {transactions.map((t) => (
                          <TableRow
                            key={t.timestamp}
                            sx={{
                              height: '64px',
                              '&:last-child td, &:last-child th': {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              sx={{
                                fontFamily: 'Manrope-Medium',
                                fontSize: '14px',
                                color: colors.SECONDARY_DISABLED_STRONG,
                              }}
                              component="th"
                              scope="row"
                            >
                              {moment(t.timestamp).format('D MMM, YYYY')}
                            </TableCell>
                            <TableCell sx={{ fontFamily: 'Manrope-Medium', fontSize: '14px' }} align="left">
                              {formatNearAmount(t.amount)} N
                            </TableCell>
                            <TableCell sx={{ fontFamily: 'Manrope-Medium', fontSize: '14px' }} align="left">
                              {formatType(t)}
                            </TableCell>
                            <TableCell sx={{ fontFamily: 'Manrope-Medium', fontSize: '14px' }} align="left">
                              {!t.hash ? (
                                <Typography>Unavailable</Typography>
                              ) : (
                                <a
                                  href={`https://explorer${
                                    DEFAULT_NETWORK_ID !== 'mainnet' ? '.testnet' : ''
                                  }.near.org/transactions/${t.hash}`}
                                  target={'_blank'}
                                  style={{ color: colors.BLUE, textDecoration: 'none' }}
                                  rel="noreferrer"
                                >
                                  {t.hash.slice(0, 7)}...
                                </a>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <Typography variant="h2" style={{ alignSelf: 'center' }}>
                      No transactions to display
                    </Typography>
                  )}
                </TableContainer>
              ) : selectedMenuOption.title === 'Sales & Payouts'.toLowerCase() ? (
                <>
                  <Box>
                    <Box
                      mb={5}
                      position="relative"
                      display="flex"
                      flexDirection={{ xs: 'column', sm: 'row' }}
                      justifyContent="space-between"
                      width="100%"
                    >
                      <GradientBorder
                        sx={{
                          padding: 3,
                          mr: { xs: 0, sm: 1.5 },
                          mb: { xs: 1.5, sm: 0 },
                          borderRadius: 3,
                          display: 'flex',
                          flexDirection: 'column',
                          width: { xs: 'initial', sm: '50%' },
                        }}
                      >
                        <Box>
                          <Typography color={colors.SECONDARY} fontWeight="bold">
                            Total amount available
                          </Typography>
                        </Box>
                        <Box
                          my={1}
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          minHeight={40}
                        >
                          <Box minHeight={2}>
                            <Typography variant="h2" component="span">
                              $&nbsp;{balanceAvailable.whole}
                            </Typography>
                            <Typography variant="h2" color={colors.SECONDARY_DISABLED_STRONG} component="span">
                              .{balanceAvailable.decimal} USD
                            </Typography>
                          </Box>
                          <Button
                            variant="contained"
                            sx={{
                              background: colors.SECONDARY,
                              border: `1px solid ${colors.SECONDARY}`,
                              '&:hover': {
                                background: colors.SECONDARY_HOVER_BG,
                                border: `1px solid ${colors.SECONDARY_HOVER_BG}`,
                              },
                            }}
                            size="small"
                            disabled={!canWithdraw}
                            onClick={handleOpenWithdrawDialog}
                          >
                            Withdraw
                          </Button>
                        </Box>
                        <Box>
                          <Typography fontSize="0.8em">Not including transfer fees</Typography>
                        </Box>
                      </GradientBorder>
                      <Paper
                        elevation={0}
                        sx={{
                          padding: 3,
                          ml: { xs: 0, sm: 1.5 },
                          borderRadius: 3,
                          display: 'flex',
                          flexDirection: 'column',
                          width: { xs: 'initial', sm: '50%' },
                        }}
                      >
                        <Box>
                          <Typography color={colors.SECONDARY} fontWeight="bold">
                            Total amount paid out
                          </Typography>
                        </Box>
                        <Box
                          my={1}
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          minHeight={40}
                        >
                          <Box minHeight={2}>
                            <Typography variant="h2" component="span">
                              $&nbsp;{balancePaid.whole}
                            </Typography>
                            <Typography variant="h2" color={colors.SECONDARY_DISABLED_STRONG} component="span">
                              .{balancePaid.decimal} USD
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Typography fontSize="0.8em">To preferred payout method</Typography>
                        </Box>
                      </Paper>
                    </Box>
                    <Box>
                      <Typography variant="h3" mb={2}>
                        Sales tracker
                      </Typography>
                      <Box borderRadius={2} sx={{ backgroundColor: (theme) => theme.palette.info.light }}>
                        <Box display="flex" p={2}>
                          <InfoOutlinedIcon color="info" fontSize="small" />
                          <Typography ml={1}>All earnings below are calculated before fees.</Typography>
                        </Box>
                      </Box>
                      <Grid container my={3}>
                        <Grid container item xs={12} mb={1}>
                          <Grid item xs={5} sm={7}>
                            <Typography ml={3}>Collection</Typography>
                          </Grid>
                          <Grid item xs={3} sm={2}>
                            <Typography textAlign="center">Sold / Total</Typography>
                          </Grid>
                          <Grid item xs={3} sm={2}>
                            <Typography textAlign="center">Total earned</Typography>
                          </Grid>
                          <Grid item xs={1} sm={1} mr={3} />
                        </Grid>
                        {transfersCollections.map((collection: any) => (
                          <PayoutsCollectionList
                            key={collection.title}
                            collection={collection}
                            isFetching={isTransfersLoading}
                          />
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                  {canWithdraw && (
                    <WithdrawDialog
                      openDialog={showWithdrawDialog}
                      setOpenDialog={setShowWithdrawDialog}
                      handleAccept={handleWithdraw}
                      amount={balanceAvailable.numeric}
                    />
                  )}
                </>
              ) : (
                <Payouts />
              )}
            </Grid>
          </Grid>
        </>
        {/* ) : (
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <ConnectNear />
          </Grid>
        )
        } */}
      </Container>
      <Snackbar open={!!updated} autoHideDuration={3000} onClose={handleCloseSnackbar} message={'Password updated!'} />
    </Grid>
  )
}

export default Account
