import { Box, Typography } from '@mui/material'
import { useRecoilState } from 'recoil'
import { userState } from '../../../../store'
import { ONBOARD_ITEM_TITLES } from '../../Payouts'
import { IOnboardItem } from '../../types'
import Icon from './Icon'

function Title({ item }: { item: IOnboardItem }) {
  const [user] = useRecoilState(userState)

  let displayTitle = item.title
  if (item.status === 'COMPLETE' && item.completedTitle) displayTitle = item.completedTitle

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
      <Icon item={item} />
      <Typography variant="h3">{displayTitle}</Typography>
      {item.title === ONBOARD_ITEM_TITLES.BANK_ACCOUNT && item.status === 'COMPLETE' && (
        <Typography variant="body1" sx={{ ml: 2 }}>
          **** {user.wyreUser!.paymentMethods.filter((pm) => !pm.isDeleted)[0]!.plaidAccountMask}
        </Typography>
      )}
    </Box>
  )
}
export default Title
