export type Network = 'dev' | 'testnet' | 'mainnet'

export type SuccessStatus = 200 | 201

export const SUCCESS_STATUSES = [200, 201]

export type ErrorStatus = 400 | 403 | 405 | 500

export interface Success<T> {
  data: T
  status: SuccessStatus
}

export interface Error {
  data: any
  status: ErrorStatus
}
