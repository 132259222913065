import { utils, connect, keyStores, WalletConnection, providers, ConnectConfig } from 'near-api-js'
import { JsonRpcProvider } from 'near-api-js/lib/providers'
import { SetterOrUpdater } from 'recoil'
import { LS_EMAIL, LS_USER_KEY } from '../../App'
import { Constants, DEFAULT_NETWORK_ID } from '../../constants'
import { defaultUser, User } from '../../store'
import { NearState } from '../../store/models/near'
import { TransactionStatus } from '../../store/models/transaction'
import { sleep } from '../../utils/Other'
// Can be an empty object if not signing transactions

const config = {
  networkId: DEFAULT_NETWORK_ID,
  nodeUrl: `https://rpc.${DEFAULT_NETWORK_ID}.near.org`,
  walletUrl: `https://wallet${DEFAULT_NETWORK_ID === 'testnet' ? '.testnet' : ''}.near.org`,
  helperUrl: `https://helper.${DEFAULT_NETWORK_ID}.near.org`,
  keyStore: new keyStores.BrowserLocalStorageKeyStore(),
}

export async function getAccount(accountId: string) {
  accountId = accountId.toLowerCase()
  let valid = /^(([a-z\d]+[\-_])*[a-z\d]+\.)*([a-z\d]+[\-_])*[a-z\d]+$/.test(accountId)
  let exists = false
  if (!valid) return { exists, valid }

  //@ts-ignore
  const near = await connect(config)
  const account = await near.account(accountId)

  try {
    const state = await account.state()
    const hasContract = state.code_hash !== '11111111111111111111111111111111'
    return { valid, exists: true, state, hasContract, account }
  } catch (e) {
    //@ts-ignore
    if (/does not exist/.test(e.toString())) {
      exists = false
      //@ts-ignore
    } else if (/is invalid/.test(e.toString())) {
      valid = false
    } else {
      throw e
    }
  }
  return { exists, valid }
}

export async function signIn(wallet: WalletConnection, redirectPath: string) {
  wallet.requestSignIn(
    // {contractId: 'snft.testnet'}, //contract requesting access
    Constants.SATORI_NEAR_ACCOUNT,
    'Creator Studio', //optional name
    //@ts-ignorebu
    window.location.origin + redirectPath //optional URL to redirect to if the sign in was successful
    // TODO: add optional URL to redirect to if the sign in was NOT successful
  )
}

export async function getSignedInAccountId() {
  //@ts-ignore
  const near = await connect(config)
  //@ts-ignore
  const wallet = new WalletConnection(near)
  const walletAccountId = wallet.getAccountId()
  return walletAccountId
}

export async function sendTokens(userWallet: WalletConnection, nearAmount: string) {
  if (!nearAmount) throw new Error('Near amount cannot be empty')
  //@ts-ignore
  const account = userWallet.account()
  const yoctoAmt = utils.format.parseNearAmount(nearAmount)
  try {
    account.sendMoney(
      Constants.SATORI_NEAR_ACCOUNT, // receiver account
      yoctoAmt // amount in yoctoNEAR
    )
  } catch (e) {
    console.log('error sending NEAR: ', e)
  }
}

export async function initNear(setNear?: SetterOrUpdater<NearState>) {
  //@ts-ignore
  const near = await connect(config)
  //@ts-ignore
  const wallet = new WalletConnection(near)
  if (setNear) setNear({ wallet })
  return wallet
}

export const logout = (near: NearState, setUser: SetterOrUpdater<User>) => {
  near.wallet?.signOut()
  localStorage.removeItem(LS_USER_KEY)
  localStorage.removeItem(LS_EMAIL)
  setUser({ ...defaultUser })
}

async function getState(provider: JsonRpcProvider, txHash: string, accountId: string) {
  const result = await provider.txStatus(txHash, accountId)
  return result
}

export const getTransactionStatus = (txHash: string, senderId: string) => {
  const provider = new providers.JsonRpcProvider(`https://rpc.${DEFAULT_NETWORK_ID}.near.org`)
  return getState(provider, txHash, senderId)
}

export const getTransactionInformation: (transactionHash: string, rpcCallCount?: number) => Promise<any> =
  async function (transactionHash: string, rpcCallCount: number = 0) {
    try {
      const provider = new providers.JsonRpcProvider(`https://rpc.${DEFAULT_NETWORK_ID}.near.org`)
      const txRes = (await provider.sendJsonRpc('EXPERIMENTAL_tx_status', [transactionHash, 'foo'])) as any
      let receiverId, signerId, amount, status: TransactionStatus | undefined
      const receipt = txRes.receipts?.filter((r: any) => {
        return r.receiver_id === Constants.SATORI_NEAR_ACCOUNT
      })[0]
      if (receipt) {
        const transferAction = receipt.receipt?.Action
        const internalAction = transferAction.actions.filter((a: any) => 'Transfer' in a)[0]
        if (transferAction) {
          signerId = transferAction.signer_id
          receiverId = receipt.receiver_id
          amount = internalAction.Transfer?.deposit
          status = txRes.status?.SuccessValue === '' ? 'success' : 'error' // TODO: relook at this
        }
      }
      return { receiverId, signerId, amount, status }
    } catch (e) {
      if (rpcCallCount < 3) {
        rpcCallCount++
        await sleep(1000)
        return await getTransactionInformation(transactionHash, rpcCallCount)
      } else {
        return { error: `getTransactionInformation could not find transaction info for: ${transactionHash}` }
      }
    }
  }
