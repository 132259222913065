export const hash = async (str: string) => {
  const utf8 = new TextEncoder().encode(str)
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('')
  return hashHex
}

export const isASCIIExtended = (str: string) => {
  return /^[\x00-\xFF]*$/.test(str)
}

export const isValidEmail = (str: string) => {
  const emailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
  return emailRegex.test(str)
}

export const getIpfsMediaUrl = (cid: string) => {
  // return `https://cloudflare-ipfs.com/ipfs/${cid}`
  return `https://ipfs.io/ipfs/${cid}`
}

export const handleOpenEmailClient = (email: string) => {
  window.location.href = `mailto:${email}`
}

export function sleep(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}
