import { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import md5 from 'md5'
import Mime from 'mime'
import { Grid, Typography, Box, IconButton, GridProps, useTheme } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import CloseIcon from '@mui/icons-material/Close'
import UploadFilesIcon from '../../icons/upload-files.svg'
import FileViewer from './FileViewer'
import { PreparedFile } from '../../types'

interface FileUploaderProps extends GridProps {
  onMediaChange: (files: PreparedFile[]) => void
  onMediaError: (error: any) => void
  content?: ReactNode
  maxFileCount?: number
}

function FileUploaderContentWrapper({ children, ...props }: { children: ReactElement } & GridProps) {
  return (
    <Grid container {...props}>
      <Box sx={{ width: '100%', height: '100%', boxSizing: 'border-box' }}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            p: 4,
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
        >
          {children}
        </Grid>
      </Box>
    </Grid>
  )
}

function FileUploader({
  onMediaChange,
  onMediaError,
  maxFileCount = 1,
  content = (
    <Box p={3}>
      <Box display="flex" flexDirection="column" alignItems="center" mb={2.5}>
        <img src={UploadFilesIcon} alt="various media icons" />
      </Box>
      <Typography textAlign="center" fontWeight="bold" mb={2.5}>
        Click or drop here
      </Typography>
      <Typography textAlign="center">Video: MP4, WEBM</Typography>
      <Typography textAlign="center">Image: JPG, JPEG, WEBP, GIF, PNG</Typography>
      <Typography textAlign="center">Audio: MP3, WAV, OGG</Typography>
      <Typography textAlign="center">(100 Mb max)</Typography>
    </Box>
  ),
  direction = 'column',
  alignItems = 'center',
  justifyContent = 'center',
  sx,
  ...props
}: FileUploaderProps) {
  const theme = useTheme()
  const [files, setFiles] = useState<PreparedFile[]>([])

  const handleRemoveFile = useCallback(
    (index: number) => {
      const isValidIndex = index >= 0 && index < files.length
      if (isValidIndex) {
        const nextFiles = files.slice()
        nextFiles.splice(index, 1)
        setFiles(nextFiles)
      }
    },
    [files]
  )

  const handleFile = useCallback(
    (file: File) =>
      new Promise<PreparedFile>((resolve, reject) => {
        const doesMeetSizeRequirement = file.size < 100 * 1000000
        if (!doesMeetSizeRequirement) {
          reject(new Error('File must be below 100MB.'))
          return
        }

        const reader = new FileReader()
        reader.onabort = () => reject('An unknown error occurred.')
        reader.onerror = () => reject('An unknown error occurred.')
        reader.onload = () => {
          const data = reader.result
          if (!data) {
            reject(new Error('An unknown error occurred.'))
            return
          }

          const { name, type, ...spread } = file
          const dataUrl = `data:${type};base64,${Buffer.from(data as string).toString('base64')}`

          let extension = Mime.getExtension(file.type)
          if (!extension) {
            ;[, extension] = file.type.split('/')
          }

          resolve({ name, type, ...spread, data, dataUrl, hash: md5(dataUrl), extension })
        }
        reader.readAsArrayBuffer(file)
      }),
    []
  )

  const dedupe = useCallback(<T extends { hash: string }>(source: T[], comparer: T[]): T[] => {
    const _source = source.slice()
    const _comparer = comparer.slice()
    for (let i = 0; i < _source.length; i += 1) {
      const { hash: currentHash } = _source[i]
      for (let j = 0; j < _comparer.length; j += 1) {
        const { hash } = _comparer[j]
        if (currentHash === hash) {
          _comparer.splice(j, 1)
          _source.splice(i, 1)
          i -= 1
          break
        }
      }
    }
    return _source
  }, [])

  const handleFiles = useCallback(
    async (currentFiles: File[]) => {
      try {
        let hasFiles = currentFiles.length > 0
        if (!hasFiles) {
          throw new Error('An unknown error occurred')
        }

        const filesDiff = maxFileCount - files.length
        const result = await Promise.allSettled(currentFiles.reverse().slice(0, filesDiff).map(handleFile))
        let preparedFiles = (
          result.filter((item) => item.status === 'fulfilled') as PromiseFulfilledResult<PreparedFile>[]
        ).map((item) => item.value)
        preparedFiles = dedupe<PreparedFile>(preparedFiles, files)

        hasFiles = preparedFiles.length > 0
        if (hasFiles) {
          const nextFiles = files.concat(preparedFiles)
          setFiles(nextFiles)
        }

        const [error] = (result.filter((item) => item.status === 'rejected') as PromiseRejectedResult[]).map(
          (item) => item.reason
        )
        if (error) {
          onMediaError(error.message)
        }
      } catch (error: any) {
        console.log(error)
        onMediaError(error.message)
      }
    },
    [handleFile, onMediaError, maxFileCount, setFiles, files, dedupe]
  )

  useEffect(() => {
    onMediaChange(files)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFiles,
    // https://raw.githubusercontent.com/broofa/mime/main/types/standard.js
    // https://raw.githubusercontent.com/broofa/mime/main/types/other.js
    accept: {
      'image/jpg': ['.jpeg', '.jpg', '.jpe'],
      'image/jpeg': ['.jpeg', '.jpg', '.jpe'],
      'image/png': ['.png'],
      'image/webp': ['.webp'],
      'image/gif': ['.gif'],
      'video/mp4': ['.mp4', '.mp4v', '.mpg4'],
      'video/webm': ['.webm'],
      'video/vnd.dlna.adts': ['.mp4'],
      'audio/mp3': ['.mp3'],
      'audio/wav': ['.wav'],
      'audio/ogg': ['.oga', '.ogg', '.spx', '.opus'],
    },
  })

  const canUploadFile = files.length < maxFileCount
  const gridProps = {
    direction,
    alignItems,
    justifyContent,
    sx: { background: 'rgba(38, 38, 38, 0.08)', borderRadius: '8px', minHeight: '140px', mb: 1, ...sx },
    ...props,
  }

  return (
    <>
      {files.map(({ dataUrl }, index) => (
        <FileUploaderContentWrapper key={index} {...gridProps}>
          <Grid className="image-item" sx={{ height: '100%' }}>
            <>
              <FileViewer isDragAndDrop mediaID={dataUrl} sx={{ width: '100%', height: '100%' }} />
              <IconButton
                onClick={() => handleRemoveFile(index)}
                aria-label="delete"
                sx={{
                  color: theme.palette.text.primary,
                  position: 'absolute',
                  top: '0',
                  right: '0',
                }}
              >
                <CloseIcon />
              </IconButton>
            </>
          </Grid>
        </FileUploaderContentWrapper>
      ))}
      {canUploadFile && (
        <FileUploaderContentWrapper {...gridProps}>
          <Box
            style={isDragActive ? { color: 'red' } : undefined}
            sx={{ width: '100%', height: '100%', boxSizing: 'border-box' }}
            {...getRootProps()}
          >
            <Grid
              container
              direction="column"
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ p: 2, height: '100%', cursor: 'pointer' }}
            >
              {content}
            </Grid>
            <input {...getInputProps()} />
          </Box>
        </FileUploaderContentWrapper>
      )}
    </>
  )
}

export default FileUploader
