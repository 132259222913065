import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RoyaltyRow from './RoyaltyRow'
import defaultRoyalty from '../../utils/Royalty'
import { topLevel } from '../../utils/Account'
import { RoyaltyRequest } from '../../services/series'
import { DEFAULT_NETWORK_ID } from '../../constants'
import { AccountId } from '../../store/models'

type Error = string
type Percentage = number
export type Royalty = [AccountId, Percentage, Error?]
interface RoyaltiesProps {
  start: RoyaltyRequest
  handleChangeRoyalty: (royalties: RoyaltyRequest) => void
  isRequesting: boolean
  royaltiesError: boolean
  setRoyaltiesError: React.Dispatch<React.SetStateAction<boolean>>
}
function Royalties({ start, handleChangeRoyalty, isRequesting, royaltiesError, setRoyaltiesError }: RoyaltiesProps) {
  /**
   * Rules
   * - up to 10 royalties (including default royalty)
   * - royalty values may not exceed 100%
   * - accountIDs must be valid
   *
   * 0: ['alice'          , 100]
   * 1: ['bob'            , 1000]
   * 2: ['charlie'        , 1000]
   * ...
   * 9: [defaultAccountId , defaultRoyaltyAmount]
   */

  const s = useMemo(() => {
    let startArr = Object.entries(start)
    if (start && startArr.length > 1) {
      // remove snft royalty
      startArr = startArr.filter((r) => {
        if (r[0].includes('.testnet')) r[0] = r[0].split('.testnet')[0]
        else if (r[0].includes('.near')) r[0] = r[0].split('.near')[0]
        return !r[0].includes('snft')
      })
      startArr.unshift(['', 0])
      return startArr
    }
    const testing = false
    return testing && DEFAULT_NETWORK_ID !== 'mainnet'
      ? ([
          ['', 0],
          ['linkdrop-wrapper', 100],
          ['art.artcoin', 200],
          ['amberdata', 300],
          ['signer.goerli', 400],
          ['goldmine', 500],
          ['lachlan-nft-test-6', 600],
          ['vif', 700],
          ['lachlan-nft-test-9', 800],
        ] as Royalty[])
      : ([['', 0]] as Royalty[])
  }, [start])

  const [royalties, setRoyalties] = useState<Royalty[]>(s)
  const maxRoyaltyAmount = 10000
  const defaultRoyaltyAmount = Object.values(defaultRoyalty).reduce((acc, r) => acc + r, 0)
  const isFull = royalties.length < 10 ? false : true // 9 is limit because satori is a default royalty

  const isAccountIDUnique = useCallback(
    (id: string) => {
      return royalties.map((r) => r[0] + topLevel).includes(id) ? false : true
    },
    [royalties]
  )

  const isAmountBelowMax = useCallback(
    (amount: number, listID: number) => {
      const cumulativeRoyaltyAmount = royalties.reduce((acc, r, id) => {
        if (id === listID) {
          return acc
        }
        return acc + r[1]
      }, 0)

      return maxRoyaltyAmount - cumulativeRoyaltyAmount - defaultRoyaltyAmount - amount >= 0
    },
    [maxRoyaltyAmount, royalties, defaultRoyaltyAmount]
  )

  const royaltiesFromList = useCallback((curr: Royalty[]): RoyaltyRequest => {
    let map = curr.filter((r) => r[0] !== '').reduce((acc, r) => ({ ...acc, [`${r[0]}${topLevel}`]: r[1] }), {})
    return { ...map, ...defaultRoyalty }
  }, [])

  const update = useCallback(
    (royalty: Royalty, idx: number) => {
      let newRoyalties = [...royalties]
      newRoyalties.splice(idx, 1, royalty)
      setRoyalties([...newRoyalties])
      handleChangeRoyalty(royaltiesFromList([...newRoyalties]))
    },
    [royalties, handleChangeRoyalty, royaltiesFromList]
  )

  const addRow = useCallback(() => {
    setRoyalties([...royalties, ['', 0]])
  }, [royalties])

  // add
  //   /*
  //    * 0: ['', 0]         ---->   ['alice', 10]   ---->   ['', 0]
  //    * ...                                                ['alice', 10]
  //    * 9: ['snft', 100]                                   ['snft', 100]
  //    */

  // edit
  //   /*
  //    * 0: ['', 0]                                       ['', 0]
  //    * 1: ['alice', 10]  ---->   ['allison', 42] ---->  ['allison', 42]
  //    * ...                                              ...
  //    * 9: ['snft', 100]                                 ['snft', 100]
  //    */

  // remove
  //   /*
  //    * 0: ['', 0]                                       ['', 0]
  //    * 1: ['alice', 10]  ---->   ['', 0]  ---->         ...
  //    * ...                                              ['snft', 100]
  //    * 9: ['snft', 100]
  //    */

  useEffect(() => {
    // console.log('   useEffect on royalties change() ===========')

    for (let i = 0; i < royalties.length; i++) {
      const cur = royalties[i]
      if ((cur[0] && cur[1] === 0) || (!cur[0] && cur[1]) || cur[2]) {
        setRoyaltiesError(true)
        return
      }
    }
    if (royaltiesError) setRoyaltiesError(false)
  }, [royalties, royaltiesError, setRoyaltiesError])

  const canAdd = useMemo(() => {
    const latest = royalties[royalties.length - 1]
    const [acct, perc] = latest
    let valid = true
    if (!acct) valid = false
    else if (!perc || perc === 0) valid = false
    else if (royalties.length > 8) valid = false
    return valid
  }, [royalties])

  function renderRows(royalties: Royalty[]) {
    return royalties.map((r, i) => {
      return (
        <RoyaltyRow
          key={i}
          k={r[0]}
          v={r[1]}
          listID={i}
          update={update}
          isAccountIDUnique={isAccountIDUnique}
          isAmountBelowMax={isAmountBelowMax}
          isFull={isFull}
          isDisabled={isRequesting}
        />
      )
    })
  }

  return (
    <Grid container spacing={0} direction="column" alignItems="flex-start" justifyContent="space-between">
      {/* 0 (default) */}
      <RoyaltyRow
        k={Object.keys(defaultRoyalty)[0].split('.')[0]}
        v={Object.values(defaultRoyalty)[0]}
        isDisabled
        update={update}
        isAccountIDUnique={isAccountIDUnique}
        isAmountBelowMax={isAmountBelowMax}
        isFull={isFull}
      />
      {/* 1-9 (editable) */}
      {renderRows(royalties)}
      <Button
        disabled={!canAdd}
        onClick={addRow}
        fullWidth
        variant="outlined"
        color="secondary"
        sx={{ mt: 1, mb: 2, fontSize: '14px', fontFamily: 'Manrope-SemiBold' }}
      >
        <AddIcon sx={{ height: '16px', width: '16px', mr: 1 }} />
        Add Another Royalty
      </Button>
      <Typography variant="body1" component="p">
        Max cumulative royalty is 100%.
      </Typography>
    </Grid>
  )
}

export default Royalties
