import { Button, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { LS_EMAIL, LS_USER_KEY } from '../../App'
import { isActivated, registerApp, resetPassword, verifyPassword } from '../../services/sonar-app'
import { errorState, userState } from '../../store'
import { SUCCESS_STATUSES } from '../../store/models'
import colors from '../../theme/colors'
import { ERROR_MESSAGES } from '../../utils/Error'
import { hash, isValidEmail } from '../../utils/Other'
import AuthHOC from '../../components/Auth/AuthHOC'

let debounceIntervalId: number | undefined
const DEBOUNCE_DELAY = 700 // ms

const AuthContent = () => {
  // useEffect(() => {
  //   ;(async () => {
  //     const res = await axios.get('https://geolocation-db.com/json/')
  //     console.log(res.data)
  //   })()
  // }, [])

  const navigate = useNavigate()

  const [user, setUser] = useRecoilState(userState)
  const [_, setError] = useRecoilState(errorState)

  const [shouldActivate, setShouldActivate] = useState<boolean>(false)
  const [shouldLogin, setShouldLogin] = useState<boolean>(false)

  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [emailResent, setEmailResent] = useState<boolean>(false)

  const [password, setPassword] = useState<string>('')
  const [passwordError, setPasswordError] = useState<string>('')
  const [passwordResetSelected, setPasswordResetSelected] = useState<boolean>(false)

  const handleCreateAccount = async (isRetry?: boolean) => {
    try {
      const registerRes = await registerApp(email)
      if (!SUCCESS_STATUSES.includes(registerRes.status)) {
        console.warn('Error registering app: ', registerRes)
        if (registerRes.data.includes('not on allow list')) {
          setError(ERROR_MESSAGES.NOT_ON_ALLOW_LIST)
        } else {
          setError(ERROR_MESSAGES.CREATE_ACCOUNT_ERROR)
        }
      } else {
        // email has been sent; give user instructions to activate account
        setShouldActivate(true)
        if (isRetry) {
          setEmailResent(true)
          setTimeout(() => setEmailResent(false), 3000)
        }
      }
    } catch (e) {
      console.warn('error registering app: ', e)
      setError(ERROR_MESSAGES.CREATE_ACCOUNT_ERROR)
    }
  }

  const handleLogin = async () => {
    const loginRes = await verifyPassword(email, await hash(password))
    if (!SUCCESS_STATUSES.includes(loginRes.status)) {
      if (loginRes.status === 403) {
        setPasswordError('Incorrect password')
      } else {
        setError(ERROR_MESSAGES.LOG_IN_ERROR)
      }
    } else {
      localStorage.setItem(LS_USER_KEY, loginRes.data.userKey)
      localStorage.setItem(LS_EMAIL, loginRes.data.email)
      setUser({
        ...user,
        apiToken: loginRes.data.userKey,
        email: loginRes.data.email,
        nearId: loginRes.data.nearId,
        wyreUser: loginRes.data.wyreUser,
      })
      navigate('/dashboard')
    }
  }

  async function checkForActivatedAccount(email: string) {
    try {
      const activatedRes = await isActivated(email)
      if (!SUCCESS_STATUSES.includes(activatedRes.status)) {
        console.warn('error checking for activated app: ', activatedRes)
        setError(ERROR_MESSAGES.GENERIC_SERVER_ERROR)
      } else {
        if (activatedRes.data) {
          setShouldLogin(true)
        } else {
          setShouldLogin(false)
        }
      }
    } catch (e) {
      console.warn('error checking activation status: ', e)
    }
  }

  const handleSetEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (shouldLogin) setShouldLogin(false)
    if (emailError) setEmailError('')
    const val = e.target.value.trim()
    setEmail(val)
    if (debounceIntervalId) window.clearTimeout(debounceIntervalId)
    debounceIntervalId = window.setTimeout(() => {
      const valid = isValidEmail(val)
      if (!valid) {
        setEmailError('Please enter a valid email')
      } else {
        checkForActivatedAccount(val)
      }
      debounceIntervalId = undefined
    }, DEBOUNCE_DELAY)
  }

  const isButtonDisabled = () => {
    if (!shouldLogin) return !email || !!emailError
    else return !password || !!passwordError
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    if (debounceIntervalId) return
    if (shouldLogin && password) handleLogin()
    else handleCreateAccount()
  }

  const handleForgotPassword = async (isRetry?: boolean) => {
    try {
      const resetRes = await resetPassword(email)
      if (!SUCCESS_STATUSES.includes(resetRes.status)) {
        console.warn('Error resetting password: ', resetRes)
        setError(ERROR_MESSAGES.RESET_PASSWORD_ERROR)
      }
    } catch (e) {
      console.warn('Error resetting password: ', e)
      setError(ERROR_MESSAGES.RESET_PASSWORD_ERROR)
    }
    if (!passwordResetSelected) setPasswordResetSelected(true)
    if (isRetry) {
      setEmailResent(true)
      setTimeout(() => setEmailResent(false), 3000)
    }
  }

  const EmailSent = ({ task, retry }: { task: string; retry: () => {} }) => {
    return (
      <>
        <Typography variant="body1" sx={{ mb: 5, textAlign: 'center' }}>
          We sent an email to {email}. <br />
          Please follow the instructions in the email to {task}.
        </Typography>
        {!emailResent ? (
          <Typography sx={{ cursor: 'pointer', color: colors.PRIMARY, textDecoration: 'underline' }} onClick={retry}>
            Didn't get an email?
          </Typography>
        ) : (
          <Typography>Email resent!</Typography>
        )}
      </>
    )
  }

  return shouldActivate ? (
    <EmailSent task="complete verification" retry={() => handleCreateAccount(true)} />
  ) : passwordResetSelected ? (
    <EmailSent task="reset your password" retry={() => handleForgotPassword(true)} />
  ) : (
    <form
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Typography variant="h1" sx={{ mb: 1, textAlign: 'center' }}>
        {!shouldLogin ? 'Enter your email to get started' : 'Welcome 👋'}
      </Typography>
      {shouldLogin && (
        <Typography variant="h1" sx={{ mb: 5, textAlign: 'center', color: colors.PRIMARY }}>
          {email}
        </Typography>
      )}
      <TextField
        autoFocus
        id="email-enter"
        label="Your email"
        variant="filled"
        value={email}
        onChange={handleSetEmail}
        error={!!emailError}
        helperText={emailError || null}
        InputProps={{ disableUnderline: true }}
        sx={{ mt: 2, minWidth: { xs: '100%', sm: '400px' } }}
      />
      {shouldLogin && (
        <TextField
          autoFocus
          type="password"
          id="title"
          label="Your password"
          variant="filled"
          onFocus={() => {
            if (passwordError) setPasswordError('')
          }}
          error={!!passwordError}
          helperText={passwordError || null}
          value={password}
          onChange={(e) => {
            if (passwordError) setPasswordError('')
            setPassword(e.target.value)
          }}
          InputProps={{ disableUnderline: true }}
          sx={{ mt: 3, minWidth: { xs: '100%', sm: '400px' } }}
        />
      )}
      <Button
        variant="contained"
        size="large"
        type="submit"
        sx={{ mt: 5, minWidth: { xs: '100%', sm: '400px' } }}
        onClick={handleSubmit}
        disabled={isButtonDisabled()}
      >
        {shouldLogin ? 'Log In' : 'Continue'}
      </Button>
      {shouldLogin && (
        <Typography
          sx={{ mt: 2, cursor: 'pointer', color: colors.PRIMARY, textDecoration: 'underline' }}
          onClick={() => handleForgotPassword()}
        >
          {passwordResetSelected ? 'Cancel' : 'Forgot your password?'}
        </Typography>
      )}
    </form>
  )
}

const Auth = () => <AuthHOC Content={AuthContent} />

export default Auth
