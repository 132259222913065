import { Grid, Typography } from '@mui/material'
import Toggle from './Toggle'
import moment from 'moment'
import { CampaignData } from '../../store/models/campaign'
import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

interface DatesProp {
  campaign: CampaignData
  setCampaign: React.Dispatch<React.SetStateAction<CampaignData | null>>
  isContainer?: boolean
}

function Dates({ campaign, setCampaign, isContainer = false }: DatesProp) {
  const xs = useMediaQuery('(max-width:600px)')

  return (
    <Grid
      container={isContainer ? true : false}
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: { xs: 'space-between', sm: 'space-between', md: 'end' },
      }}
    >
      <div
        style={{
          display: 'inline-block',
          justifyContent: 'start',
        }}
      >
        <Typography variant="body2" component="span" sx={{ mr: 1, width: { xs: '30px', sm: '35px' } }}>
          From
        </Typography>
        <Typography variant="body1" component="span" sx={{ mr: 2, width: { xs: '60px', sm: '110px' } }}>
          {moment(campaign.startTime).format(xs ? 'MMM Do' : 'MMM Do, YYYY')}
        </Typography>
        <Typography variant="body2" component="span" sx={{ mr: 1, width: { xs: '20px', sm: '17px' } }}>
          To
        </Typography>
        <Typography
          variant="body1"
          component="span"
          sx={{ mr: { xs: 0, sm: 0, md: 1 }, sm: 2, width: { xs: '60px', sm: '110px' } }}
        >
          {campaign.endTime ? moment(campaign.endTime).format(xs ? 'MMM Do' : 'MMM Do, YYYY') : ''}
        </Typography>
      </div>
      <Toggle campaign={campaign} onSuccess={(c: CampaignData) => setCampaign({ ...campaign })} />
    </Grid>
  )
}

export default Dates
