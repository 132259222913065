import { atom, selector } from 'recoil'
import { SonarCollection } from '../services/sonar-app'
import { CampaignBody } from './models/campaign'
import { NearState } from './models/near'
import { Series } from './models/series'

export type RequiredIdentityField = 'name' | 'email' | 'phone' | 'address' | 'dob'

export type WyreUserField = 'dateOfBirth' | 'email' | 'cellphoneNumber' | 'residenceAddress'
export type WyreResidenceAddressField = 'street1' | 'city' | 'state' | 'postalCode' | 'country'

type WyreUserStatus = 'OPEN' | 'PENDING' | 'APPROVED' | 'CLOSED'

type WyrePaymentMethodStatus = 'PENDING' | 'AWAITING_FOLLOWUP' | 'ACTIVE' | 'REJECTED'

type WyrePaymentMethodType = 'plaid' | 'swift'
interface WyrePaymentMethod {
  /** Internal reference to the type of Wyre payment method (`plaid`, `swift`, etc) */
  type: WyrePaymentMethodType
  /** Internal; timestamp of the initial creation of this Sonar payment method record */
  createdAt: number
  /**Has Wyre PaymentMethod been created yet? (essentially equivalent to `!!this.wyrePaymentMethodId`) */
  hasWyrePaymentMethodBeenCreated: boolean
  /** Wyre's unique identifier for this PaymentMethod (`null` if Wyre PaymentMethod hasn't been created yet) */
  wyrePaymentMethodId: null | string
  /** Wyre's status for this PaymentMethod */
  wyrePaymentMethodStatus: null | WyrePaymentMethodStatus
  /** Internal; indicates whether this is the default payment method for this Sonar app/user */
  isDefault: boolean
  /** Internal; indicates whether user has requested to remove this payment method (we need to keep it around, e.g. to associate historical transfers with payment method account details) */
  isDeleted: boolean
}

interface PlaidWyreData {
  /** Plaid's `account_id` (unique identifier) for this account. More info: https://plaid.com/docs/api/products/identity/#identityget */
  plaidAccountId: string
  /** The name of the account, either assigned by the user or by the financial institution itself. More info: https://plaid.com/docs/api/products/identity/#identityget */
  plaidAccountName: string
  /** The last 2-4 alphanumeric characters of an account's official account number. Note that the mask may be non-unique between an Item's accounts, and it may also not match the mask that the bank displays to the user. More info: https://plaid.com/docs/api/products/identity/#identityget */
  plaidAccountMask: null | string
  /** No expiry, as per Plaid docs: https://plaid.com/docs/quickstart/glossary/#access-token */
  plaidAccessToken: string
  /** No expiry; see https://plaid.com/docs/api/processors/#processortokencreate and https://plaid.com/docs/quickstart/glossary/#processor-token */
  plaidProcessorToken: string
}

export interface WyrePlaidPaymentMethod extends WyrePaymentMethod, PlaidWyreData {}

interface WyreResidenceAddress {
  street1: string
  city: string
  state: string
  postalCode: string
  country: string
}

/* Wyre `fields` (KYC) */
interface WyreUserFields {
  firstName: string
  lastName: string
  legalName: string
  dateOfBirth: null | string // e.g. "1975-03-06"
  cellphoneNumber: null | string // e.g. "+1112224444"
  email: null | string // e.g. "extraordinarily.long.email.username.123456@reallylonghostname.com"
  residenceAddress: null | WyreResidenceAddress
}

interface WyreUserKyc {
  wyreFields: WyreUserFields
  wyreMissingFields: string[]
}
export interface WyreUser {
  userId: null | string
  userStatus: null | WyreUserStatus
  /**
   * A place to temporarily store KYC data for a Wyre PaymentMethod.
   *
   * A non-`null` `kyc` value indicates that there is a Wyre PaymentMethod for this user that is not approved ('ACTIVE' in Wyre verbiage).
   */
  kyc: null | WyreUserKyc
  paymentMethods: WyrePlaidPaymentMethod[] // could eventually include other payment method types, e.g. Swift
  /** A helper flag to indicate whether or not a user should be allowed to connect a new payment method (not allowed while a payment method is not approved/active, so this is the equivalent of `WyreUser.paymentMethods.some(pm => pm.wyrePaymentMethodStatus !== 'ACTIVE')`) */
  canAttachNewPaymentMethod: boolean
  /** A helper flag to indicate whether or not a user should be prompted for missing KYC data (this is the equivalent of `WyreUser.kyc && WyreUser.kyc.missingFields.length > 0`) */
  isKycRequired: boolean
}

export interface User {
  apiToken: string
  email: string
  nearId: string

  wyreUser: WyreUser | null
}

export const defaultUser: User = {
  apiToken: '',
  email: '',
  nearId: '',
  wyreUser: null,
}

export const nearState = atom({
  key: 'near',
  default: {
    wallet: null,
    // accountId: null,
  } as NearState,
  dangerouslyAllowMutability: true,
})

export const loadingState = atom({
  key: 'loading',
  default: true,
})

export const errorState = atom({
  key: 'error',
  default: '',
})

export const userState = atom({
  key: 'user',
  default: defaultUser,
})

export const allowedState = atom({
  key: 'allowed',
  default: false,
})

export const collectionsState = atom({
  key: 'collections',
  default: [] as SonarCollection[],
})

export const collectionsValue = selector({
  key: 'collectionsValue',
  get: ({ get }) => ({
    total: get(collectionsState).length,
  }),
})

export const seriesState = atom({
  key: 'series',
  default: [] as Series[],
})

export const campaignsState = atom({
  key: 'campaigns',
  default: [] as CampaignBody[],
})
