import { Box, Button, CircularProgress, Typography } from '@mui/material'
import colors from '../../../../theme/colors'
import { ONBOARD_ITEM_TITLES } from '../../Payouts'
import GreenCheck from '../../../../icons/check-green.svg'
import { IOnboardItem } from '../../types'

function Status({ item }: { item: IOnboardItem }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
      {item.status === 'COMPLETE' ? (
        <>
          <img style={{ width: '24px', height: '24px' }} src={GreenCheck} alt="Success Icon" />
          <Typography sx={{ ml: 1, color: colors.SUCCESS }} variant="body1">
            {item.completedSubtext}
          </Typography>
        </>
      ) : item.status === 'PENDING' ? (
        <>
          <CircularProgress size={20} sx={{ color: colors.SECONDARY_DISABLED_STRONG }} />
          <Typography sx={{ ml: 1, color: colors.SECONDARY_DISABLED_STRONG }} variant="body1">
            {item.pendingSubtext}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body1">{item.helperSubtext}</Typography>
          {item.title === ONBOARD_ITEM_TITLES.BANK_ACCOUNT && (
            <Button variant="contained" sx={{ ml: 5 }} onClick={item.onClick}>
              Connect
            </Button>
          )}
        </>
      )}
    </Box>
  )
}
export default Status
