import { Button, Grid, Typography, Paper, Box, Container, Snackbar } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import QRCode from 'qrcode.react'
import EventIcon from '../icons/map-pin.svg'
import EmailIcon from '../icons/mail.svg'
import TwitterIconColor from '../icons/twitter-color.svg'
import TikTokIcon from '../icons/tiktok-alt.svg'
import CopyIcon from '../icons/copy.svg'
import LinkIcon from '../icons/link.svg'
import EyeIcon from '../icons/eye.svg'
import ExternalLinkIcon from '../icons/external-link.svg'
import DownloadIcon from '../icons/download.svg'
import { CampaignData, CampaignStats } from '../store/models/campaign'
import { getCampaign, getCampaignStats } from '../services/campaigns/campaigns.service'
import colors from '../theme/colors'
import { SUCCESS_STATUSES } from '../store/models/common'
import { errorState, loadingState, userState } from '../store'
import { useRecoilState } from 'recoil'
import Loading from '../components/Loading'
import { ERROR_MESSAGES } from '../utils/Error'
import CheckInCode from '../components/Event/CheckInCode'
import { getCheckInToken } from '../services/sonar-app'
import { getCampaignLink } from '../utils/Campaign'
import { responsiveContainer } from '../utils/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Dates from '../components/Campaign/Dates'
import { getContractIdAndSeriesTitleFromSeriesId } from '../utils/Series'

const CANVAS_ID = 'qr-canvas'
let intervalId: number | undefined
const POLLING_INTERVAL = 10000 // 10 seconds

type CampaignType = 'direct-link' | 'event' | 'twitter' | 'tiktok' | 'email'

function Monitoring() {
  const { campaignId } = useParams()
  const [campaign, setCampaign] = useState<CampaignData | null>(null)
  const [campaignStats, setCampaignStats] = useState<CampaignStats | null>(null)
  const [openSnack, setOpenSnack] = useState<boolean>(false)
  const [token, setToken] = useState<string>('')
  const [loading, setLoading] = useRecoilState(loadingState)
  const [user] = useRecoilState(userState)
  const [_, setError] = useRecoilState(errorState)

  const navigate = useNavigate()
  const sm = useMediaQuery('(max-width:900px)')

  useEffect(() => {
    if (campaignId && user.apiToken) {
      setLoading(true)
      ;(async () => {
        try {
          const campaignRes = await getCampaign(user.email, user.apiToken, campaignId)
          const campaignStatsRes = await getCampaignStats(user.email, user.apiToken, campaignId)
          if (campaignRes.status !== 200 || campaignStatsRes.status !== 200) {
            console.warn('error getting campaign: ', campaignRes.data, campaignStatsRes.data)
            setError(ERROR_MESSAGES.GET_CAMPAIGN_ERROR)
          } else {
            setCampaign(campaignRes.data)
            setCampaignStats(campaignStatsRes.data)
            startPoll()
            if (campaignRes.data.requirements?.qr) {
              // EVENT CAMPAIGN; GET TOKEN & CODE
              const tokenRes = await getCheckInToken(user.email, user.apiToken, campaignId)
              if (!SUCCESS_STATUSES.includes(tokenRes.status)) {
                console.warn('error getting token and code: ', tokenRes)
                setError(ERROR_MESSAGES.CHECK_IN_CODE_ERROR)
              } else {
                setToken(tokenRes.data.token)
              }
            }
          }
        } catch (e) {
          console.warn(e)
          setError(ERROR_MESSAGES.GENERIC_SERVER_ERROR)
        } finally {
          setLoading(false)
        }
      })()
    }
  }, [user])

  const startPoll = () => {
    if (!intervalId && campaignId) {
      console.log('starting poll...')
      intervalId = window.setInterval(async () => {
        try {
          const campaignStatsRes = await getCampaignStats(user.email, user.apiToken, campaignId)
          if (campaignStatsRes.status !== 200) {
            console.warn('error getting campaign stats: ', campaignStatsRes.data)
            setError(ERROR_MESSAGES.CAMPAIGN_STATS_ERROR)
            stopPoll()
          } else {
            setCampaignStats(campaignStatsRes.data)
          }
        } catch (e) {
          console.warn(e)
          setError(ERROR_MESSAGES.GENERIC_SERVER_ERROR)
          stopPoll()
        }
      }, POLLING_INTERVAL)
    }
  }

  const stopPoll = () => {
    if (intervalId) {
      console.log('stopping poll...')
      window.clearInterval(intervalId)
      intervalId = undefined
    }
  }

  const onFocus = startPoll
  const onBlur = stopPoll

  useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    return stopPoll
  }, [])

  const csvData = useMemo(() => {
    const rows: any[] = [['Timestamp', 'Account ID']]

    campaignStats?.claimed.forEach((c) => {
      const row = [moment(c.timestamp).format('MM/DD/YYYY HH:mm:ss'), c.accountId]
      if (c.data) {
        for (let key in c.data) {
          if (!rows[0].includes(key)) {
            rows[0].push(key)
          }
          row.push(c.data[key])
          // TODO: this could have edge cases if 'data' objects have multiple entries, may need to sort first. review in more detail
        }
      }
      rows.push(row)
    })

    rows.push(
      Array(rows[0].length).fill('---'), // empty row
      ['Campaign views:', campaignStats?.campaignViews || 0],
      ['NFTs claimed:', campaignStats?.claimed.length || 0],
      ['Wallets created:', campaignStats?.linkdrops || 0]
    )

    let csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n')
    return csvContent
  }, [campaignStats])

  const handleDownloadCsv = () => {
    const encodedUri = encodeURI(csvData)
    const win = window.open(encodedUri)
    if (!win || win?.closed || typeof win?.closed == 'undefined') {
      setError('You must enable popups for this site in order to download CSV.')
    }
  }

  if (!campaign || !campaignStats) return null

  const StatItem = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '195px',
    '@media (max-width: 600px)': {
      minHeight: 'unset',
      paddingTop: '24px',
      paddingBottom: '24px',
    },
    borderRadius: '16px',
  }))

  const Number = styled(Typography)(({ theme }) => ({
    color: colors.PRIMARY,
    fontSize: '56px',
    marginBottom: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%',
    '@media (max-width: 600px)': {
      fontSize: '32px',
    },
  }))

  const getCampaignType = (): CampaignType => {
    if (campaign.requirements?.qr) return 'event'
    else if (campaign.requirements?.email) return 'email'
    else if (campaign.requirements?.socials?.tiktok) return 'tiktok'
    else if (campaign.requirements?.socials?.twitter) return 'twitter'
    else return 'direct-link'
  }

  const getCampaignTypeIcon = () => {
    const campaignType = getCampaignType()
    if (campaignType === 'direct-link') return LinkIcon
    else if (campaignType === 'event') return EventIcon
    else if (campaignType === 'email') return EmailIcon
    else if (campaignType === 'tiktok') return TikTokIcon
    else if (campaignType === 'twitter') return TwitterIconColor
  }

  const campaignLink = getCampaignLink(campaign.id)

  const handleDownloadQr = () => {
    const canvas = document.getElementById(CANVAS_ID) as any
    const img = canvas.toDataURL('image/png')
    const downloadLink = document.createElement('a')
    downloadLink.href = img
    downloadLink.download = `QR-${campaign.name}.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  //@ts-ignore
  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnack(false)
  }

  const handleCopyCampaignLink = () => {
    navigator.clipboard.writeText(campaignLink)
    setOpenSnack(true)
  }

  const handleVisitCampaignPage = () => openNewTab(campaignLink)

  const openNewTab = (url: string) => {
    const win = window.open(url, '_blank')
    if (win != null) {
      win.focus()
    } else {
      window.location.assign(url) // handles iPad/safari situation when win == null on the call from handleLaunchCheckIn (why is win == null? not sure...)
    }
  }

  const getDataType = () => {
    const campaignType = getCampaignType()
    if (campaignType === 'direct-link') return ''
    else if (campaignType === 'event') return ''
    else if (campaignType === 'email') return 'email addresses and'
    else if (campaignType === 'tiktok') return 'TikTok handlees and'
    else if (campaignType === 'twitter') return 'Twitter handlees and'
  }

  const handleLaunchCheckIn = async () => {
    const res = await getCheckInToken(user.email, user.apiToken, campaign.id)
    if (!SUCCESS_STATUSES.includes(res.status)) {
      console.warn('error getting check-in token and code: ', res)
      setError(ERROR_MESSAGES.CHECK_IN_LAUNCH_ERROR)
    }
    const { token } = res.data as { token: string }
    const url = window.location.origin + `/check-in/${campaignId}?token=${token}`
    openNewTab(url)
  }

  if (loading) return <Loading />

  return (
    <Grid
      container
      spacing={0}
      alignItems="center"
      justifyContent="center"
      sx={{
        ...responsiveContainer(),
      }}
    >
      <Container>
        {/* HEADER */}
        <Grid container>
          <Grid item xs={sm ? 12 : 6}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: { xs: 2, sm: 3 } }}>
              <img src={getCampaignTypeIcon() as string} alt="campaign type logo" />
              <Typography variant="h2" component="h2" sx={{ ml: '16px' }}>
                {`${campaign.name} for`}
              </Typography>
            </Box>
            <Typography variant="h1" component="h1" sx={{ mb: { xs: 4, sm: 4, md: 10 } }}>
              {getContractIdAndSeriesTitleFromSeriesId(campaign.seriesId)[1]}
            </Typography>
          </Grid>

          <Grid item xs={sm ? 12 : 6} sx={{ mb: { xs: 4, sm: 4, md: 0 } }}>
            <Dates campaign={campaign} setCampaign={setCampaign} isContainer={true}></Dates>
          </Grid>
        </Grid>

        {/* STATS */}
        <Grid container spacing={0} sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderRadius: '16px',
              flex: 1,
              p: {
                xs: 4,
                sm: 4,
                md: 4,
              },
              mr: {
                xs: 0,
                sm: 0,
                md: 3,
              },
              mb: {
                xs: 0,
                sm: 3,
                md: 0,
              },
            }}
          >
            <Typography sx={{ alignSelf: 'center', mb: 3 }} variant="h2">
              Campaign link
            </Typography>
            <QRCode
              id={CANVAS_ID}
              style={{
                cursor: 'pointer',
                height: 'auto',
                marginBottom: getCampaignType() === 'event' ? '16px' : 0,
              }}
              renderAs="canvas"
              onClick={handleDownloadQr}
              level="H"
              size={256}
              value={campaignLink}
            />

            {getCampaignType() === 'event' ? (
              <>
                {<CheckInCode campaignId={campaign.id} token={token} fontSizePx={32} />}
                <Button
                  fullWidth
                  sx={{ alignSelf: 'center', mt: 2 }}
                  variant="contained"
                  size="medium"
                  onClick={handleLaunchCheckIn}
                >
                  <img src={ExternalLinkIcon} style={{ marginRight: '8px' }} alt="Visit Campaign Page" />
                  Launch Check-in
                </Button>
              </>
            ) : (
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: 3,
                }}
              >
                <Button
                  sx={{ mr: 2 }}
                  variant="outlined"
                  color="secondary"
                  size="medium"
                  onClick={handleVisitCampaignPage}
                >
                  <img src={EyeIcon} style={{ marginRight: '8px' }} alt="Visit Campaign Page" />
                  Visit
                </Button>
                <Button variant="contained" size="medium" onClick={handleCopyCampaignLink}>
                  <img src={CopyIcon} style={{ marginRight: '8px' }} alt="Copy Campaign Link" />
                  Copy link
                </Button>
              </Grid>
            )}
          </Paper>

          <Grid
            container
            spacing={3}
            sx={{
              flex: 2,
              height: '100%',
              mt: {
                xs: 0,
                sm: 0,
                md: -3,
              },
            }}
          >
            <Grid item xs={sm ? 12 : 4}>
              <StatItem>
                <Number>{campaignStats.campaignViews}</Number>
                <Typography variant="body1" sx={{ textAlign: 'center' }}>
                  Campaign visits
                </Typography>
              </StatItem>
            </Grid>

            <Grid item xs={sm ? 12 : 4}>
              <StatItem>
                <Number>{campaignStats.claimed.length}</Number>
                <Typography variant="body1" sx={{ textAlign: 'center' }}>
                  NFTs claimed
                </Typography>
              </StatItem>
            </Grid>

            <Grid item xs={sm ? 12 : 4}>
              <StatItem>
                <Number>{campaignStats.linkdrops}</Number>
                <Typography variant="body1" sx={{ textAlign: 'center' }}>
                  New wallets created
                </Typography>
              </StatItem>
            </Grid>

            <Grid item xs={12}>
              <Paper
                sx={{
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: '16px',
                }}
              >
                <Typography variant="h2" sx={{ mb: 4 }}>
                  Track your Success
                </Typography>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    flexDirection: {
                      xs: 'column',
                      sm: 'column',
                      md: 'row',
                    },
                  }}
                >
                  <Grid
                    item
                    sx={{
                      maxWidth: {
                        xs: 'none',
                        sm: 'none',
                        md: '300px',
                      },
                    }}
                  >
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      Your data is ready for download.
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 4 }}>
                      Includes {getDataType()} NEAR wallets that have interacted with your NFT distribution campaign.
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      flexDirection: 'column',
                      minHeight: '157px',
                      maxWidth: {
                        xs: 'none',
                        sm: 'none',
                        md: '300px',
                      },
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="medium"
                      onClick={handleDownloadCsv}
                      sx={{ mt: { xs: 3, sm: 3, md: 0 } }}
                    >
                      <img src={DownloadIcon} style={{ marginRight: '8px' }} alt="Download CSV" />
                      Download CSV
                    </Button>
                    <Typography variant="body1" sx={{ fontSize: '12px', textAlign: 'center', mt: 1 }}>
                      *Must enable popups
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        {/* BUTTON */}
        <Grid container direction="row" alignItems="start" justifyContent="center">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate('/dashboard')}
            sx={{ mt: 8, alignSelf: 'center' }}
          >
            Back to Dashboard
          </Button>
        </Grid>
      </Container>
      <Snackbar open={openSnack} autoHideDuration={2000} onClose={handleSnackClose} message="Copied Link!" />
    </Grid>
  )
}

export default Monitoring
