import { Container, Grid, Typography, Box } from '@mui/material'
import { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import QRCode from 'qrcode.react'
import { useParams, useSearchParams } from 'react-router-dom'
import CheckInCode from '../components/Event/CheckInCode'
import { getCampaignLink } from '../utils/Campaign'

const CANVAS_ID = 'qr-canvas'
const SS_CHECK_IN_TOKEN = 'ci-t' // session storage check-in token key

function CheckIn() {
  const [token, setToken] = useState<string | null>(null)
  const [{ width, height }, setWindowDimensions] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  const [searchParams, setSearchParams] = useSearchParams()
  const { campaignId } = useParams()

  const handleWindowResize = (e: any) => {
    setWindowDimensions({ width: e.target.innerWidth as number, height: e.target.innerHeight as number })
  }

  const removeNavbarAndFooter = () => {
    const navbar = document.getElementById('navbar')
    navbar?.parentElement?.removeChild(navbar)
    const rootFooter = document.getElementById('root-footer')
    if (rootFooter) {
      rootFooter.parentElement?.removeChild(rootFooter)
    }
  }

  useEffect(() => {
    ;(async () => {
      /// LISTEN FOR WINDOW SIZE CHANGES
      window.addEventListener('resize', handleWindowResize)
      /// REMOVE NAVBAR & ROOT FOOTER
      removeNavbarAndFooter()
      /// GET TOKEN FROM URL PARAMS / LOCAL STORAGE, SET STATE
      let t = searchParams.get('token')
      if (!t) {
        // token isn't present in params; look for it in local storage
        t = sessionStorage.getItem(SS_CHECK_IN_TOKEN)
      } else {
        // token exists in params; save to local storage
        sessionStorage.setItem(SS_CHECK_IN_TOKEN, t)
      }
      if (!t) return // get outta here if there's still no token. something's wrong.
      setSearchParams({}) // remove token etc from params for security purposes
      setToken(t)
    })()
  }, [])

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ height: '100%', background: `url('Top blobs.png') no-repeat top center` }}
      >
        {width < height ? (
          <Typography>Please rotate device to landscape or expand window width to display check-in page.</Typography>
        ) : !token ? (
          <Typography>
            Could not load check-in page. Try returning to your Campaign dashboard and selecting "Launch Check-in"
            again.
          </Typography>
        ) : (
          <>
            <Grid
              item
              xs={6}
              sx={{
                height: '100%',
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Container
                sx={{
                  alignSelf: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography sx={{ textAlign: 'center', mb: 8 }} variant="h1">
                  1. Scan the QR code <br />
                  to get an NFT
                </Typography>
                <QRCode
                  id={CANVAS_ID}
                  style={{ cursor: 'pointer', minHeight: '400px', minWidth: '400px', height: 'auto' }}
                  renderAs="canvas"
                  // renderAs="svg"
                  level="H"
                  size={256}
                  value={getCampaignLink(campaignId as string)}
                />
              </Container>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                height: '100%',
                background: `url('Top blobs.png') no-repeat top center`,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Container
                sx={{
                  alignSelf: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h1" sx={{ mb: 8, textAlign: 'center' }}>
                  2. Confirm your presence <br />
                  with the check-in code
                </Typography>
                <Box
                  sx={{
                    minWidth: '400px',
                    minHeight: '400px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <CheckInCode campaignId={campaignId as string} token={token} fontSizePx={80} />
                </Box>
              </Container>
            </Grid>
          </>
        )}
      </Grid>
      <Footer absolute />
    </>
  )
}

export default CheckIn
