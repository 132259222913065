import { ClaimLinkResponse } from '.'
import { Constants } from '../../constants'
import { Success, Error } from '../../store/models/common'
import { spearmint } from '../axios'
import { resolveResponseError, resolveResponseSuccess } from '../helpers'

export function getClaimLinks(seriesId: string, amount: number = 1): Promise<Success<ClaimLinkResponse> | Error> {
  return spearmint
    .post(`/v1/api/${Constants.SPEARMINT_APP_NAME}/claim`, { seriesId, amount })
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}
