import { Container, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'
import PlaidLink from './PlaidLink'
import { deletePaymentMethod, getLinkToken, getUserBalance, UserBalanceResponse } from '../../services/sonar-payments'
import { errorState, userState } from '../../store'
import { SUCCESS_STATUSES } from '../../store/models'
import { ERROR_MESSAGES } from '../../utils/Error'
import BankIncomplete from '../../icons/bank.svg'
import BankComplete from '../../icons/bank-blue.svg'
import SecurityIncomplete from '../../icons/user.svg'
import SecurityComplete from '../../icons/user-blue.svg'
import OnboardItem from './OnboardItem/OnboardItem'
import Loading from '../Loading'
import { IOnboardItem, OnboardItemStatus } from './types'
import WyreBalance from './WyreBalance'

export const ONBOARD_ITEM_TITLES = {
  BANK_ACCOUNT: 'Bank Account',
  KYC: 'Security Verification',
}

const Payouts = () => {
  const [user] = useRecoilState(userState)
  const [, setError] = useRecoilState(errorState)

  const paymentMethod = user.wyreUser!.paymentMethods.filter((pm) => !pm.isDeleted)[0] // only allowing one payment method per user for now, so we can use zeroeth index of this filtered array

  /* Get status of KYC (Security Verification) item */
  const kycItemStatus = useMemo(() => {
    if (user.wyreUser?.isKycRequired || !paymentMethod) return 'INCOMPLETE'
    else if (user.wyreUser?.userStatus === 'PENDING' || paymentMethod.wyrePaymentMethodStatus === 'PENDING')
      return 'PENDING'
    else if (paymentMethod.wyrePaymentMethodStatus === 'REJECTED') return 'ERROR'
    else return 'COMPLETE'
  }, [user, paymentMethod])

  const [onboardItems, setOnboardItems] = useState<IOnboardItem[]>([
    {
      active: !paymentMethod,
      title: ONBOARD_ITEM_TITLES.BANK_ACCOUNT,
      status: paymentMethod ? 'COMPLETE' : 'INCOMPLETE',
      iconIncomplete: BankIncomplete,
      iconComplete: BankComplete,
      helperSubtext: 'Takes less than a minute',
      completedSubtext: 'Connected',
      completedTitle: paymentMethod?.plaidAccountName,
      onClick: () => setInitiatePlaid(true),
      onClickSecondary: async () => {
        await deletePaymentMethod(user.email, user.apiToken, paymentMethod.plaidAccountId)
        await getAndSetLinkToken()
        setInitiatePlaid(true)
      },
    },
    {
      active: user.wyreUser?.isKycRequired!,
      disabled: !paymentMethod,
      title: ONBOARD_ITEM_TITLES.KYC,
      status: kycItemStatus,
      iconIncomplete: SecurityIncomplete,
      iconComplete: SecurityComplete,
      helperSubtext: 'Verification may take up to 24 hours',
      pendingSubtext: 'Verification in progress. Refresh to update.',
      completedSubtext: 'Verified',
    },
  ])

  /// PLAID STATE UTILS
  const [initiatePlaid, setInitiatePlaid] = useState<boolean>(false)
  const [linkToken, setLinkToken] = useState<string>('')

  /** If there's no payment method, it means that user needs to connect a bank account with Plaid. Get link token to pass to PlaidLink */
  useEffect(() => {
    ;(async () => {
      // only get link token if user hasn't set up Plaid yet
      if (!paymentMethod) await getAndSetLinkToken()
    })()
  }, [])

  async function getAndSetLinkToken() {
    const linkTokenRes = await getLinkToken(user.email, user.apiToken)
    // console.log('linkTokenRes: ', linkTokenRes)
    if (!SUCCESS_STATUSES.includes(linkTokenRes.status)) {
      console.warn('Error getting link token: ', linkTokenRes)
      setError(ERROR_MESSAGES.PLAID_LINK_TOKEN_ERROR)
    } else {
      setLinkToken(linkTokenRes.data.link_token)
    }
  }

  /** Check for updates to payment method, and update Plaid onboard item accordingly */
  useEffect(() => {
    if (paymentMethod) {
      // A payment method exists, which means that the Plaid step has been completed. Update OnboardItems accordingly.
      const updatedOnboardItems = onboardItems.map((item) => {
        if (item.title === ONBOARD_ITEM_TITLES.BANK_ACCOUNT) {
          // bank account item
          return {
            ...item,
            active: false,
            status: 'COMPLETE',
          }
        }
        // wyre/kyc item
        return {
          ...item,
          active: user.wyreUser?.isKycRequired && paymentMethod.wyrePaymentMethodStatus === null,
          disabled: false,
          status:
            user.wyreUser?.isKycRequired && paymentMethod.wyrePaymentMethodStatus === null ? 'INCOMPLETE' : 'COMPLETE',
        }
      }) as IOnboardItem[]
      console.log('updatedOnboardItems line 152: ', updatedOnboardItems)
      setOnboardItems(updatedOnboardItems)
    }
  }, [paymentMethod])

  /** Check for updates to payment method, and update KYC onboard item accordingly */
  useEffect(() => {
    const kycItem = onboardItems.filter((item) => item.title === ONBOARD_ITEM_TITLES.KYC)[0] as IOnboardItem
    if (
      !user.wyreUser?.isKycRequired &&
      (user.wyreUser?.userStatus === 'PENDING' || paymentMethod?.wyrePaymentMethodStatus === 'PENDING') && // Wyre user OR Wyre payment method is pending
      kycItem.status !== 'PENDING' // this item has not yet been marked as pending
    ) {
      // Nothing more to do until we get an update from Sonar
      // mark this item as PENDING and no longer active
      const updatedOnboardItems = onboardItems.map((item) => {
        if (item.title === ONBOARD_ITEM_TITLES.KYC) {
          // wyre item
          return {
            ...item,
            status: 'PENDING',
            active: false,
          }
        }
        // bank account item
        return item
      }) as IOnboardItem[]
      console.log('updatedOnboardItems line 182: ', updatedOnboardItems)
      setOnboardItems(updatedOnboardItems)
    }
  }, [paymentMethod])

  useEffect(() => {
    ;(async () => {
      if (user.wyreUser?.userStatus === 'APPROVED') {
        const userBalanceRes = await getUserBalance(user.email, user.apiToken)
        if (!SUCCESS_STATUSES.includes(userBalanceRes.status)) {
          console.warn('Error getting link token: ', userBalanceRes)
          setError(ERROR_MESSAGES.PLAID_LINK_TOKEN_ERROR)
        } else {
          const json = userBalanceRes.data as UserBalanceResponse
          setWyreBalance(json)
        }
      }
    })()
  }, [])

  const [wyreBalance, setWyreBalance] = useState<null | UserBalanceResponse>(null)

  if (!paymentMethod && !linkToken) return <Loading />

  return (
    <Container>
      <Typography variant="body1" sx={{ mb: 5 }}>
        To sell your NFTs via the "Credit Card" distribution strategy, you need to connect your bank account for payouts
        and pass a security verification.
      </Typography>
      {initiatePlaid ? (
        <PlaidLink linkToken={linkToken} setInitiatePlaid={setInitiatePlaid} />
      ) : (
        onboardItems.map((item, idx) => <OnboardItem key={idx} item={item} />)
      )}
      {paymentMethod?.wyrePaymentMethodStatus === 'ACTIVE' && wyreBalance !== null ? (
        <WyreBalance availableBalances={wyreBalance.availableBalances} totalBalances={wyreBalance.totalBalances} />
      ) : null}
    </Container>
  )
}

export default Payouts
