import { Box, Grid, Link, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Link as RouterLink } from 'react-router-dom'
import { CampaignData } from '../../store/models'
import EventIcon from '../../icons/map-pin.svg'
import EmailIcon from '../../icons/mail.svg'
import TwitterIcon from '../../icons/twitter.svg'
import TikTokIcon from '../../icons/tiktok-alt.svg'
import LinkIcon from '../../icons/link.svg'
import Dates from '../Campaign/Dates'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useState } from 'react'

interface CampaignProps {
  campaign: CampaignData
  openMenu: boolean
  handleMenuClick: (event: any, campaign: CampaignData) => void
}

function Campaign({ campaign, openMenu, handleMenuClick }: CampaignProps) {
  const [, setCampaign] = useState<CampaignData | null>(null)

  const sm = useMediaQuery('(max-width:900px)')

  function getSocialIcon(campaign: CampaignData) {
    const reqs = campaign.requirements
    if (reqs === null) {
      return LinkIcon
    } else {
      if (reqs.hasOwnProperty('email')) {
        return EmailIcon
      } else if (reqs.hasOwnProperty('socials')) {
        const socials = reqs.socials
        if (socials!.hasOwnProperty('twitter')) {
          return TwitterIcon
        } else if (socials!.hasOwnProperty('tiktok')) {
          return TikTokIcon
        }
      }
    }
    return EventIcon
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        pt: 2,
        pb: 2,
        pl: 3,
        pr: { xs: 1, sm: 1, md: 3 },
        '&:hover': {
          backgroundColor: 'rgba(38, 38, 38, 0.04)',
        },
        borderBottom: sm ? '1px solid rgba(38, 38, 38, 0.08)' : 'none',
      }}
      key={campaign.id}
    >
      <Box sx={{ width: { xs: '100%', sm: '100%', md: 'unset' } }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          sx={{
            width: '100%',
            flex: 1,
            justifyContent: { xs: 'space-between', sm: 'space-between', md: 'start' },
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
            <Box
              sx={{
                mr: 3,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <img src={getSocialIcon(campaign)} style={{ height: '24px' }} alt="distribution strategy" />
            </Box>

            <Link
              variant="body1"
              color="inherit"
              underline="none"
              component={RouterLink}
              to={`/view-campaign/${campaign.id}`}
            >
              <Typography variant="h6" component="span">
                {campaign.name}
              </Typography>
            </Link>
          </Box>

          <Grid>
            <IconButton
              aria-label="More"
              size="large"
              aria-controls={openMenu ? 'campaign-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
              onClick={(e) => handleMenuClick(e, campaign)}
              sx={{ display: sm ? 'flex' : 'none' }}
            >
              <MoreVertIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ width: { xs: '100%', sm: '100%', md: 'unset' } }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          sx={{ justifyContent: { xs: 'space-between', sm: 'space-between', md: 'end' } }}
        >
          <Dates campaign={campaign} setCampaign={setCampaign} isContainer={sm ? true : false}></Dates>
          <IconButton
            aria-label="More"
            size="large"
            aria-controls={openMenu ? 'campaign-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={(e) => handleMenuClick(e, campaign)}
            sx={{ display: sm ? 'none' : 'flex' }}
          >
            <MoreVertIcon />
          </IconButton>
        </Grid>
      </Box>
    </Grid>
  )
}

export default Campaign
