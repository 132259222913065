import EmptyDashboard from '../components/Dashboard/EmptyDashboard'
import PopulatedDashboard from '../components/Dashboard/PopulatedDashboard'
import { useRecoilState, useRecoilValue } from 'recoil'
import { collectionsValue, loadingState, userState } from '../store/index'
import { Button, Grid, Typography, Box, Container, useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom'
import Loading from '../components/Loading'
import ConnectNear from '../components/Dashboard/ConnectNear'
import { responsiveContainer } from '../utils/styles'

function Dashboard() {
  const [loading] = useRecoilState(loadingState)
  const collections = useRecoilValue(collectionsValue)
  const [user] = useRecoilState(userState)

  const xs = useMediaQuery('(max-width: 600px)')

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="start"
      justifyContent="start"
      sx={{ ...responsiveContainer() }}
    >
      <Container maxWidth="lg">
        {user.nearId ? (
          <>
            <Grid
              container
              justifyContent="space-between"
              sx={{
                flexDirection: { xs: 'column', sm: 'row', md: 'row' },
                alignItems: { xs: 'start', sm: 'center', md: 'center' },
              }}
            >
              <Box>
                <Typography variant="h1" component="h1">
                  Dashboard
                </Typography>
              </Box>

              {xs ? null : collections.total > 0 ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  component={Link}
                  to={'/create-collection'}
                  size="medium"
                  sx={{ mt: { xs: 4, sm: 4, md: 0 }, alignSelf: { xs: 'center', sm: 'center', md: 'none' } }}
                >
                  Create Collection
                </Button>
              ) : null}
            </Grid>
            {loading ? <Loading /> : collections.total > 0 ? <PopulatedDashboard /> : <EmptyDashboard />}
          </>
        ) : (
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <ConnectNear />
          </Grid>
        )}
      </Container>
    </Grid>
  )
}

export default Dashboard
