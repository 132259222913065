import { Button, Grid, Typography, TextField } from '@mui/material'
import colors from '../../theme/colors'

interface TwitterFormProps {
  setTweetUrlError: React.Dispatch<React.SetStateAction<string>>
  handleSetTweetUrl: (val: string) => void
  handleSignInWithTwitter: () => Promise<void>
  tweetUrlError: string
  twUsername: string | null
}

function TwitterForm({
  setTweetUrlError,
  handleSetTweetUrl,
  handleSignInWithTwitter,
  tweetUrlError,
  twUsername,
}: TwitterFormProps) {
  return (
    <Grid item sx={{ minHeight: '56px' }}>
      (twUsername ? (
      <>
        <Typography variant="body1">
          Please add the tweet URL below that must be re-tweeted in order to claim your NFT (Signed in as {twUsername}.{' '}
          <span
            onClick={handleSignInWithTwitter}
            style={{ color: colors.BLUE, textDecoration: 'underline', cursor: 'pointer' }}
          >
            Not you?
          </span>
          )
        </Typography>
        <TextField
          id="tweet-url-input"
          label="Tweet URL"
          variant="filled"
          onFocus={() => {
            if (tweetUrlError) setTweetUrlError('')
          }}
          error={!!tweetUrlError}
          helperText={!tweetUrlError ? null : tweetUrlError}
          onChange={(e) => handleSetTweetUrl(e.target.value)}
          InputProps={{ disableUnderline: true }}
          fullWidth
          sx={{ mb: 3, mt: 1 }}
        />
      </>
      ) : (
      <Button
        variant="contained"
        size="small"
        sx={{
          mt: 2,
        }}
        onClick={handleSignInWithTwitter}
      >
        Sign in with Twitter
      </Button>
      ) )
    </Grid>
  )
}

export default TwitterForm
