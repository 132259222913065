import { WyreResidenceAddressField, WyreUserField } from '../../store'
import { Success, Error } from '../../store/models/common'
import { sonar } from '../axios'
import { resolveResponseError, resolveResponseSuccess } from '../helpers'
import { LinkTokenResponse, UserBalanceResponse } from './models'

export function getLinkToken(appName: string, apiKey: string): Promise<Success<LinkTokenResponse> | Error> {
  return sonar({ appName, apiKey })
    .get('payments/plaid/link-token')
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function getUserBalance(appName: string, apiKey: string): Promise<Success<UserBalanceResponse> | Error> {
  return sonar({ appName, apiKey })
    .get('payments/wyre/balance')
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function exchangePlaidPublicToken(
  appName: string,
  apiKey: string,
  public_token: string,
  account_id: string
): Promise<Success<null> | Error> {
  return sonar({ appName, apiKey })
    .post('app/plaid-exchange', { public_token, account_id })
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function addIdentityFields(
  appName: string,
  apiKey: string,
  fields: Record<WyreUserField | 'residenceAddress', string | Record<WyreResidenceAddressField, string>>,
  paymentMethodPlaidAccountId: string
): Promise<Success<null> | Error> {
  // TODO: change null
  return sonar({ appName, apiKey })
    .post('app/wyre-user-fields', { fields, paymentMethodPlaidAccountId })
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function deletePaymentMethod(
  appName: string,
  apiKey: string,
  paymentMethodPlaidAccountId: string
): Promise<Success<null> | Error> {
  // TODO: change null
  return sonar({ appName, apiKey })
    .delete(`app/wyre-plaid-payment-method/${paymentMethodPlaidAccountId}`)
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function getUserBalances(appName: string, apiKey: string) {
  return sonar({ appName, apiKey })
    .get(`payments/wyre/balances`)
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function createTransfer(appName: string, apiKey: string, wyrePaymentMethodId: string) {
  return sonar({ appName, apiKey })
    .post(`payments/wyre/transfers`, { wyrePaymentMethodId })
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function getTransfers(appName: string, apiKey: string) {
  return sonar({ appName, apiKey })
    .get(`payments/wyre/transfers`)
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}
