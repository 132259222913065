import { Grid, Typography, Paper, Tooltip } from '@mui/material'
import { DistributionOption } from '../../utils/Campaign'
import colors from '../../theme/colors'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface OptionProps {
  distributionOptions: DistributionOption[]
  option: any
  idx: number
  setDistributionOptions: React.Dispatch<React.SetStateAction<DistributionOption[]>>
}

function Option({ distributionOptions, option, idx, setDistributionOptions }: OptionProps) {
  let navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const { selected, icon, title, disabled } = option

  function handleSelectOption() {
    if (option.selected) return
    if (option.disabled) {
      navigate('/account')
    }
    option.selected = !selected
    setDistributionOptions(
      distributionOptions.map((opt, i) => {
        if (i === idx) {
          return option
        } else {
          opt.selected = !option.selected
          return opt
        }
      })
    )
  }

  function handleOpen() {
    if (option.disabled) setOpen(true)
  }

  function handleClose() {
    if (option.disabled) setOpen(false)
  }

  const tooltipText = 'You must connect your bank account in Account > Payouts'

  return (
    <Grid xs={Math.floor(6)} item>
      <Tooltip open={open} onClose={handleClose} onOpen={handleOpen} title={tooltipText}>
        <Paper
          key={idx}
          sx={{
            backgroundColor: selected ? 'rgba(38, 38, 38, 0.08)' : 'transparent',
            border: selected
              ? '1px solid rgba(38, 38, 38, 0.32)'
              : !disabled
              ? '1px solid rgba(38, 38, 38, 0.16)'
              : '1px solid rgba(38, 38, 38, 0.08)',
            cursor: !disabled ? 'pointer' : 'not-allowed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            borderRadius: '8px',
            padding: '12px 16px',
          }}
          variant="outlined"
          onClick={handleSelectOption}
          onMouseOver={handleOpen}
          onMouseOut={handleClose}
        >
          <img
            style={{ width: '24px', height: '24px', opacity: !disabled ? 1 : 0.08 }}
            src={icon}
            alt="distribution strategy"
          />
          <Typography
            sx={{
              color: !disabled ? colors.SECONDARY : colors.SECONDARY_DISABLED,
              fontFamily: 'Manrope-Medium',
              ml: 2,
            }}
          >
            {title}
          </Typography>
        </Paper>
      </Tooltip>
    </Grid>
  )
}

export default Option
