import EventIcon from '../icons/map-pin.svg'
import EmailIcon from '../icons/mail.svg'
import TwitterIcon from '../icons/twitter.svg'
import TikTokIcon from '../icons/tiktok-alt.svg'
import CreditCardIcon from '../icons/creditcard.svg'
import LinkIcon from '../icons/link.svg'
import DirectLinkAnimation from '../icons/animations/directlink@2x.gif'
import EmailAnimation from '../icons/animations/email@2x.gif'
import EventAnimation from '../icons/animations/event@2x.gif'
import TikTokAnimation from '../icons/animations/tiktok@2x.gif'
import CreditCardAnimation from '../icons/animations/creditcard@2x.gif'
import { DEFAULT_NETWORK_ID } from '../constants'

export interface DistributionOption {
  title: string
  icon: any
  animation: any
  selected: boolean
  extra?: JSX.Element
  disabled?: boolean
}

export const CAMPAIGN_COST = '1' // NEAR

// ceiling of how much a creator can set for an NFT.
// inherited from Wyre's Transaction Processing limit https://docs.sendwyre.com/reference/hosted-checkout#transaction-processing-limits
// TODO: is this per customer or creator??? https://docs.sendwyre.com/docs/wyre-checkout-1#transaction-processing-limits
export const NFT_MAX_PRICE = '1000.00' // USD

export const getCampaignLink = (campaignId: string) => {
  return `https://sonar${DEFAULT_NETWORK_ID === 'mainnet' ? '' : '-' + DEFAULT_NETWORK_ID}.satori.art/${campaignId}`
}

export const options: DistributionOption[] = [
  {
    title: 'Direct Link',
    icon: LinkIcon,
    animation: DirectLinkAnimation,
    selected: true,
  },
  {
    title: 'Credit Card',
    icon: CreditCardIcon,
    animation: CreditCardAnimation,
    selected: false,
  },
  {
    title: 'Event',
    icon: EventIcon,
    animation: EventAnimation,
    selected: false,
  },
  {
    title: 'Email',
    icon: EmailIcon,
    animation: EmailAnimation,
    selected: false,
  },
  {
    title: 'TikTok',
    icon: TikTokIcon,
    animation: TikTokAnimation,
    selected: false,
  },
  // {
  //   title: 'Twitter',
  //   svg: TwitterIcon,
  //   selected: false, // TODO: selected = true if this is twitter redirect
  //   description: '', //Require your users to retweet one of your Tweets in order to claim an NFT... Copy the URL of your Twitter post that users must retweet to claim their NFT, and paste it below
  // },
]
