import { Button, Box } from '@mui/material'
import { IOnboardItem } from '../../types'

function ResetPaymentMethod({ item }: { item: IOnboardItem }) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <Button color="secondary" variant="outlined" size="small" sx={{ mt: 2 }} onClick={item.onClickSecondary}>
        Change Bank Account
      </Button>
    </Box>
  )
}

export default ResetPaymentMethod
