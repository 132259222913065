import { Box, Button, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { SonarCollection } from '../../services/sonar-app'

interface EmptySeriesProps {
  collection: SonarCollection
}

function EmptySeries({ collection }: EmptySeriesProps) {
  const navigate = useNavigate()

  return (
    <Grid
      container
      direction="column"
      alignItems={'center'}
      justifyContent={'center'}
      sx={{ minHeight: '288px', border: '2px dashed rgba(38, 38, 38, 0.16)', borderRadius: '8px' }}
    >
      <Box sx={{ mb: 2 }}>
        <img src="/empty-series.png" alt="empty series" style={{ width: '69px' }} loading="lazy"></img>
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          navigate('/create-series', {
            state: { collectionId: collection.contractId },
          })
        }
        size="small"
      >
        Create NFT
      </Button>
    </Grid>
  )
}

export default EmptySeries
