export const DEFAULT_NETWORK_ID = process.env.REACT_APP_DEFAULT_NETWORK_ID || 'testnet'

export const Constants = {
  SPEARMINT_API_KEY: process.env.REACT_APP_SPEARMINT_API_KEY,
  SPEARMINT_APP_NAME: 'creator-studio',
  EXPLORER_DOMAIN: DEFAULT_NETWORK_ID === 'mainnet' ? 'https://explorer.near.org' : 'https://explorer.testnet.near.org',
  SATORI_NEAR_ACCOUNT: DEFAULT_NETWORK_ID === 'mainnet' ? 'snft.near' : 'snft.testnet',
  SPEARMINT_API_URL: `https://spearmint-${DEFAULT_NETWORK_ID}.satdev.workers.dev`,
  SONAR_API_URL: process.env.REACT_APP_SONAR_API_URL || `https://sonar-api-${DEFAULT_NETWORK_ID}.satori.art/api`,
  SNFT_SUFFIX: DEFAULT_NETWORK_ID === 'mainnet' ? '.snft.near' : '.snft.testnet',
  SNFT_DEFAULT_ROYALTY_AMOUNT: 200,
  CHECK_IN_CODE_VALID_MS: 20000,
  SATORI_INFO_EMAIL: 'info@satori.art',
  GOOGLE_ANALYTICS_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_ID || 'G-FR8Q8LN8PB',
  NFT_STORAGE_TOKEN: process.env.REACT_APP_NFT_STORAGE_TOKEN,
  NODE_ENV: process.env.NODE_ENV,
  FEES: {
    SATORI: {
      baseUSD: 0.75,
      percent: 2.5,
    },
    WYRE: {
      baseUSD: 0.3,
      percent: 4.1,
    },
  },
}
