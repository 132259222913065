import { Button, Grid, List, ListItem, Typography, Card, Menu, MenuItem, Snackbar, useMediaQuery } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { collectionsState, errorState } from '../../store'
import { SUCCESS_STATUSES, Series, CampaignData } from '../../store/models'
import CollectionHeader from './CollectionHeader'
import EmptySeries from './EmptySeries'
import { formatSeriesId } from '../../services/helpers'
import { getClaimLinks } from '../../services/claimlinks'
import FileViewer from '../Series/FileViewer'
import { Royalty } from '../../services/series'
import explorer from '../../utils/Explorer'
import { ERROR_MESSAGES } from '../../utils/Error'
import { DEFAULT_NETWORK_ID } from '../../constants'
import { getIpfsMediaUrl } from '../../utils/Other'
import Metrics from './Metrics'
import Campaign from './Campaign'
import { SonarCollection } from '../../services/sonar-app'
import { Link } from 'react-router-dom'

function PopulatedDashboard() {
  const xs = useMediaQuery('(max-width: 600px)')
  const sm = useMediaQuery('(max-width: 900px)')

  const navigate = useNavigate()
  const [collections] = useRecoilState(collectionsState)
  const [_, setError] = useRecoilState(errorState)

  // COLLECTION MENU (mobile only)
  const [anchorElCo, setAnchorElCo] = useState(null)
  const [activeCo, setActiveCo] = useState<SonarCollection | null>(null)
  const openMenuCo = Boolean(anchorElCo)

  //@ts-ignore
  function handleMenuClickCo(event, collection: SonarCollection) {
    setActiveCo(collection)
    setAnchorElCo(event.currentTarget)
  }
  function handleMenuCloseCo() {
    setAnchorElCo(null)
  }

  function handleCreateNFTSeries() {
    if (!activeCo) return
    navigate('/create-series', {
      state: { collectionId: activeCo.contractId },
    })
  }

  function handleCollectionsExplorerLink() {
    if (!activeCo) return
    window.open(explorer.getAccountURL(activeCo.contractId), '_blank')
  }

  // SERIES MENU
  const [anchorElS, setAnchorElS] = useState(null)
  const [activeS, setActiveS] = useState<Series | null>(null)
  const [openSnackS, setOpenSnackS] = useState(false)
  const openMenuS = Boolean(anchorElS)

  //@ts-ignore
  function handleMenuClickS(event, series) {
    setActiveS(series)
    setAnchorElS(event.currentTarget)
  }
  function handleMenuCloseS() {
    setAnchorElS(null)
  }
  async function handleGenerateAndCopyClaimLink() {
    if (activeS) {
      const res = await getClaimLinks(`${activeS.contractId}/${activeS.title}`, 1)
      if (!SUCCESS_STATUSES.includes(res.status)) {
        console.warn('error getting claimlink: ', res.data)
        setError(ERROR_MESSAGES.CLAIM_LINK_ERROR)
      } else {
        navigator.clipboard.writeText(res.data[0])
        handleSeriesSnackClick()
      }
    }
    handleMenuCloseS()
  }
  function handleCopyIPFSLink() {
    if (activeS) {
      const IPFSLink = getIpfsMediaUrl(activeS.mediaCid as string)
      navigator.clipboard.writeText(IPFSLink)
      handleSeriesSnackClick()
    }
    handleMenuCloseS()
  }

  function handleSeriesSnackClick() {
    setOpenSnackS(true)
  }
  //@ts-ignore
  function handleSeriesSnackClose(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackS(false)
  }

  function handleSeriesExplorerLink() {
    if (activeS) {
      window.open(explorer.getAccountURL(activeS.contractId))
    }
    handleMenuCloseS()
  }

  // CAMPAIGN MENU
  const [anchorElC, setAnchorElC] = useState(null)
  const [activeC, setActiveC] = useState<CampaignData | null>(null)
  const openMenuC = Boolean(anchorElC)

  //@ts-ignore
  function handleMenuClickC(event, campaign) {
    setActiveC(campaign)
    setAnchorElC(event.currentTarget)
  }
  function handleMenuCloseC() {
    setAnchorElC(null)
  }
  function handleMonitoringLink() {
    handleMenuCloseC()
    if (activeC) {
      navigate(`/view-campaign/${activeC.id}`)
    }
  }
  function handleLandingPageLink() {
    handleMenuCloseC()
    if (activeC) {
      window.open(
        `https://sonar${DEFAULT_NETWORK_ID === 'mainnet' ? '' : '-' + DEFAULT_NETWORK_ID}.satori.art/${activeC.id}`
      )
    }
  }

  useEffect(() => {}, [activeS, activeC])

  return (
    <Grid container spacing={0} direction="column" alignItems="start" justifyContent="start" sx={{ p: 0 }}>
      <Metrics />
      {xs ? (
        collections.length > 0 ? (
          <Button
            variant="outlined"
            color="secondary"
            component={Link}
            to={'/create-collection'}
            size="medium"
            fullWidth
            sx={{ mt: { xs: 4, sm: 4, md: 0 }, alignSelf: { xs: 'center', sm: 'center', md: 'none' } }}
          >
            Create Collection
          </Button>
        ) : null
      ) : null}
      <List sx={{ width: '100%' }}>
        {collections.map((c) => (
          <ListItem sx={{ pl: 0, pr: 0 }} key={c.contractId}>
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="space-between">
              <CollectionHeader collection={c} openMenuCo={openMenuCo} handleMenuClickCo={handleMenuClickCo} />
              {/* SERIES */}
              {c.series ? (
                c.series.length > 0 ? (
                  <List sx={{ width: '100%' }}>
                    {c.series.map((s) => (
                      <ListItem sx={{ pl: 0, pr: 0 }} key={`${s.contractId} + '/' + ${s.title}`}>
                        <Card sx={{ width: '100%', mb: 2 }}>
                          <Grid
                            container
                            spacing={0}
                            justifyContent="space-between"
                            sx={{
                              p: 3,
                              flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                              alignItems: { xs: 'center', sm: 'center', md: 'start' },
                              justifyContent: { xs: 'start', sm: 'start', md: 'space-between' },
                            }}
                          >
                            {/* IMG */}
                            <FileViewer fromIPFS mediaID={s.mediaCid} />
                            {/* CONTENT */}
                            <Grid
                              container
                              spacing={0}
                              direction="column"
                              alignItems="center"
                              justifyContent="space-between"
                              sx={{ flex: 1, mt: 2, ml: { md: 4 } }}
                            >
                              <Grid
                                container
                                spacing={0}
                                direction="row"
                                alignItems="start"
                                justifyContent="space-between"
                              >
                                <Grid
                                  item
                                  xs={10}
                                  sm={10}
                                  md={12}
                                  sx={{
                                    mb: 3,
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Typography variant="h4" component="h4" sx={{ mb: 1, wordBreak: 'break-word' }}>
                                    {s.title}
                                  </Typography>
                                  <Typography variant="h5" component="p" sx={{ mb: 1 }}>
                                    {s.copiesMinted} / {s.copies} minted
                                  </Typography>
                                </Grid>
                                {sm ? (
                                  <IconButton
                                    aria-label="More"
                                    size="large"
                                    aria-controls={openMenuS ? 'series-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openMenuS ? 'true' : undefined}
                                    onClick={(e) => handleMenuClickS(e, s)}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                ) : null}
                              </Grid>

                              <Grid
                                container
                                spacing={0}
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Grid
                                  container
                                  rowSpacing={1}
                                  columnSpacing={{ xs: 1, sm: 2, md: 0 }}
                                  sx={{ ml: { xs: 0, sm: 0, md: 0 }, mb: 4 }}
                                >
                                  {s.description !== undefined ? (
                                    <Grid sx={{ width: '100%' }}>
                                      <Typography variant="h6" component="p" sx={{ mb: 1, fontSize: '12px' }}>
                                        Description
                                      </Typography>
                                      <Typography variant="body1" component="p" sx={{ mb: 1, pl: { md: 4 } }}>
                                        {s.description}
                                      </Typography>
                                    </Grid>
                                  ) : null}
                                  {s.royalty !== undefined ? (
                                    <Grid sx={{ mt: { xs: 2 } }}>
                                      <Typography variant="h6" component="p" sx={{ mb: 1, fontSize: '12px' }}>
                                        Royalties
                                      </Typography>
                                      {Object.keys(s.royalty).map((key, i) => {
                                        return (
                                          <Grid
                                            item
                                            key={i}
                                            sx={{
                                              flexDirection: 'row',
                                              justifyContent: 'space-between',
                                              alignItems: 'space-between',
                                              display: 'flex',
                                              width: { xs: '100%', sm: '400px' },
                                              borderBottom: '1px solid rgba(38, 38, 38, 0.08);',
                                              ml: { md: 4 },
                                            }}
                                          >
                                            <Typography variant="body1" component="span">
                                              {key}
                                            </Typography>
                                            <Typography variant="h5" component="span">
                                              {(s.royalty as Royalty)[key] / 100}%
                                            </Typography>
                                          </Grid>
                                        )
                                      })}
                                    </Grid>
                                  ) : null}
                                </Grid>

                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    navigate(
                                      `/create-campaign?seriesId=${encodeURIComponent(
                                        formatSeriesId(c.contractId, s.title)
                                      )}`
                                    )
                                  }
                                  fullWidth={xs ? true : false}
                                  size="small"
                                  startIcon={<AddIcon />}
                                >
                                  Create Campaign
                                </Button>
                              </Grid>
                            </Grid>
                            {sm ? null : (
                              <Grid>
                                <IconButton
                                  aria-label="More"
                                  size="large"
                                  aria-controls={openMenuS ? 'series-menu' : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={openMenuS ? 'true' : undefined}
                                  onClick={(e) => handleMenuClickS(e, s)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                          {s.campaigns ? (
                            s.campaigns.length > 0 ? (
                              <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="start"
                                justifyContent="space-between"
                                sx={{ borderTop: `1px solid rgba(38, 38, 38, 0.08)` }}
                              >
                                {s.campaigns.map((c, i) => {
                                  return (
                                    <Campaign
                                      campaign={c}
                                      openMenu={openMenuC}
                                      handleMenuClick={handleMenuClickC}
                                      key={c.id}
                                    ></Campaign>
                                  )
                                })}
                              </Grid>
                            ) : null
                          ) : null}
                        </Card>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <EmptySeries collection={c} />
                )
              ) : null}
            </Grid>
          </ListItem>
        ))}
      </List>

      <Menu
        id="collection-menu"
        anchorEl={anchorElCo}
        open={openMenuCo}
        onClose={handleMenuCloseCo}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleCreateNFTSeries}>Create NFT</MenuItem>
        <MenuItem onClick={handleCollectionsExplorerLink}>View in explorer</MenuItem>
      </Menu>

      <Menu
        id="series-menu"
        anchorEl={anchorElS}
        open={openMenuS}
        onClose={handleMenuCloseS}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleGenerateAndCopyClaimLink}>Copy claim link</MenuItem>
        <MenuItem onClick={handleCopyIPFSLink}>Copy IPFS link</MenuItem>
        <MenuItem onClick={handleSeriesExplorerLink}>View in explorer</MenuItem>
        {/* <MenuItem disabled>Transfer</MenuItem> */}
        {/* <MenuItem disabled>Burn NFT</MenuItem> */}
      </Menu>

      <Snackbar open={openSnackS} autoHideDuration={2000} onClose={handleSeriesSnackClose} message="Copied Link!" />

      <Menu
        id="campaign-menu"
        anchorEl={anchorElC}
        open={openMenuC}
        onClose={handleMenuCloseC}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/* <MenuItem>Edit</MenuItem> */}
        <MenuItem onClick={handleMonitoringLink}>View Stats</MenuItem>
        <MenuItem onClick={handleLandingPageLink}>View Landing Page</MenuItem>
        {/* <MenuItem disabled>Remove</MenuItem> */}
      </Menu>
    </Grid>
  )
}

export default PopulatedDashboard
