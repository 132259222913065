import { AxiosRequestHeaders } from 'axios'
import { CreateSeriesResponse, SeriesByIdResponse, SeriesResponse } from '.'
import { Constants } from '../../constants'
import { spearmint, sonar } from '../axios'
import { resolveResponseError, resolveResponseSuccess } from '../helpers'
import { CreateSeriesRequest, SeriesByIdRequest } from './models'

export async function postSeries(appName: string, apiKey: string, body: CreateSeriesRequest, fundingHash: string) {
  const nftContent = JSON.stringify(body)

  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${apiKey}`,
    'funding-hash': fundingHash,
  }

  return sonar({ appName, apiKey })
    .post(`app/series`, nftContent, { headers })
    .then((r) => resolveResponseSuccess<CreateSeriesResponse>(r))
    .catch(resolveResponseError)
}

export function getSeries() {
  return spearmint
    .get(`/v1/api/${Constants.SPEARMINT_APP_NAME}/series`)
    .then((r) => resolveResponseSuccess<SeriesResponse>(r))
    .catch(resolveResponseError)
}

export function getSeriesById({ seriesId }: SeriesByIdRequest) {
  return spearmint
    .get(`/v1/api/${Constants.SPEARMINT_APP_NAME}/series/${seriesId}`)
    .then((r) => resolveResponseSuccess<SeriesByIdResponse>(r))
    .catch(resolveResponseError)
}
