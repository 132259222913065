import { Grid, Typography } from '@mui/material'
import { DistributionOption } from '../../utils/Campaign'
import useMediaQuery from '@mui/material/useMediaQuery'

interface InstructionProps {
  option: DistributionOption
}

function Instruction({ option }: InstructionProps) {
  const sm = useMediaQuery('(max-width:900px)')

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        mt: { xs: 0, sm: 0, md: 0 },
        mb: { xs: 0, sm: 0, md: 0 },
        width: '100%',
      }}
    >
      <Typography sx={{ mb: { xs: 3, sm: 3, md: 5 } }} variant="h2">
        To get your NFT, a fan must do the following:
      </Typography>
      <img
        src={option.animation}
        style={{ width: '80%', maxWidth: '500px', height: 'auto', mixBlendMode: 'multiply' }}
        alt="Instructions"
      />
      {/* BACKGROUND */}
      {sm ? null : (
        <div
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            width: '50vw',
            height: '100vh',
            background: sm
              ? 'transparent'
              : 'linear-gradient(180deg, rgba(38, 38, 38, 0.03) 0%, rgba(38, 38, 38, 0) 100%)',
          }}
        ></div>
      )}
    </Grid>
  )
}

export default Instruction
