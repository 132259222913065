const formatCurrency = (value: number, separator = ',') => {
  const roundedValue = Math.round(value * 100) / 100
  const [whole = '0', decimal = '00'] = String(roundedValue).split('.')
  const formattedWhole = whole
    .split('')
    .reverse()
    .reduce<string[]>((prev, current, index) => {
      prev.push(current)

      const shouldInsertSeparator = (index + 1) % 3 === 0 && index !== whole.length - 1
      if (shouldInsertSeparator) {
        prev.push(separator)
      }
      return prev
    }, [])
    .reverse()
    .join('')
  const formattedDecimal = `${decimal}00`.substring(0, 2)

  return {
    value: `${formattedWhole}.${formattedDecimal}`,
    whole: formattedWhole,
    decimal: formattedDecimal,
    numeric: roundedValue,
  }
}

export { formatCurrency }
