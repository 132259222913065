import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'
import { collectionsState, errorState, userState } from '../../store'
import { SUCCESS_STATUSES } from '../../store/models'
import { ERROR_MESSAGES } from '../../utils/Error'
import { AppStats, getAppStats } from '../../services/sonar-app'
import colors from '../../theme/colors'

let intervalId: number | undefined
const POLLING_INTERVAL = 10000 // 10 seconds

function Metrics() {
  const [appStats, setAppStats] = useState<AppStats | null>(null)
  const [user] = useRecoilState(userState)
  const [, setError] = useRecoilState(errorState)
  const [collections] = useRecoilState(collectionsState)

  useEffect(() => {
    window.addEventListener('focus', startPoll)
    window.addEventListener('blur', stopPoll)
    ;(async () => startPoll())()
    return stopPoll
  }, [])

  const fetchAppStats = async () => {
    try {
      const statsRes = await getAppStats(user.email, user.apiToken)
      if (!SUCCESS_STATUSES.includes(statsRes.status)) {
        console.warn('error getting app stats: ', statsRes.status, statsRes.data)
        setError(ERROR_MESSAGES.APP_STATS_ERROR)
        stopPoll()
      } else {
        setAppStats(statsRes.data)
      }
    } catch (e) {
      console.warn(e)
      setError(ERROR_MESSAGES.APP_STATS_ERROR)
      stopPoll()
    }
  }

  const startPoll = async () => {
    if (intervalId) window.clearInterval(intervalId)
    await fetchAppStats() // call manually first
    intervalId = window.setInterval(fetchAppStats, POLLING_INTERVAL)
  }

  const stopPoll = () => {
    if (intervalId) {
      window.clearInterval(intervalId)
      intervalId = undefined
    }
  }

  const [nftsMinted, nftsAvailable] = useMemo(() => {
    let copiesMinted = 0
    let copiesRemaining = 0
    collections.forEach((c) => {
      c.series.forEach((s) => {
        copiesRemaining += s.copiesRemaining
        copiesMinted += s.copiesMinted || 0
      })
    })
    return [copiesMinted, copiesRemaining]
  }, [collections])

  const MetricNumber = styled(Typography)(({ theme }) => ({
    color: colors.PRIMARY,
    fontSize: '40px',
    '@media (max-width: 600px)': {
      fontSize: '24px',
    },
  }))

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        mt: 4,
      }}
    >
      <Grid item xs={6} md={3} sx={{ mb: { xs: 4, sm: 4, md: 0 } }}>
        <MetricNumber>{appStats ? appStats.campaignViews : '-'}</MetricNumber>
        <Typography variant="body1">Campaign visits</Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <MetricNumber>{appStats ? nftsAvailable : '-'}</MetricNumber>
        <Typography variant="body1">NFTs available to mint</Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <MetricNumber>{appStats ? nftsMinted : '-'}</MetricNumber>
        <Typography variant="body1">Total NFTs minted</Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <MetricNumber>{appStats ? appStats.linkdrops : '-'}</MetricNumber>
        <Typography variant="body1">NEAR accounts created</Typography>
      </Grid>
    </Grid>
  )
}

export default Metrics
