import { Grid, CircularProgress, GridProps, CircularProgressProps } from '@mui/material'

type LoadingProps = GridProps & { CircularProgressProps?: CircularProgressProps }

function Loading({
  sx = {},
  direction = 'column',
  alignItems = 'center',
  justifyContent = 'center',
  CircularProgressProps = {},
  ...props
}: LoadingProps) {
  return (
    <Grid
      container
      direction={direction}
      alignItems={alignItems}
      justifyContent={justifyContent}
      sx={{
        mt: 16,
        height: '100%',
        ...sx,
      }}
      {...props}
    >
      <CircularProgress {...CircularProgressProps} />
    </Grid>
  )
}

export default Loading
