import { Constants } from '../constants'
import { Royalty, RoyaltyAmount } from '../services/series'
import { AccountId } from '../store/models'

// Recipient of royalties across all NFTs minted by Satori
const defaultRoyaltyAccountID: AccountId = Constants.SATORI_NEAR_ACCOUNT

const defaultRoyaltyAmount: RoyaltyAmount = Constants.SNFT_DEFAULT_ROYALTY_AMOUNT

const defaultRoyalty: Royalty = {}
defaultRoyalty[defaultRoyaltyAccountID] = defaultRoyaltyAmount

export default defaultRoyalty

export function toPerc(amt: number) {
  return amt / 100
}

export function toAmount(perc: number) {
  return perc * 100
}
