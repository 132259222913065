import { SetterOrUpdater } from 'recoil'
import { CampaignBody } from './models/campaign'
import { Series } from './models/series'
import { Error, SUCCESS_STATUSES } from './models/common'
import { postCollection } from '../services/collections'
import { CreateSeriesRequest, getSeriesById, postSeries } from '../services/series'
import { CampaignUpdateBody, getCampaigns, postCampaign, updateCampaign } from '../services/campaigns'
import { getCollections, SonarCollection, SonarSeries } from '../services/sonar-app'
import { getContractIdAndSeriesTitleFromSeriesId } from '../utils/Series'

export async function initStore(
  appName: string,
  apiKey: string,
  setCollections: SetterOrUpdater<SonarCollection[]>,
  setSeries: SetterOrUpdater<Series[]>,
  setCampaigns: SetterOrUpdater<CampaignBody[]>,
  setLoading: SetterOrUpdater<boolean> | null
) {
  const collectionsRes = await getCollections(appName, apiKey)

  // TODO: EXCEPTION HANDING
  // Test what happens when the getCollections response is an empty object with no keyvalue pairs

  if (!SUCCESS_STATUSES.includes(collectionsRes.status)) {
    console.warn('Error getting collections: ', collectionsRes)
    if (setLoading) setLoading(false)
    return
  }

  const seriesIds: string[] = []
  collectionsRes.data.forEach((collection: SonarCollection<SonarSeries>) => {
    collection.series.forEach((s) => seriesIds.push(s.id))
  })
  // Dress Series with detailed data
  const dressedSeries = await dressSeries(seriesIds)

  collectionsRes.data.forEach((collection: SonarCollection<SonarSeries>) => {
    collection.series = collection.series.map((s) => {
      return {
        ...dressedSeries.find((e) => e.id === s.id),
        ...s,
      }
    })
  })

  setCollections(collectionsRes.data)

  // Save these to store as flat lists for reference
  setSeries(dressedSeries)
  const campaignsRes = await getCampaigns(appName, apiKey)
  if (!SUCCESS_STATUSES.includes(campaignsRes.status)) {
    console.warn('Error getting campaigns: ', campaignsRes)
  } else {
    setCampaigns(campaignsRes.data)
  }
  if (setLoading) setLoading(false)
}

async function dressSeries(seriesIds: string[]) {
  let list: Series[] = []

  const seriesByIdResponses = await Promise.all(
    seriesIds.map(async (id) => {
      return { ...(await getSeriesById({ seriesId: encodeURIComponent(id) })).data }
    })
  )

  list = seriesByIdResponses
    .map((r, i) => {
      return {
        id: r.seriesId,
        contractId: r.contractId,
        createdAt: r.createdAt,
        copiesMinted: r.copiesMinted,
        copiesRemaining: r.copiesRemaining,
        title: r.metadata ? r.metadata.title : 'deprecated',
        description: r.metadata ? r.metadata.description : 'deprecated',
        copies: r.metadata ? r.metadata.copies : 0,
        royalty: r.royalties,
        mediaCid: r.metadata ? r.metadata.media : '',
        campaigns: [],
        // TODO: theme?
      } as Series
    })
    .sort((a, b) => b.createdAt - a.createdAt)

  return list
}

export async function createCollection(
  appName: string,
  apiKey: string,
  body: {
    title: string
    fundingHash: string
  },
  collections: SonarCollection[],
  setCollections: SetterOrUpdater<SonarCollection[]>
) {
  const createCollectionRes = await postCollection(appName, apiKey, body)
  if (!SUCCESS_STATUSES.includes(createCollectionRes.status)) {
    console.warn('Error creating collection: ', createCollectionRes)
    return createCollectionRes
  }
  // TODO add exception handler
  /**
   * Error Message Example
   * {
    "contractId": "ethdenver-test-tuesday-215.snft.testnet",
    "accountError": "{\"message\":\"Timeout while waiting for response\",\"context\":{\"transactionHash\":\"CrLFpTKw9dQcvvLkF3VMPXjUUyrG2rpv9pLD79mmux6h\"}}",
    "deployError": "{\"message\":\"Timeout while waiting for response\",\"context\":{\"transactionHash\":\"8th63sCFjgruUSvzpxYwcypfmfFAWDZ6EQpP1nwTRfh3\"}}"
    }
   * 
   */

  const newCollections = [{ ...createCollectionRes.data, series: [] }, ...collections]

  setCollections(newCollections)

  return createCollectionRes
}

export async function createCampaign(
  appName: string,
  apiKey: string,
  series: Series[],
  setSeries: SetterOrUpdater<Series[]>,
  collections: SonarCollection[],
  setCollections: SetterOrUpdater<SonarCollection[]>,
  body: CampaignBody,
  fundingHash: string
) {
  const createResponse = await postCampaign(appName, apiKey, body, fundingHash)
  if (SUCCESS_STATUSES.includes(createResponse.status)) {
    const idxToUpdate = series.findIndex((s) => s.id === body.seriesId)
    if (idxToUpdate === -1) {
      console.warn("Couldn't find series to update with new campaign... not going to update")
      return {
        data: "Couldn't find series to update with new campaign... not going to update",
      } as Error
    } else {
      const updatedSeries = series.map((s, idx) => {
        if (idx === idxToUpdate) {
          return {
            ...s,
            campaigns: [createResponse.data, ...s.campaigns],
          }
        } else return { ...s }
      })
      setSeries(updatedSeries)
      const contractId = series[idxToUpdate].contractId
      const updatedCollections = collections.map((c) => {
        if (c.contractId === contractId) {
          return {
            ...c,
            series: updatedSeries,
          }
        }
        return { ...c }
      })
      setCollections(updatedCollections)
      return createResponse
    }
  } else {
    return createResponse as unknown as Error
  }
}

export async function editCampaign(
  appName: string,
  apiKey: string,
  campaignId: string,
  seriesId: string,
  updates: CampaignUpdateBody,
  collections: SonarCollection[],
  setCollections: SetterOrUpdater<SonarCollection[]>
) {
  const updateResponse = await updateCampaign(appName, apiKey, campaignId, updates)
  if (SUCCESS_STATUSES.includes(updateResponse.status)) {
    // await initStore
    const [contractId, seriesTitle] = getContractIdAndSeriesTitleFromSeriesId(seriesId)
    const updatedCollections = collections.map((c) => {
      if (c.contractId === contractId) {
        const series = c.series.map((s) => {
          if (s.title === seriesTitle) {
            const campaigns = s.campaigns.map((c) => {
              if (c.id === campaignId) {
                return { ...updateResponse.data }
              } else return { ...c }
            })
            return {
              ...s,
              campaigns,
            }
          } else return { ...s }
        })
        return {
          ...c,
          series,
        }
      } else return { ...c }
    })
    setCollections(updatedCollections)
    return updateResponse
  } else {
    return updateResponse as Error
  }
}

export function validateContractNameForUniqueness(): boolean {
  // TODO SERVICE - check the blockchain if contract name is available
  return true
}

export async function createSeries(
  appName: string,
  apiKey: string,
  request: {
    nftContent: CreateSeriesRequest
    fundingHash: string
  },
  series: Series[],
  setSeries: SetterOrUpdater<Series[]>,
  collections: SonarCollection[],
  setCollections: SetterOrUpdater<SonarCollection[]>
) {
  // Create new Series
  const createSeriesRes = await postSeries(appName, apiKey, request.nftContent, request.fundingHash)
  if (!SUCCESS_STATUSES.includes(createSeriesRes.status)) {
    console.warn('error creating series: ', createSeriesRes)
    return createSeriesRes
  }
  const { data: createSeriesData } = createSeriesRes

  // Get Details for newly created Series
  const seriesByIdRes = await getSeriesById({
    seriesId: encodeURIComponent(createSeriesData.seriesId),
  })

  if (!SUCCESS_STATUSES.includes(seriesByIdRes.status)) {
    console.warn('error getting series by ID: ', seriesByIdRes)
    return seriesByIdRes
  }

  const { data } = seriesByIdRes

  let seriesObj = {
    seriesId: data.seriesId,
    contractId: data.contractId,
    // createdAt: data.createdAt,
    copiesMinted: data.copiesMinted,
    copiesRemaining: data.copiesRemaining,
    title: data.metadata.title,
    description: data.metadata.description,
    copies: data.metadata.copies,
    royalty: data.royalties,
    mediaCid: data.metadata.media,
    campaigns: [],
    // TODO: theme?
  }

  const newSeries = [seriesObj, ...series] as Series[]
  setSeries(newSeries)

  const newCollections = collections.map((c) => {
    return c.contractId === createSeriesData.seriesId.split('/')[0] ? { ...c, series: [...newSeries] } : c
  })
  setCollections(newCollections as SonarCollection[])

  // TODO SERVICE - NEAR ACCOUNT BALANCE
  return createSeriesRes
}
