import { Grid, Link, Typography, Box } from '@mui/material'
import colors from '../theme/colors'
import { Constants } from '../constants'
import useMediaQuery from '@mui/material/useMediaQuery'
import { handleOpenEmailClient } from '../utils/Other'

function Footer({
  absolute,
  id,
  pt = 4,
  pl = 4,
  pr = 4,
  pb = 4,
}: {
  absolute?: boolean
  id?: string
  pt?: number
  pl?: number
  pr?: number
  pb?: number
}) {
  const xs = useMediaQuery('(max-width:600px)')

  const styles: any = {
    pt,
    pb,
    pl,
    pr,
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    alignItems: {
      xs: 'center',
      sm: 'center',
    },
    justifyContent: {
      xs: 'space-between',
      sm: 'space-between',
    },
    position: absolute ? 'absolute' : 'relative',
  }
  if (absolute) styles.bottom = 0

  return (
    <Grid id={id || 'footer'} container sx={styles}>
      <Link
        href="https://satori.art"
        sx={{
          mb: {
            xs: 4,
            sm: 0,
          },
        }}
      >
        <img
          src={xs ? '/made-by-satori-vert.png' : '/made-by-satori.png'}
          alt="Made by Satori"
          style={{ width: xs ? '109px' : '162px' }}
        ></img>
      </Link>
      {!absolute && ( // absolute indicates that we have a split-screen display, in which case we shouldn't show this "questions or feedback" text
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
          <Typography
            id="questions-or-feedback"
            variant="body1"
            component="span"
            sx={{ color: colors.SECONDARY_DISABLED_STRONG }}
          >
            Have questions or feedback? let us know at{' '}
          </Typography>
          <Typography
            variant="body1"
            component="span"
            onClick={() => handleOpenEmailClient(Constants.SATORI_INFO_EMAIL)}
            sx={{ color: `${colors.BLUE_DISABLED} !important`, cursor: 'pointer' }}
          >
            info@satori.art
          </Typography>
        </Box>
      )}

      <Link
        href="https://near.org/"
        sx={{
          mb: {
            xs: 2,
            sm: 0,
          },
        }}
      >
        <img
          src={xs ? '/built-on-near-vert.png' : '/built-on-near.png'}
          alt="Built on NEAR"
          style={{ width: xs ? '165px' : '213px' }}
        ></img>
      </Link>
    </Grid>
  )
}

export default Footer
