import { CampaignBody, CampaignData, CampaignStats } from '../../store/models/campaign'
import { Success, Error } from '../../store/models/common'
import { sonar } from '../axios'
import { resolveResponseError, resolveResponseSuccess } from '../helpers'
import { CampaignUpdateBody } from './models'

export function postCampaign(
  appName: string,
  apiKey: string,
  body: CampaignBody,
  fundingHash: string
): Promise<Success<CampaignData> | Error> {
  return sonar({ appName, apiKey, 'funding-hash': fundingHash })
    .post('campaign', body)
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function getCampaign(
  appName: string,
  apiKey: string,
  campaignId: string
): Promise<Success<CampaignData> | Error> {
  return sonar({ appName, apiKey })
    .get(`campaign/${campaignId}`)
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function updateCampaign(
  appName: string,
  apiKey: string,
  campaignId: string,
  updates: CampaignUpdateBody
): Promise<Success<CampaignData> | Error> {
  return sonar({ appName, apiKey })
    .put(`campaign/${campaignId}`, JSON.stringify(updates))
    .then(resolveResponseSuccess)
    .catch(resolveResponseError)
}

export function getCampaignStats(
  appName: string,
  apiKey: string,
  campaignId: string
): Promise<Success<CampaignStats> | Error> {
  return sonar({ appName, apiKey })
    .get(`campaign/${campaignId}/stats`)
    .then((r) => resolveResponseSuccess<CampaignStats>(r))
    .catch(resolveResponseError)
}

export function getCampaigns(appName: string, apiKey: string): Promise<Success<CampaignData[]> | Error> {
  return sonar({ apiKey, appName }).get(`app/campaign`).then(resolveResponseSuccess).catch(resolveResponseError)
}
