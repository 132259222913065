import { Tooltip, Switch } from '@mui/material'
import { useRecoilState } from 'recoil'
import { collectionsState, userState } from '../../store'
import { editCampaign } from '../../store/dispatchers'
import { CampaignData, SUCCESS_STATUSES } from '../../store/models'

const Toggle = ({ campaign, onSuccess }: { campaign: CampaignData; onSuccess?: (c: CampaignData) => any }) => {
  const [user] = useRecoilState(userState)
  const [collections, setCollections] = useRecoilState(collectionsState)

  const now = Date.now()
  const isLive = now >= campaign.startTime && now <= campaign.endTime
  let toggleTooltipTitle
  if (now <= campaign.startTime) {
    // campaign hasn't started
    toggleTooltipTitle = 'Activate campaign'
  } else if (isLive) {
    // campaign is live
    toggleTooltipTitle = 'Deactivate campaign'
  } else {
    // campaign has finished
    toggleTooltipTitle = 'Campaign has ended and cannot be reactivated.'
  }
  return (
    <Tooltip title={toggleTooltipTitle}>
      <Switch
        onClick={async () => {
          if (now <= campaign.startTime) {
            // campaign hasn't started
            window.confirm('Are you sure you want to activate this campaign? This cannot be undone.')
            const res = await editCampaign(
              user.email,
              user.apiToken,
              campaign.id,
              campaign.seriesId,
              { startTime: Date.now() },
              collections,
              setCollections
            )
          } else if (isLive) {
            // campaign is live
            const confirmed = window.confirm(
              'Are you sure you want to deactivate this campaign? This cannot be undone.'
            )
            if (confirmed) {
              const res = await editCampaign(
                user.email,
                user.apiToken,
                campaign.id,
                campaign.seriesId,
                { endTime: Date.now() },
                collections,
                setCollections
              )
              if (SUCCESS_STATUSES.includes(res.status)) {
                if (onSuccess) onSuccess(res.data)
              } else {
                // TODO: handle error
              }
            }
          } else {
            // campaign has finished
            return
          }
          // handleActivateCampaign(c.id)
        }}
        // disabled
        aria-label="Toggle Campaign"
        checked={isLive}
        sx={{ mr: 2 }}
      />
    </Tooltip>
  )
}

export default Toggle
