import { Grid, Typography } from '@mui/material'

import { responsiveTypography } from '../../utils/styles'

interface FeesProps {
  title: string
  price: string
  satoriFee: number
  wyreFee: number
  totalPrice: number
  mt?: number
  mb?: number
}
function Fees({ price, title, satoriFee, wyreFee, totalPrice, mt = 3, mb = 3 }: FeesProps) {
  return (
    <Grid container direction="column" sx={{ pl: 0, mt, mb }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={0}
        sx={{ pt: '12px', pb: 1, borderBottom: '1px solid rgba(38, 38, 38, 0.08)' }}
      >
        <Grid item xs={8}>
          <Typography variant="body2" component="p" sx={{ ...responsiveTypography(0) }}>
            Price for {title} NFT
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" component="p" sx={{ ...responsiveTypography(0), textAlign: 'right' }}>
            $ {parseFloat(price).toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={0}
        sx={{ pt: '12px', pb: 1, borderBottom: '1px solid rgba(38, 38, 38, 0.08)' }}
      >
        <Grid item xs={8}>
          <Typography variant="body2" component="p" sx={{ ...responsiveTypography(0) }}>
            Satori Fees
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ width: '100%' }}>
          <Typography variant="body1" component="p" sx={{ ...responsiveTypography(0), textAlign: 'right' }}>
            $ {satoriFee.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={0}
        sx={{ pt: '12px', pb: 1, borderBottom: '1px solid rgba(38, 38, 38, 0.08)' }}
      >
        <Grid item xs={8}>
          <Typography variant="body2" component="p" sx={{ ...responsiveTypography(0) }}>
            Wyre Fees
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ width: '100%' }}>
          <Typography variant="body1" component="p" sx={{ ...responsiveTypography(0), textAlign: 'right' }}>
            $ {wyreFee.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="space-between" spacing={0} sx={{ pt: '12px', pb: 1 }}>
        <Grid item xs={8}>
          <Typography variant="body1" component="p" sx={{ ...responsiveTypography(0), fontFamily: 'Manrope-Bold' }}>
            Total
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ width: '100%' }}>
          <Typography
            variant="body1"
            component="p"
            sx={{ ...responsiveTypography(0), fontFamily: 'Manrope-Bold', textAlign: 'right' }}
          >
            $ {totalPrice.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Fees
