import AdapterDateFns from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import LoadingButton from '@mui/lab/LoadingButton'
import AddIcon from '@mui/icons-material/Add'
import DatePicker from '@mui/lab/DatePicker'
import moment from 'moment'
import { nanoid } from 'nanoid'
import type {} from '@mui/lab/themeAugmentation'
import { Button, Grid, Typography, TextField, Snackbar, Container, Box } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, Link, useSearchParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { collectionsState, errorState, loadingState, nearState, seriesState, userState } from '../store/index'
import { createCampaign } from '../store/dispatchers'
import { CampaignBody } from '../store/models/campaign'
import { SUCCESS_STATUSES } from '../store/models/common'
import { addTransaction, twitterCallback, twitterRedirect, updateApp } from '../services/sonar-app'
import { WalletConnection } from 'near-api-js'
import { getTransactionInformation, initNear, sendTokens, signIn } from '../services/near'
import { LS_NEAR_ID } from './CreateSeries'
import { TransactionBody, TransactionStatus } from '../store/models/transaction'
import { ERROR_MESSAGES } from '../utils/Error'
import { responsiveContainer } from '../utils/styles'
import Header from '../components/Campaign/Header'
import TwitterForm from '../components/Campaign/TwitterForm'
import Option from '../components/Campaign/Option'
import NullSeries from '../components/Campaign/NullSeries'
import Loading from '../components/Campaign/Loading'
import Instruction from '../components/Campaign/Instruction'
import useMediaQuery from '@mui/material/useMediaQuery'
import CTACancelButtonContainer from '../components/CTACancelButtonContainer'
import { DistributionOption, options, CAMPAIGN_COST, NFT_MAX_PRICE } from '../utils/Campaign'
import CreditCardForm from '../components/Campaign/CreditCardForm'

// Local Storage Keys
const LS_TWITTER_USER = 'TW_USER'
const LS_CAMPAIGN_NAME = 'CAMPAIGN_NAME'
const LS_START_DATE = 'START_DATE'
const LS_END_DATE = 'END_DATE'
const LS_DISTRIBUTION_OPTIONS = 'DIST_OPTS'
const LS_NANOID = 'NANO'
const LS_SERIES = 'SERIES'
const LS_TWEET_URL = 'TW_URL'
const LS_NFT_PRICE = 'NFT_PRICE'
const LS_WEBHOOK_URL = 'WEBHOOK_URL'
const LS_TERMS_LINK = 'TERMS_LINK'

function CreateCampaign() {
  const sm = useMediaQuery('(max-width:900px)')
  const navigate = useNavigate()
  // ====================================
  // WATCH FOR THESE IN URL (they will update based on redirects from Near Wallet)
  // 1) user signs into Near
  // 2) user pays Satori in Near for a Create Campaign fee
  // 3) component watches for a txHash, then creates a campaign
  // ====================================
  const [searchParams, setSearchParams] = useSearchParams()
  const sId = searchParams.get('seriesId')
  const txHash = searchParams.get('transactionHashes')
  const account_id = searchParams.get('account_id')

  // ====================================
  // NEAR SIGN-IN
  // ====================================
  const [user] = useRecoilState(userState)
  const [near, setNear] = useRecoilState(nearState)
  // called on NEAR sign-in redirect
  useEffect(() => {
    ;(async () => {
      if (account_id) {
        setLoading(true)
        try {
          if (account_id !== user.nearId) {
            // update user
            await updateApp(user.email, user.apiToken, { nearId: account_id })
          }
        } catch (e) {
          console.warn('error updating nearId: ', e)
        } finally {
          // save acct id to check tx hash
          localStorage.setItem(LS_NEAR_ID, account_id)
          const wallet = (near.wallet as WalletConnection) || (await initNear(setNear))
          setSearchParams({ seriesId })
          handleSendNear(wallet)
        }
      }
    })()
  }, [account_id])

  const handleSendNear = async (wallet: WalletConnection) => {
    sendTokens(wallet, CAMPAIGN_COST)
  }

  // ====================================
  // SET SERIES/COLLECTIONS
  // ====================================
  const [collections, setCollections] = useRecoilState(collectionsState)
  const [, setSeries] = useRecoilState(seriesState)
  const [seriesId, setSeriesId] = useState<string>('')
  const lsSeries = localStorage.getItem(LS_SERIES)

  useEffect(() => {
    if (sId) setSeriesId(decodeURIComponent(sId))
    else if (lsSeries) setSeriesId(lsSeries)
  }, [sId, lsSeries])

  const series = useMemo(() => {
    return collections.find((c) => c.contractId === seriesId.split('/')[0])?.series || []
  }, [collections, seriesId])

  const selectedSeries = useMemo(() => {
    return series.find((s) => s.id === seriesId)
  }, [series, seriesId])

  // ====================================
  // CREATE CAMPAIGN FORM
  // ====================================
  // FORM STATES
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [finalizing, setFinalizing] = useState<boolean>(false)
  const [, setLoading] = useRecoilState(loadingState)
  const [, setError] = useRecoilState(errorState)
  // FORM DATA
  const [campaignName, setCampaignName] = useState<string>('')
  const [campaignNameError, setCampaignNameError] = useState<string>('')
  const [startDate, setStartDate] = useState<moment.Moment | null>(null)
  const [startDateError, setStartDateError] = useState<string>('')
  const [endDate, setEndDate] = useState<moment.Moment | null>(null)
  const [endDateError, setEndDateError] = useState<string>('')
  const [areTermsRequired, setAreTermsRequired] = useState<boolean>(false)
  const [termsLink, setTermsLink] = useState<string>('')
  const [isTermsLinkValid, setIsTermsLinkValid] = useState<boolean>(false)
  const [termsLinkError, setTermsLinkError] = useState<string | undefined>(undefined)
  const [distributionOptions, setDistributionOptions] = useState<DistributionOption[]>(options)
  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  // Add a disabled flag to the CC campaign if user is not KYC'ed
  useEffect(() => {
    const updatedDistributionOptions = distributionOptions.map((o) => {
      const newOption = o
      if (o.title === 'Credit Card') {
        const shouldDisableCreditCard = !user.wyreUser?.paymentMethods.some(
          (pm) => pm.wyrePaymentMethodStatus === 'ACTIVE' || !pm.isDeleted
        )
        if (shouldDisableCreditCard) {
          newOption.disabled = true
        }
      }
      return newOption
    })
    setDistributionOptions(updatedDistributionOptions)
  }, [user])

  const selectedDistributionOption = useMemo(
    () => distributionOptions.filter((o) => o.selected)[0],
    [distributionOptions]
  )
  // WITH TWITTER (optional)
  const [twUsername, setTwUsername] = useState<string | null>(localStorage.getItem(LS_TWITTER_USER))
  const [tweetUrl, setTweetUrl] = useState<string>('')
  const [tweetUrlError, setTweetUrlError] = useState<string>('')
  const INVALID_URL_ERROR = 'Invalid tweet URL'
  // WITH CREDIT CARD (optional)
  const [nftPrice, setNftPrice] = useState<string | null>(localStorage.getItem(LS_NFT_PRICE))
  const [nftPriceError, setNftPriceError] = useState<string>('')
  const [webhookUrl, setWebhookUrl] = useState<string | null>(localStorage.getItem(LS_WEBHOOK_URL))
  const [webhookUrlError, setWebhookUrlError] = useState<string>('')
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null)

  // Listen for txHash and SUBMIT CAMPAIGN TO SONAR
  // called on NEAR payment redirect
  useEffect(() => {
    ;(async () => {
      if (txHash) {
        setFinalizing(true)
        if (series.length > 0) {
          // populate form
          populateFromLocalStorage()
          // get stuff from local storage
          const lsCampaignName = localStorage.getItem(LS_CAMPAIGN_NAME) as string
          const lsStartDate = parseInt(localStorage.getItem(LS_START_DATE) as string, 10)
          const lsEndDate = parseInt(localStorage.getItem(LS_END_DATE) as string, 10)
          const lsSeriesId = localStorage.getItem(LS_SERIES) as string
          const lsDistributionOptions = JSON.parse(
            localStorage.getItem(LS_DISTRIBUTION_OPTIONS) as string
          ) as DistributionOption[]
          const lsTweetUrl = localStorage.getItem(LS_TWEET_URL) as string
          const lsPrice = localStorage.getItem(LS_NFT_PRICE) as string
          const lsTermsLink = localStorage.getItem(LS_TERMS_LINK) as string
          // clear stuff from local storage
          clearLocalStorageItems()
          try {
            const { receiverId, signerId, amount, status } = await getTransactionInformation(txHash)
            const valid = signerId && receiverId && amount && status !== 'error'
            if (!valid) {
              /// something weird going on with transaction
              setError(ERROR_MESSAGES.NEAR_TRANSACTION_ERROR)
              /// TODO: log this error for reference
              return
            }
            const tx: TransactionBody = {
              category: 'campaign',
              timestamp: Date.now(),
              amount,
              receiverId,
              signerId,
              isRefund: false,
              hash: txHash,
              status: status as TransactionStatus,
            }
            const addTransactionRes = await addTransaction(user.email, user.apiToken, tx)
            if (!SUCCESS_STATUSES.includes(addTransactionRes.status)) {
              console.warn('error adding transaction: ', addTransactionRes)
              setError(ERROR_MESSAGES.NEAR_POST_TRANSACTION_ERROR)
              // TODO: REFUND NEAR!
              return
            }
            if (status !== 'success') {
              setError(ERROR_MESSAGES.NEAR_TRANSACTION_ERROR)
              setFinalizing(false)
              return
            }
            if (lsCampaignName && lsStartDate && lsEndDate) {
              const body: Omit<CampaignBody, 'id'> = {
                name: lsCampaignName.trim(),
                seriesId: lsSeriesId,
                startTime: lsStartDate, // TODO: get more exact MS for 12AM on selected date in user's TZ
                endTime: lsEndDate, // TODO: get more exact MS for 11:59PM on selected date in user's TZ
                termsLink: lsTermsLink,
                requirements: null,
              }
              const selected = lsDistributionOptions.filter((o) => o.selected)[0]
              switch (selected.title.toLowerCase()) {
                case 'event': {
                  body.requirements = {
                    qr: true,
                  }
                  break
                }
                case 'twitter': {
                  const [username, path, tweetId] = deconstructTweetUrl(lsTweetUrl)
                  body.requirements = {
                    socials: {
                      twitter: {
                        retweet: {
                          tweetId, // TODO: update these with actual parsed URL
                          tweeter: username,
                        },
                      },
                    },
                  }
                  break
                }
                case 'pass-through': {
                  break
                }
                case 'email': {
                  body.requirements = {
                    ...(lsTermsLink && { terms: true }),
                    email: true,
                  }
                  break
                }
                case 'tiktok': {
                  body.requirements = {
                    socials: {
                      tiktok: true,
                    },
                  }
                  break
                }
                case 'credit card': {
                  let fiatPayment = {
                    price: lsPrice,
                    orders: [] as string[],
                  } as any
                  if (webhookUrl && webhookUrl.length > 0) {
                    fiatPayment.webhookUrl = webhookUrl
                  }
                  body.requirements = { fiatPayment }
                  break
                }
                default: {
                  console.warn('Distribution option not found')
                }
              }
              setSearchParams({ seriesId })

              console.log('body:', body)

              try {
                const s = collections.find((c) => c.contractId === lsSeriesId.split('/')[0])?.series || []
                const campaignRes = await createCampaign(
                  user.email,
                  user.apiToken,
                  s,
                  setSeries,
                  collections,
                  setCollections,
                  body,
                  txHash
                )
                if (SUCCESS_STATUSES.includes(campaignRes.status)) {
                  navigate(`/view-campaign/${campaignRes.data.id}`)
                } else {
                  console.warn('error creating campaign: ', campaignRes)
                  setError(ERROR_MESSAGES.CREATE_CAMPAIGN_ERROR)
                  // TODO: REFUND NEAR!
                  setFinalizing(false)
                }
              } catch (e) {
                console.warn(e)
                setFinalizing(false)
              }
            } else {
              // something went wrong setting items in local storage &/or retrieving them; campaign won't be created but tx has completed
              console.warn('missing required properties to create campaign!')
              setFinalizing(false)
              setError(ERROR_MESSAGES.CREATE_CAMPAIGN_ERROR)
              // TODO: REFUND NEAR!
            }
          } catch (e) {
            console.warn('Error getting transaction info: ', e)
            setError(ERROR_MESSAGES.NEAR_TRANSACTION_ERROR)
            return
          }
        }
      }
    })()
  }, [txHash, series])

  useEffect(() => {
    let valid = true
    if (!campaignName || !startDate || !endDate) valid = false
    if (selectedDistributionOption.title === 'Twitter') {
      if (!tweetUrl || tweetUrlError) valid = false
    }
    if (selectedDistributionOption.title === 'Credit Card') {
      if (!nftPrice || nftPriceError) valid = false
      if (webhookUrlError) valid = false
    }
    if (areTermsRequired && !isTermsLinkValid) {
      valid = false
    }
    setIsFormValid(valid)
  }, [
    areTermsRequired,
    campaignName,
    endDate,
    isTermsLinkValid,
    nftPrice,
    nftPriceError,
    webhookUrlError,
    selectedDistributionOption,
    startDate,
    termsLink,
    tweetUrl,
    tweetUrlError,
  ])

  useEffect(() => {
    const hasTermsLink = typeof termsLink === 'string' && termsLink.length > 0
    const [isValidLink] = termsLink?.match(/https:\/\/.+\..+/g) || []
    const isValid = areTermsRequired && hasTermsLink && isValidLink

    if (!hasTermsLink || isValid) {
      setTermsLinkError(undefined)
      setIsTermsLinkValid(true)
    } else {
      setTermsLinkError('That is not a valid link.')
      setIsTermsLinkValid(false)
    }
  }, [areTermsRequired, termsLink, user.apiToken, user.email])

  const handleCreateCampaign = async () => {
    if (!campaignName || !seriesId || !startDate || !endDate) {
      console.warn('invalid campaign body') // TODO: validate at time of input
      return
    }
    setSubmitting(true)
    setLocalStorageItems()
    const wallet = (near.wallet as WalletConnection) || (await initNear(setNear))
    if (!wallet.isSignedIn()) {
      await signIn(wallet, `/create-campaign?seriesId=${encodeURIComponent(seriesId)}`)
    } else {
      // save acct id to check tx hash
      localStorage.setItem(LS_NEAR_ID, wallet.getAccountId())
      handleSendNear(wallet)
    }
  }

  const handleSetCampaignName = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (campaignNameError) setCampaignNameError('')
    const val = e.target.value
    if (val.includes('/')) {
      setCampaignNameError('Campaign name cannot include /')
    } else {
      setCampaignName(val)
    }
  }

  const handleSetTermsLink = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (termsLinkError) {
        setTermsLinkError(undefined)
      }
      setTermsLink(e.target.value)
    },
    [setTermsLinkError, setTermsLink, termsLinkError]
  )

  // LOCAL STORAGE METHODS
  const setLocalStorageItems = () => {
    console.log('SETTING LOCAL STORAGE ITEMS...')
    localStorage.setItem(LS_CAMPAIGN_NAME, campaignName.trim())
    if (startDate) localStorage.setItem(LS_START_DATE, startDate.valueOf().toString())
    if (endDate) localStorage.setItem(LS_END_DATE, endDate.valueOf().toString())
    localStorage.setItem(LS_DISTRIBUTION_OPTIONS, JSON.stringify(distributionOptions))
    localStorage.setItem(LS_TWEET_URL, tweetUrl)
    localStorage.setItem(LS_SERIES, seriesId)
    if (areTermsRequired && termsLink) {
      localStorage.setItem(LS_TERMS_LINK, termsLink)
    }
    console.log('localStorage', localStorage)
  }

  const clearLocalStorageItems = () => {
    console.log('CLEARING LOCAL STORAGE ITEMS...')
    localStorage.removeItem(LS_CAMPAIGN_NAME)
    localStorage.removeItem(LS_START_DATE)
    localStorage.removeItem(LS_END_DATE)
    localStorage.removeItem(LS_DISTRIBUTION_OPTIONS)
    localStorage.removeItem(LS_TWEET_URL)
    localStorage.removeItem(LS_NFT_PRICE)
    localStorage.removeItem(LS_WEBHOOK_URL)
    localStorage.removeItem(LS_SERIES)
    localStorage.removeItem(LS_TERMS_LINK)
    console.log('localStorage', localStorage)
  }

  const populateFromLocalStorage = () => {
    console.log('POPULATING STATE FROM LOCAL STORAGE...')
    console.log('localStorage', localStorage)
    setCampaignName(localStorage.getItem(LS_CAMPAIGN_NAME) || '')
    const lsStartDate = localStorage.getItem(LS_START_DATE)
    if (lsStartDate) setStartDate(moment(parseInt(lsStartDate, 10)))
    const lsEndDate = localStorage.getItem(LS_END_DATE)
    if (lsEndDate) setEndDate(moment(parseInt(lsEndDate, 10)))
    const lsDistrOpts = localStorage.getItem(LS_DISTRIBUTION_OPTIONS)
    if (lsDistrOpts) setDistributionOptions(JSON.parse(lsDistrOpts))
    const lsTweetUrl = localStorage.getItem(LS_TWEET_URL)
    if (lsTweetUrl) handleSetTweetUrl(lsTweetUrl)
    const lsNftPrice = localStorage.getItem(LS_NFT_PRICE)
    if (lsNftPrice) setNftPrice(lsNftPrice)
    const lsWebhookUrl = localStorage.getItem(LS_WEBHOOK_URL)
    if (lsWebhookUrl) setWebhookUrl(lsWebhookUrl)
    const lsTermsLink = localStorage.getItem(LS_TERMS_LINK)
    if (lsTermsLink) {
      setAreTermsRequired(true)
      setTermsLink(termsLink)
    }
  }

  // TWITTER METHODS
  const getPrefix = (tweetUrl: string) => {
    const validTwitterHosts = ['www.twitter.com/', 'https://twitter.com/', 'https://www.twitter.com/']
    let prefix
    for (let i = 0; i < validTwitterHosts.length; i++) {
      if (tweetUrl.startsWith(validTwitterHosts[i])) {
        prefix = validTwitterHosts[i]
      }
    }
    return prefix
  }

  const deconstructTweetUrl = (tweetUrl: string): string[] => {
    const prefix = getPrefix(tweetUrl)
    const deconstructed = tweetUrl.split(prefix as string)[1].split('/') // [username, path, tweetId + params]
    const username = deconstructed[0]
    const path = deconstructed[1]
    const tweetId = deconstructed[2].split('?')[0]
    return [username, path, tweetId]
  }

  const handleSetTweetUrl = (val: string) => {
    if (tweetUrlError) setTweetUrlError('')
    val = val.trim()
    setTweetUrl(val)
    const prefix = getPrefix(val)
    if (prefix) {
      const [username, path, tweetId] = deconstructTweetUrl(val)
      if (path !== 'status') setTweetUrlError(INVALID_URL_ERROR)
      else if (username !== twUsername)
        setTweetUrlError(`Please enter a tweet that was tweeted from your account (@${twUsername})`)
    } else {
      setTweetUrlError(INVALID_URL_ERROR)
    }
  }

  const handleSignInWithTwitter = async () => {
    try {
      const id = nanoid()
      localStorage.setItem(LS_NANOID, id)
      setLocalStorageItems()
      let redirectRes = await twitterRedirect(user.email, user.apiToken, id)
      if (!SUCCESS_STATUSES.includes(redirectRes.status)) {
        console.warn('error getting Twitter redirect url: ', redirectRes)
        setError(ERROR_MESSAGES.TWITTER_CONNECTION_ERROR)
      } else {
        const { url } = redirectRes.data
        window.location.href = url
      }
    } catch (e) {
      console.log('error connecting to twitter: ', e)
      clearLocalStorageItems()
      setError(ERROR_MESSAGES.TWITTER_CONNECTION_ERROR)
    }
  }

  useEffect(() => {
    ;(async () => {
      const oauthToken = searchParams.get('oauth_token')
      const oauthVerifier = searchParams.get('oauth_verifier')
      const id = localStorage.getItem(LS_NANOID)
      if (oauthToken && oauthVerifier && id) {
        populateFromLocalStorage()
        localStorage.removeItem(LS_NANOID)
        const res = await twitterCallback(user.email, user.apiToken, id, oauthToken, oauthVerifier)
        if (!SUCCESS_STATUSES.includes(res.status)) {
          console.warn('error in twitter callback: ', res)
          setError(ERROR_MESSAGES.TWITTER_VERIFICATION_ERROR)
        } else {
          const username = res.data.user.userName
          localStorage.setItem(LS_TWITTER_USER, username)
          setTwUsername(username)
          setSearchParams({ seriesId: encodeURIComponent(localStorage.getItem(LS_SERIES) || ('' as string)) })
          // TODO: remove ls items that need removing (twitter oauth-related)
        }
      }
    })()
  }, [])

  // CREDIT CARD METHODS
  const handleSetNftPrice = (val: string) => {
    console.log('val', val)
    // Reset validation check
    setNftPriceError('')
    let errorMsg: string = ''
    const periodCount = (val.match(/\./g) || []).length

    // validate
    if (val.startsWith('00')) return
    if (isNaN(parseFloat(val)) || periodCount > 1) errorMsg = 'Value must be a valid number'
    if (parseFloat(val) <= 0) errorMsg = 'Value must be greater than 0'
    if (parseFloat(val) > parseFloat(NFT_MAX_PRICE)) errorMsg = 'The price exceeds the max price.'
    if (val === '') errorMsg = ''

    // IT'S VALID - TRIM ANYTHING BEYOND TICK DECIMAL COUNT
    if (val.includes('.')) {
      const decimalPlaces = val.split('.')[1].length
      if (decimalPlaces > 2) {
        return
      }
    }

    if (errorMsg !== '') {
      setNftPriceError(errorMsg)
      return
    }

    localStorage.setItem(LS_NFT_PRICE, val)
    console.log('localStorage', localStorage)
    setNftPrice(val)
  }

  const handleSetWebhookUrl = (val: string) => {
    if (timer !== null) clearTimeout(timer)

    setTimeout(() => {
      // Reset validation check
      setWebhookUrlError('')
      let errorMsg: string = ''

      // validate
      if (!isValidHttpUrl(val)) errorMsg = 'Value must be a valid URL'
      if (val === '') errorMsg = ''

      if (errorMsg !== '') {
        setWebhookUrlError(errorMsg)
        return
      }

      localStorage.setItem(LS_WEBHOOK_URL, val)
    }, 500)

    setWebhookUrl(val)
  }

  function isValidHttpUrl(string: string) {
    let url
    try {
      url = new URL(string)
    } catch (_) {
      return false
    }
    return url.protocol === 'http:' || url.protocol === 'https:'
  }

  useEffect(() => {
    const isEmailSelected = selectedDistributionOption.title === 'Email'
    const hasTermsLink = typeof termsLink === 'string' && termsLink.length > 0
    if (isEmailSelected && hasTermsLink) {
      setAreTermsRequired(true)
    } else {
      setAreTermsRequired(false)
    }
  }, [selectedDistributionOption, termsLink])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid
        container
        spacing={0}
        sx={{
          ...responsiveContainer(),
        }}
      >
        <Container maxWidth="lg">
          {!seriesId ? (
            <NullSeries />
          ) : finalizing ? (
            <Loading />
          ) : (
            <Grid container sx={{ xs: 'column', sm: 'column', md: 'row' }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                sx={{ direction: 'column', alignItems: 'start', justifyContent: 'start' }}
              >
                <Header series={selectedSeries} id={seriesId} />
                <Grid container spacing={0} direction="row" alignItems="start" justifyContent="start">
                  {/* FORM */}
                  <Grid container spacing={0} direction="column" alignItems="start" justifyContent="start">
                    {/* SELECT DISTRIBUTION STRATEGY */}
                    <Grid item xs={12} sx={{ mb: 2, width: '100%' }}>
                      <Typography
                        variant="subtitle1"
                        component="h2"
                        gutterBottom
                        sx={{
                          mb: 3,
                        }}
                      >
                        Choose strategy
                      </Typography>
                      <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', mb: 6 }}>
                        {distributionOptions.map((option, idx) => {
                          return (
                            <Option
                              key={idx}
                              distributionOptions={distributionOptions}
                              option={option}
                              idx={idx}
                              setDistributionOptions={setDistributionOptions}
                            />
                          )
                        })}
                      </Grid>

                      {/* TWITTER FORM (OPTIONAL) */}
                      {selectedDistributionOption.title === 'Twitter' && (
                        <TwitterForm
                          setTweetUrlError={setTweetUrlError}
                          handleSetTweetUrl={handleSetTweetUrl}
                          handleSignInWithTwitter={handleSignInWithTwitter}
                          tweetUrlError={tweetUrlError}
                          twUsername={twUsername}
                        />
                      )}

                      {/* CC FORM (OPTIONAL) */}
                      {selectedDistributionOption.title === 'Credit Card' && (
                        <CreditCardForm
                          nftPrice={nftPrice}
                          handleSetNftPrice={handleSetNftPrice}
                          nftPriceError={nftPriceError}
                          webhookUrl={webhookUrl}
                          handleSetWebhookUrl={handleSetWebhookUrl}
                          webhookUrlError={webhookUrlError}
                          nftMaxPrice={NFT_MAX_PRICE}
                          title={selectedSeries ? selectedSeries.title : ''}
                        />
                      )}
                    </Grid>

                    {/* TERMS LINK */}
                    {selectedDistributionOption.title === 'Email' && (
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems={{ sm: 'center', md: 'flex-start' }}
                          mb={3}
                        >
                          <Box display="flex" flexDirection="row">
                            <Typography variant="subtitle1" component="h2" gutterBottom mr={1}>
                              Add custom Terms & Conditions
                            </Typography>
                            <Typography component="h2" gutterBottom color="text">
                              (optional)
                            </Typography>
                          </Box>
                        </Box>
                        <Grid container sx={{ mb: 5 }} direction="row" justifyContent="space-between" spacing={3}>
                          <Grid item xs={12}>
                            <TextField
                              id="campaign-terms-input"
                              label="Link to specific Terms & Conditions"
                              variant="filled"
                              onFocus={() => {
                                setTermsLinkError(undefined)
                              }}
                              error={Boolean(termsLinkError)}
                              helperText={termsLinkError}
                              onChange={handleSetTermsLink}
                              inputProps={{ tabIndex: 1 }}
                              InputProps={{ disableUnderline: true }}
                              fullWidth
                              value={termsLink}
                              sx={{ mb: 3 }}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}

                    <Typography
                      variant="subtitle1"
                      component="h2"
                      gutterBottom
                      sx={{
                        mb: 3,
                        textAlign: sm ? 'center' : 'left',
                      }}
                    >
                      Campaign name & dates
                    </Typography>

                    {/* CAMPAIGN NAME */}
                    <TextField
                      id="campaign-name-input"
                      label="Campaign Name"
                      variant="filled"
                      onFocus={() => {
                        if (campaignNameError) setCampaignNameError('')
                      }}
                      error={!!campaignNameError}
                      helperText={!campaignNameError ? null : campaignNameError}
                      onChange={handleSetCampaignName}
                      inputProps={{ tabIndex: 1 }}
                      InputProps={{ disableUnderline: true }}
                      fullWidth
                      value={campaignName}
                      sx={{ mb: 3 }}
                    />

                    {/* START/END DATES */}
                    <Grid container sx={{ mb: 3 }} direction="row" justifyContent="space-between" spacing={3}>
                      <Grid item xs={6}>
                        <DatePicker
                          label="Start date"
                          value={startDate}
                          onChange={(e) => {
                            if ((e?.valueOf() as number) < Date.now() && !e?.isSame(new Date(), 'day')) {
                              setStartDateError('Start date cannot be earlier than today.')
                              return
                            }
                            setStartDate((e as moment.Moment).startOf('day'))
                          }}
                          renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DatePicker
                          label="End date"
                          value={endDate}
                          onChange={(e) => {
                            if ((e?.valueOf() as number) < Date.now() && !e?.isSame(new Date(), 'day')) {
                              setEndDateError('End date cannot be earlier than today.')
                              return
                            }
                            setEndDate((e as moment.Moment).endOf('day'))
                          }}
                          renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
                        />
                      </Grid>
                    </Grid>

                    {/* SELECT DISTRIBUTION STRATEGY */}
                    <Grid item xs={12} sx={{ mb: 2, width: '100%' }}>
                      {/* INSTRUCTION ANIMATION - mobile */}
                      {sm ? <Instruction option={selectedDistributionOption}></Instruction> : null}

                      {/* BUTTONS */}
                      <CTACancelButtonContainer>
                        <LoadingButton
                          loading={submitting}
                          loadingPosition="start"
                          variant="contained"
                          disabled={!isFormValid}
                          onClick={handleCreateCampaign}
                          fullWidth={sm ? true : false}
                          startIcon={<AddIcon />}
                          sx={{ mb: { xs: 2, sm: 2, md: 0 } }}
                        >
                          Create Campaign for {parseFloat(CAMPAIGN_COST)} N
                        </LoadingButton>
                        <Button
                          variant="outlined"
                          color="secondary"
                          component={Link}
                          to={'/dashboard'}
                          fullWidth={sm ? true : false}
                        >
                          Cancel
                        </Button>
                      </CTACancelButtonContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {sm ? null : <Grid item md={1}></Grid>}

              {/* INSTRUCTION ANIMATION - desktop */}
              {sm ? null : <Instruction option={selectedDistributionOption}></Instruction>}
            </Grid>
          )}
        </Container>
        <Snackbar
          open={!!startDateError}
          autoHideDuration={3000}
          onClose={() => setStartDateError('')}
          message={startDateError}
        />
        <Snackbar
          open={!!endDateError}
          autoHideDuration={3000}
          onClose={() => setEndDateError('')}
          message={endDateError}
        />
      </Grid>
    </LocalizationProvider>
  )
}

export default CreateCampaign
