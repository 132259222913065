import { Box } from '@mui/material'
import colors from '../../../../theme/colors'
import { IOnboardItem } from '../../types'

function Icon({ item }: { item: IOnboardItem }) {
  return (
    <Box
      sx={{
        width: '48px',
        height: '48px',
        backgroundColor: item.status === 'COMPLETE' ? colors.PRIMARY_DISABLED_BG : colors.SECONDARY_DISABLED_BG,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '24px',
        mr: 4,
      }}
    >
      <img
        style={{ width: '24px', height: '24px' }}
        src={item.status === 'COMPLETE' ? item.iconComplete : item.iconIncomplete}
        alt={`${item.status === 'COMPLETE' ? 'Complete' : 'Incomplete'} Icon`}
      />
    </Box>
  )
}

export default Icon
