import colors from '../theme/colors'

export interface FaqItem {
  question: string
  answer: JSX.Element
  expanded: boolean
}

const BoldSpan = (props: any) => {
  return <span style={{ color: colors.SECONDARY, fontWeight: 'bold' }}>{props.children}</span>
}

export const FAQ_ITEMS: FaqItem[] = [
  {
    question: 'What is Creator Studio?',
    answer: (
      <span>
        Creator Studio is a NFT minting and distribution tool that enables anyone to activate their audience and bring
        them into Web3 - zero coding experience is required. Our NFTs are carbon-neutral as we are built on top of the
        NEAR Protocol.
      </span>
    ),
    expanded: false,
  },
  {
    question: 'What can I create with Creator Studio?',
    answer: (
      <span>
        Creator Studio is a tool to mint many copies of one asset. You can be as creative as you’d like with what you
        decide to make as an NFT. A few example applications could include: Artwork, Business Cards, Membership Tokens,
        Event Tickets, Digital Merchandise, etc...
      </span>
    ),
    expanded: false,
  },
  {
    question: 'What is the difference between a Collection and a Series?',
    answer: (
      <span>
        Think of a <BoldSpan>Collection</BoldSpan> as the top level container for your NFTs that are similar in nature.
        You can have multiple different NFTs inside of a Collection. Examples:
        <br />
        <br />
        1) Photos from a recent trip of yours. Each photo is distinct but they are all related. <br />
        2) Business cards for your company. Each employee has a distinct card but all part of the same company. <br />
        <br />
        Think of a <BoldSpan>Series</BoldSpan> as the specific NFT asset. In the examples above:
        <br />
        <br />
        1) Each photo would be a Series (could be 10 copies of the same image).
        <br />
        2) Each employee business card would be its own Series (each employee could have 100 of the same business card
        to distribute).
      </span>
    ),
    expanded: false,
  },
  {
    question: 'Why do I need to create a Campaign?',
    answer: (
      <span>
        Think of Campaigns as a means to distribution -this is where we thrive. All NFTs must have a Campaign in order
        to be distributed. Our modes of distribution include:
        <br />
        <br />
        1) <BoldSpan>Direct Links:</BoldSpan> send these to individuals to easily claim your NFT.
        <br />
        2) <BoldSpan>Event Check-In:</BoldSpan> have your guests claim an NFT when they are at an event.
        <br />
        3) <BoldSpan>Email Collection:</BoldSpan> gate receiving your NFT by requiring inputting an email address.
        <br />
        4) <BoldSpan>Social Media Integration:</BoldSpan> gate receiving your NFT by requiring a social media
        interaction.
      </span>
    ),
    expanded: false,
  },
  {
    question: 'How do I track my NFT campaigns?',
    answer: (
      <span>
        All campaigns have a tracking dashboard. You will see the number of people that view your Campaign, the number
        of NFTs claimed, and the number of new NEAR accounts created as a result of your campaign. You will also have
        access to the NEAR accounts that claimed your NFT.
      </span>
    ),
    expanded: false,
  },
  {
    question: 'What are the costs of launching an NFT with Creator Studio?',
    answer: (
      <span>
        There are fixed blockchain costs associated with minting NFTs along with a cost to distribute:
        <br />
        <br />
        <BoldSpan>1) Create a Collection: 5 $NEAR</BoldSpan> - This covers on-chain storage cost for your Smart
        Contract, deployed on the NEAR Protocol.
        <br />
        <br />
        <BoldSpan>2) Create a Series: 0.05 $NEAR per NFT</BoldSpan> - This covers on-chain storage costs for each NFT in
        your Series.
        <br />
        <br />
        <BoldSpan>3) Create a Campaign: 0.2 $NEAR</BoldSpan> - This is the cost to deploy an NFT distribution strategy
        built by Satori.
      </span>
    ),
    expanded: false,
  },
  {
    question: 'How do I contact the team?',
    answer: (
      <span>
        Please reach out to us directly at <BoldSpan>info@satori.art</BoldSpan> for questions/feedback. We will get back
        with you ASAP.
      </span>
    ),
    expanded: false,
  },
]
