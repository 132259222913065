import { Constants } from '../constants'

export function escapifyAndStringify(obj: any) {
  let kvStr = Object.entries(obj).reduce((acc, entry, i, arr) => {
    const terminal = i === 0 ? ',\n' : i < arr.length - 1 ? ',\n' : '\n'
    return acc + '    ' + entry[0] + ': ' + entry[1] + terminal
  }, '')
  return '{\n' + kvStr + '\n}'
}

export function getFees(nftPrice: string) {
  const wyre = Constants.FEES.WYRE
  const price = parseFloat(nftPrice)

  const totalFees = feeViaRegression(price)
  const totalPrice = price + totalFees
  const wyreFee = fee(totalPrice, wyre)
  const satoriFee = totalPrice - price - wyreFee

  // const afterWyreCut = totalPrice - wyreFee // must deduct wyreFee
  // const afterSatoriCut = afterWyreCut - satoriFee // must deduct satoriFee

  // console.log('==========================================================')
  // console.log('creator sets price:          ', `$${price}`)
  // console.log('==========================================================')
  // console.log('   w/ satori fee:            ', `$${price + satoriFee} ($${price} + $${satoriFee})`)
  // console.log('   w/ wyre fee:              ', `$${price + satoriFee + wyreFee} ($${price + satoriFee} + $${wyreFee})`)
  // console.log('==========================================================')
  // console.log('customer pays:               ', `$${totalPrice}`)
  // console.log('==========================================================')
  // console.log('   after wyre takes cut:     ', `$${afterWyreCut} ($${totalPrice} - $${wyreFee})`)
  // console.log('   after satori takes cut:   ', `$${afterSatoriCut} ($${afterWyreCut} - $${satoriFee})`)
  // console.log('==========================================================')
  // console.log('creator earns:               ', `$${afterSatoriCut}`)
  // console.log('==========================================================')

  return {
    satoriFee,
    wyreFee,
    totalPrice,
  }
}

function fee(price: number, fee: any) {
  const priceCents = price * 100
  const percFeeCents = Math.round(priceCents * (fee.percent / 100))
  const baseFeeCents = fee.baseUSD * 100
  const totalFeeCents = percFeeCents + baseFeeCents
  const totalFeeDollars = totalFeeCents / 100
  return totalFeeDollars
}

// https://docs.google.com/spreadsheets/d/1RKxS-UcNIpzZam40A7YdHNb5N8r01fNi/edit#gid=2012141439
function feeViaRegression(price: number) {
  const reg = 0.0706638116 * price + 1.1241970021
  return parseFloat(reg.toPrecision(2))
}
