import ReactGA from 'react-ga'
import { Constants } from '../constants'

const isProduction = Constants.NODE_ENV === 'production'
if (isProduction) {
  ReactGA.initialize(Constants.GOOGLE_ANALYTICS_ID)
}

export function pageView(path: string) {
  if (isProduction) {
    ReactGA.pageview(path)
  }
}
