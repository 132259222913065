import { Box, BoxProps, FilledTextFieldProps, TextField as MuiTextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'

type TextFieldProps = Omit<FilledTextFieldProps, 'onChange' | 'variant'> & {
  variant?: FilledTextFieldProps['variant']
  validate?: (value: string) => boolean
  onChange?: (value: string) => void
  onChangeAfterTimeout?: (value: string) => void
  onValidityChange?: (isValid: boolean) => void
  onTypingChange?: (isTyping: boolean) => void
  BoxProps?: BoxProps
  timeout?: number
  characterLimit?: number
  disableUnderline: boolean
}

function TextField({
  error,
  value,
  color,
  validate,
  onChange,
  onChangeAfterTimeout,
  onValidityChange,
  onTypingChange,
  InputProps = {},
  variant = 'filled',
  size = 'medium',
  fullWidth = true,
  timeout = 500,
  autoComplete = 'off',
  characterLimit: characterLimitProp,
  BoxProps = {},
  disableUnderline,
  ...props
}: TextFieldProps) {
  const [{ isDirty, isValid, isTyping, hasCharacterLimit, characterLimit, timeoutRef }, setState] = useState<{
    isDirty: boolean
    isValid: boolean
    isTyping: boolean
    timeoutRef: NodeJS.Timeout | undefined
    hasCharacterLimit: boolean
    characterLimit: number | undefined
  }>({
    isDirty: false,
    isValid: false,
    isTyping: false,
    timeoutRef: undefined,
    hasCharacterLimit: false,
    characterLimit: characterLimitProp,
  })

  const handleTimeoutComplete = useCallback(
    (value: string) => {
      if (typeof onTypingChange === 'function') {
        onTypingChange(false)
      }
      setState((state) => ({ ...state, isTyping: false }))
      if (typeof onChangeAfterTimeout === 'function') {
        onChangeAfterTimeout(value as string)
      }
    },
    [onTypingChange, onChangeAfterTimeout]
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const hasValidity = typeof validate !== 'undefined'
      let isValid: boolean = !hasValidity || true
      const value = event.target?.value
      console.log('handleChange() value', value)
      if (hasValidity) {
        isValid = validate(value)
      }
      if (hasCharacterLimit && isValid) {
        isValid = value.length <= (characterLimit as number)
      }
      if (typeof onChange === 'function') {
        onChange(value)
      }
      if (hasValidity && typeof onValidityChange === 'function') {
        onValidityChange(isValid)
      }
      if (!isTyping && typeof onTypingChange === 'function') {
        onTypingChange(true)
      }

      if (typeof timeoutRef !== 'undefined') {
        clearTimeout(timeoutRef)
      }
      const ref = setTimeout(() => handleTimeoutComplete(value), timeout)

      setState((state) => ({
        ...state,
        isValid,
        isDirty: true,
        isTyping: true,
        timeoutRef: ref,
        value,
      }))
    },
    [
      onChange,
      onValidityChange,
      validate,
      handleTimeoutComplete,
      onTypingChange,
      isTyping,
      timeoutRef,
      timeout,
      characterLimit,
      hasCharacterLimit,
    ]
  )

  useEffect(() => {
    const hasCharacterLimit = typeof characterLimitProp === 'number' && characterLimitProp > 0
    const isCharacterLimitValid = hasCharacterLimit ? (value as string).length <= characterLimitProp : true
    setState((state) => ({
      ...state,
      hasCharacterLimit,
      characterLimit: characterLimitProp,
      isValid: !isCharacterLimitValid ? false : isValid,
    }))
  }, [characterLimitProp, isValid, value])

  const charactersLeft = hasCharacterLimit ? Math.max(0, (characterLimit as number) - (value as string).length) : 0
  const { sx: BoxSx = {}, ...RestBoxProps } = BoxProps
  return (
    <Box position="relative" sx={{ width: fullWidth ? '100%' : 'auto', ...BoxSx }} {...RestBoxProps}>
      <MuiTextField
        value={value}
        variant={variant}
        onChange={handleChange}
        error={(isDirty && !isValid) || (typeof error !== 'undefined' ? error : false)}
        InputProps={{ disableUnderline: true, sx: { pr: hasCharacterLimit ? '35px' : 0 }, ...InputProps }}
        fullWidth={fullWidth}
        autoComplete={autoComplete}
        size={size}
        color={color}
        sx={{ paddingTop: '25px', paddingBottom: '8px' }}
        {...props}
      />
      {hasCharacterLimit && isDirty && (
        <Box position="absolute" right="10px" top="50%" sx={{ transform: 'translateY(-50%)' }}>
          <Typography color={color || 'primary'} fontSize="0.9em">
            {charactersLeft}/{characterLimit}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default TextField
export type { TextFieldProps }
