import KYC from './KYC'
import { ONBOARD_ITEM_TITLES } from '../../Payouts'
import { IOnboardItem } from '../../types'
import ResetPaymentMethod from './ResetPaymentMethod'

function Body({ item }: { item: IOnboardItem }) {
  switch (item.title) {
    case ONBOARD_ITEM_TITLES.BANK_ACCOUNT:
      return item.active === false ? <ResetPaymentMethod item={item} /> : null
    case ONBOARD_ITEM_TITLES.KYC:
      return item.active ? <KYC /> : null
    default:
      return null
  }
}

export default Body
