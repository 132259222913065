const tryAgain = ' Please try again in a few minutes.'
const haveRefunded = ' We have refunded your NEAR payment.'
const willRefund = ' We will refund your NEAR payment.'
const contactUs =
  ' If you believe you have been charged for this transaction, please contact us at creatorstudio@satori.art to arrange a refund.'

export const ERROR_MESSAGES = {
  GENERIC_SERVER_ERROR: 'Something went wrong.' + tryAgain,
  CREATE_ACCOUNT_ERROR: 'We had a problem creating your account.' + tryAgain,
  NOT_ON_ALLOW_LIST: 'We could not create an account using that email, as it is not on our beta allow-list.',
  ACTIVATE_ACCOUNT_ERROR:
    'We had a problem activating your account. Please ensure that you are using the link provided in the latest email sent to you. If this does not solve the issue, please contact us at support@satori.art.',
  SET_PASSWORD_ERROR: 'We had a problem setting your password.' + tryAgain,
  RESET_PASSWORD_ERROR: 'We had a problem helping you reset your password.' + tryAgain,
  LOG_IN_ERROR: 'We had a problem logging you in.' + tryAgain,
  NEAR_ERROR: 'We had a problem connecting with NEAR.' + tryAgain,
  APP_STATS_ERROR: 'We had a problem fetching your latest stats.',
  CLAIM_LINK_ERROR: 'We had a problem generating your claim link.' + tryAgain,
  GET_TRANSACTIONS_ERROR: 'We had a problem fetching your transactions.' + tryAgain,
  NEAR_TRANSACTION_ERROR: 'We had a problem completing your transaction.' + contactUs,
  NEAR_POST_TRANSACTION_ERROR: 'Something went wrong.' + haveRefunded,
  CREATE_CAMPAIGN_ERROR: 'We had a problem creating your campaign.' + willRefund,
  CREATE_COLLECTION_ERROR_REFUNDED: 'We had a problem creating your collection.' + haveRefunded,
  CREATE_SERIES_ERROR: 'We had a problem creating your series.' + haveRefunded,
  TWITTER_CONNECTION_ERROR: 'We had a problem connecting to Twitter.' + tryAgain,
  TWITTER_VERIFICATION_ERROR: 'We had a problem verifying your Twitter connection.' + tryAgain,
  MEDIA_UPLOAD_ERROR: 'We had a problem uploading your file.' + tryAgain,
  GET_CAMPAIGN_ERROR: 'We had a problem fetching your campaign details.' + tryAgain,
  CAMPAIGN_STATS_ERROR: 'We had a problem fetching the latest stats for your campaign.',
  CHECK_IN_CODE_ERROR: 'We had a problem fetching a check-in code for your campaign.',
  APP_LOAD_ERROR: 'We had a problem loading your account details.' + tryAgain,
  CHECK_IN_LAUNCH_ERROR: 'We had a problem launching your check-in screen.' + tryAgain,
  PLAID_LINK_TOKEN_ERROR: 'We had a problem initiating your setup with Plaid.' + tryAgain,
  PLAID_EXCHANGE_TOKEN_ERROR: 'We had a problem completing your setup with Plaid.' + tryAgain,
  PLAID_ADD_IDENTITY_FIELDS_ERROR: 'We had a problem completing your KYC setup.' + tryAgain,
}
