import { DEFAULT_NETWORK_ID } from '../constants'
import { getAccount } from '../services/near'

export const topLevel =
  DEFAULT_NETWORK_ID === 'mainnet' ? '.near' : DEFAULT_NETWORK_ID === 'testnet' ? '.testnet' : '.testnet'

export async function isExistingAccountID(accountName: string) {
  const account = await getAccount(accountName.toLowerCase())
  return account.exists
}
