import { Box } from '@mui/material'
import Body from './Body'
import Header from './Header'
import { IOnboardItem } from '../types'

function OnboardItem({ item }: { item: IOnboardItem }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        opacity: item.disabled ? 0.4 : 1,
        borderRadius: '16px',
        mb: 2,
        p: 5,
      }}
    >
      <Header item={item} />
      <Body item={item} />
    </Box>
  )
}

export default OnboardItem
