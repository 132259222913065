import { Grid, Typography, TextField } from '@mui/material'
import { getFees } from '../../utils/Order'
import Fees from './Fees'

interface CreditCardFormProps {
  nftPrice: string | null
  handleSetNftPrice: (price: string) => void
  nftPriceError: string
  webhookUrl: string | null
  handleSetWebhookUrl: (url: string) => void
  webhookUrlError: string
  nftMaxPrice: string
  title: string
}

function CreditCardForm({
  nftPrice,
  handleSetNftPrice,
  nftPriceError,
  webhookUrl,
  handleSetWebhookUrl,
  webhookUrlError,
  nftMaxPrice,
  title,
}: CreditCardFormProps) {
  const { satoriFee, wyreFee, totalPrice } = getFees(nftPrice ? nftPrice : '0')

  return (
    <Grid item sx={{ minHeight: '56px' }}>
      <>
        <Typography variant="body1">
          Set a price for the NFT (max{' '}
          {parseFloat(nftMaxPrice).toLocaleString(undefined, {
            style: 'currency',
            currency: 'USD',
          })}{' '}
          USD)
        </Typography>
        <TextField
          id="nft-price-input"
          label="Price (USD)"
          variant="filled"
          value={nftPrice === null ? '' : nftPrice}
          error={!!nftPriceError}
          helperText={!nftPriceError ? null : nftPriceError}
          onChange={(e) => handleSetNftPrice(e.target.value)}
          InputProps={{ disableUnderline: true }}
          fullWidth
          sx={{ mb: 3, mt: 1 }}
        />
      </>
      <Fees
        price={nftPrice ? nftPrice : '0'}
        title={title}
        satoriFee={satoriFee}
        wyreFee={wyreFee}
        totalPrice={totalPrice}
        mt={0}
        mb={6}
      />
      <>
        <Typography variant="body1">Add a webhook URL to receive order notifications.</Typography>
        <TextField
          id="webhook-url-input"
          label="Webhook URL (optional)"
          variant="filled"
          value={webhookUrl === null ? '' : webhookUrl}
          error={!!webhookUrlError}
          helperText={!webhookUrlError ? null : webhookUrlError}
          onChange={(e) => handleSetWebhookUrl(e.target.value)}
          InputProps={{ disableUnderline: true }}
          fullWidth
          sx={{ mb: 6, mt: 1 }}
        />
      </>
    </Grid>
  )
}

export default CreditCardForm
