import { CollectionsResponse, CreateCollectionResponse } from '.'
import { Constants } from '../../constants'
import { spearmint, sonar } from '../axios'
import { resolveResponseError, resolveResponseSuccess } from '../helpers'
import { CreateCollectionRequest } from './models'

export function postCollection(appName: string, apiKey: string, body: CreateCollectionRequest) {
  return sonar({ appName, apiKey, 'funding-hash': body.fundingHash })
    .post(`app/collections`, { title: body.title })
    .then((r) => resolveResponseSuccess<CreateCollectionResponse>(r))
    .catch(resolveResponseError)
}

export function getCollections() {
  return spearmint
    .get(`/v1/api/${Constants.SPEARMINT_APP_NAME}/collections`)
    .then((r) => resolveResponseSuccess<CollectionsResponse>(r))
    .catch(resolveResponseError)
}
